import React from "react";
// import pricingbg from "../assets/images/bg/pricing-bg.jpg";
// import topshape from "../assets/css/img/top-shape.png";
import bottomshape from "../assets/css/img/bottom-shape.png";

import ball1 from "../assets/images/balls/1.png";
import ball2 from "../assets/images/balls/2.png";
import ball3 from "../assets/images/balls/3.png";
import ball4 from "../assets/images/balls/4.png";
import ball5 from "../assets/images/balls/5.png";
import ball6 from "../assets/images/balls/6.png";
import pricing5 from "../assets/images/pricing/pricing5.png";
import pricing6 from "../assets/images/pricing/pricing6.png"
import pricing7 from "../assets/images/pricing/pricing7.png"
// import pricing1 from "../assets/images/pricing/pricing1.png";
import pricing2 from "../assets/images/pricing/pricing2.png";
import pricing3 from "../assets/images/pricing/pricing3.png";
import pricing4 from "../assets/images/pricing/pricing4.png";
// import mostoclient from "../assets/images/client/mosto-client.jpg";
import community1 from "../assets/images/dummies/D7.png";
import community2 from "../assets/images/dummies/D1.png";
import community3 from "../assets/images/dummies/D2.png";
import community4 from "../assets/images/dummies/D3.png";
import community5 from "../assets/images/dummies/D13.png";
import community6 from "../assets/images/dummies/D16.png";
import community7 from "../assets/images/dummies/D17.png";
import community8 from "../assets/images/dummies/D20.png";
import community9 from "../assets/images/dummies/D4.png";
import community10 from "../assets/images/dummies/D9.png";
import community11 from "../assets/images/dummies/D5.png";
import community12 from "../assets/images/dummies/D6.png";
import community13 from "../assets/images/dummies/D18.png";
import community14 from "../assets/images/dummies/D21.png";
import community15 from "../assets/images/dummies/D15.png";
import community16 from "../assets/images/dummies/D16.png";
import community17 from "../assets/images/dummies/D19.png";
import community18 from "../assets/images/dummies/D10.png";
import community19 from "../assets/images/dummies/D12.png";
import community20 from "../assets/images/dummies/D11.png";
import community21 from "../assets/images/dummies/D8.png";
import cate from "../assets/images/cate.png";
import calculatebg from "../assets/images/bg/calculate-bg.png";
// import linebg from "../assets/images/bg/line-bg.png";
import help from "../assets/images/feature/help.png"
import help1 from "../assets/images/feature/help1.png"
import help2 from "../assets/images/feature/help2.png"
import help3 from "../assets/images/feature/help3.png"

import comparePlans from "../assets/images/pricing/compare-plans.png";
import Tickimg from "../assets/images/pricing/Tick.svg"
import Crossimg from "../assets/images/pricing/cross.svg"

import { useScrollToTop } from "./UseScrollToTop";

function Pricing() {
    // useScrollToTop();
    return (
        <>

            {/* <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div> */}
            <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
            <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img_pricing pos-rel" id="pricing">

                <div className="bottom-shape d-none d-md-block">
                    <img src={bottomshape} alt="css" />
                </div>
                <div className="ball-2" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
                    data-paroller-type="foreground" data-paroller-direction="horizontal">
                    <img src={ball1} alt="balls" />
                </div>
                <div className="ball-3" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
                    data-paroller-type="foreground" data-paroller-direction="horizontal">
                    <img src={ball2} alt="balls" />
                </div>
                <div className="ball-4" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
                    data-paroller-type="foreground" data-paroller-direction="horizontal">
                    <img src={ball3} alt="balls" />
                </div>
                <div className="ball-5" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
                    data-paroller-type="foreground" data-paroller-direction="vertical">
                    <img src={ball4} alt="balls" />
                </div>
                <div className="ball-6" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
                    data-paroller-type="foreground" data-paroller-direction="horizontal">
                    <img src={ball5} alt="balls" />
                </div>
                <div className="ball-7" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
                    data-paroller-type="foreground" data-paroller-direction="vertical">
                    <img src={ball6} alt="balls" />
                </div>
                <div className="container">
                    <div className="section-header pb-30 cl-white">
                        {/* <h5 className="cate">Choose a plan that's right for you</h5> */}
                        <h2 className="title">NEOIN Plans for Everyone</h2>
                        <p>NEOIN has plans, from free to paid, that scale with your needs. Subscribe to a plan that fits the size of your business.
                        </p>
                    </div>
                    <div className="tab-up">
                        <ul className="tab-menu pricing-menu">
                            <li className="active">Monthly</li>
                            <li>Yearly</li>
                        </ul>
                    </div>
                    <div className="row justify-content-center mb-30-none">
                        <div className="col-sm-10 col-md-6 col-lg-4">
                            <div className="pricing-item">
                                <span className="tag">basic</span>
                                <div className="pricing-thumb">
                                    <img src={pricing5} alt="pricing" />
                                </div>
                                <div className="pricing-content">
                                    <div className="pricing-header">
                                        <h2 className="title"><sup>$</sup>30.0</h2>
                                        <span className="cate">Per Month</span>
                                    </div>
                                    <ul>
                                        <li>Days of Free Trial</li>
                                        <li>Cloud Storage</li>
                                        <li>Regular Updates</li>
                                        <li>eCommerce Integration</li>
                                        <li>Interface Customization</li>
                                        <li>24/7 Support</li>
                                    </ul>
                                    <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 col-lg-4">
                            <div className="pricing-item">
                                <span className="tag">standard</span>
                                <div className="pricing-thumb">
                                    <img src={pricing6} alt="pricing" />
                                </div>
                                <div className="pricing-content">
                                    <div className="pricing-header">
                                        <h2 className="title"><sup>$</sup>50.0</h2>
                                        <span className="cate">Per Month</span>
                                    </div>
                                    <ul>
                                        <li>Days of Free Trial</li>
                                        <li>Cloud Storage</li>
                                        <li>Regular Updates</li>
                                        <li>eCommerce Integration</li>
                                        <li>Interface Customization</li>
                                        <li>24/7 Support</li>
                                    </ul>
                                    <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-md-6 col-lg-4">
                            <div className="pricing-item">
                                <span className="tag">premium</span>
                                <div className="pricing-thumb">
                                    <img src={pricing7} alt="pricing" />
                                </div>
                                <div className="pricing-content">
                                    <div className="pricing-header">
                                        <h2 className="title"><sup>$</sup>90.0</h2>
                                        <span className="cate">Per Month</span>
                                    </div>
                                    <ul>
                                        <li>Days of Free Trial</li>
                                        <li>Cloud Storage</li>
                                        <li>Regular Updates</li>
                                        <li>eCommerce Integration</li>
                                        <li>Interface Customization</li>
                                        <li>24/7 Support</li>
                                    </ul>
                                    <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="sect-header cl white">
                <h2 className="title">Compare our Plans</h2>
            </div>
            <section className="pricing-section oh  padding-bottom-2 single-pricing">
                <div className="container">
                    <div className="tab-up">
                        <div className="tab-area">

                            <div className="tab-item active">
                                <div className="pricing-slider-wrapper">
                                    <div className="pricing-slider owl-theme owl-carousel">
                                        {/* <div className="pricing-item-2">
                                           <div className="imgclass">
                                                <img src={pricing1} alt="pricing" />
                                            </div>
                                            </div>
                                           
                                            <ul className="pricing-content-3">
                                                <ul>Users</ul>
                                                <ul>Unlimited Bots</ul>
                                                <ul>Advance Dashboard</ul>
                                                <ul>Unlimited Project use</ul>
                                                <ul>Advance Insight</ul>
                                                <ul>Phone Support</ul>
                                            </ul>
                                        </div> */}

                                        <div className="line">
                                            <div className="pricing-item-2">
                                                <div className="imgclass">
                                                    <img src={comparePlans} alt="pricing" />
                                                </div>
                                                <div className="price-plan-name">
                                                    <ul>Users</ul>
                                                    <ul>Unlimited Bots</ul>
                                                    <ul>Advance Dashboard</ul>
                                                    <ul>Unlimited Project use</ul>
                                                    <ul>Advance Insight</ul>
                                                    <ul>Phone Support</ul>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="pricing-item-2">
                                            <h5 className="cate">Standard</h5>
                                            <div className="thumb">
                                                <img src={pricing2} alt="pricing" />
                                            </div>
                                            <h2 className="title"><sup>$</sup>10</h2>
                                            <span className="info">Per Month</span>
                                            <ul className="pricing-content-3">

                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Crossimg} alt="cross" /></li>
                                                <li><img src={Crossimg} alt="cross" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                            </ul>
                                        </div>
                                        <div className="pricing-item-2">
                                            <h5 className="cate">Business</h5>
                                            <div className="thumb">
                                                <img src={pricing3} alt="pricing" />
                                            </div>
                                            <h2 className="title"><sup>$</sup>20</h2>
                                            <span className="info">Per Month</span>
                                            <ul className="pricing-content-3">
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Crossimg} alt="cross" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                            </ul>
                                        </div>
                                        <div className="pricing-item-2">
                                            <h5 className="cate">Premium</h5>
                                            <div className="thumb">
                                                <img src={pricing4} alt="pricing" />
                                            </div>
                                            <h2 className="title"><sup>$</sup>30</h2>
                                            <span className="info">Per Month</span>
                                            <ul className="pricing-content-3">
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                                <li><img src={Tickimg} alt="tick" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-item">
                                    <div className="pricing-slider-wrapper">
                                        <div className="pricing-slider owl-theme owl-carousel">
                                            {/* <div className="pricing-item-2">
                                            <h5 className="cate">Startup</h5>
                                            <div className="thumb">
                                                <img src={pricing1} alt="pricing" />
                                            </div>
                                            <h2 className="title"><sup>$</sup>60</h2>
                                            <span className="info">Per Month</span>
                                            <ul className="pricing-content-3">
                                                <li>3 Users 10 GB Storage</li>
                                                <li>Monthly Updates</li>
                                                <li>eCommerce Integration</li>
                                                <li>Interface Customization</li>
                                                <li>24/7 Support</li>
                                            </ul>
                                            <a href="#0" className="get-button">Select Plan<i className="flaticon-right"></i></a>
                                        </div> */}
                                            <div className="pricing-item-2">
                                                <h5 className="cate">Standard</h5>
                                                <div className="thumb">
                                                    <img src={pricing2} alt="pricing" />
                                                </div>
                                                <h2 className="title"><sup>$</sup>120</h2>
                                                <span className="info">Per Month</span>
                                                <ul className="pricing-content-3">
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Crossimg} alt="cross" /></li>
                                                    <li><img src={Crossimg} alt="cross" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                </ul>

                                            </div>
                                            <div className="pricing-item-2">
                                                <h5 className="cate">Business</h5>
                                                <div className="thumb">
                                                    <img src={pricing3} alt="pricing" />
                                                </div>
                                                <h2 className="title"><sup>$</sup>180</h2>
                                                <span className="info">Per Month</span>
                                                <ul className="pricing-content-3">
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Crossimg} alt="cross" /></li>
                                                    <li><img src={Crossimg} alt="cross" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                </ul>

                                            </div>
                                            <div className="pricing-item-2">
                                                <h5 className="cate">Premium</h5>
                                                <div className="thumb">
                                                    <img src={pricing4} alt="pricing" />
                                                </div>
                                                <h2 className="title"><sup>$</sup>270</h2>
                                                <span className="info">Per Month</span>
                                                <ul className="pricing-content-3">
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Crossimg} alt="cross" /></li>
                                                    <li><img src={Crossimg} alt="cross" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                    <li><img src={Tickimg} alt="tick" /></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-70">
                            <p>Want a customized solution to meet your specific needs?</p>
                            <a href="#0" className="show-feature">Get in touch with us.</a>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--============= Comunity Section Starts Here =============--> */}
            <section className="comunity-section padding-top padding-bottom oh pos-rel">
                <div className="comunity-bg bg_img_mosto_client"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-header cl-white">
                                <h5 className="cate">Join the Community</h5>
                                <h2 className="title">Over 1,000,000 users</h2>
                                <p>Join and share in a community of like-minded members.</p>
                            </div>
                        </div>
                    </div>
                    <div className="comunity-wrapper">
                        <div className="buttons"><a href="#0" className="button-3 border-no-outline">Join the community <i className="flaticon-right"></i></a></div>
                        <div className="comunity-area">
                            <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community21} alt="comunity" /></div>

                        </div>
                        <div className="comunity-area two">
                            <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                        </div>
                        <div className="comunity-area three">
                            <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Comunity Section Ends Here =============--> */}

            {/* <!--============= Faq Section Starts Here =============--> */}
            <section className="faq-section padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-header">
                                <div className="cate">
                                    <img src={cate} alt="cate" />
                                </div>
                                <h2 className="title">Frequently Asked Questions</h2>
                                <a href="#0">More Questions <i className="flaticon-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-wrapper mb--38">
                                <div className="faq-item">
                                    <div className="faq-thumb">
                                        <i className="flaticon-pdf"></i>
                                    </div>
                                    <div className="faq-content">
                                        <h4 className="title">Who is NEOIN for?</h4>
                                        <p>
                                            Neoin is for professionals, businesses, associations, banks, students, and individuals seeking efficient networking and communication solutions.
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div className="faq-thumb">
                                        <i className="flaticon-pdf"></i>
                                    </div>
                                    <div className="faq-content">
                                        <h4 className="title">How do I get into NEOIN?</h4>
                                        <p>
                                            Getting started with Neoin is easy! Simply sign up for an account on our website and start exploring the features tailored to your needs.
                                        </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div className="faq-thumb">
                                        <i className="flaticon-pdf"></i>
                                    </div>
                                    <div className="faq-content">
                                        <h4 className="title">What features does NEOIN have?</h4>
                                        <p>
                                            Neoin offers a range of features including digital business cards, NFC technology, AI-enabled scanning, virtual backgrounds, and more, designed to enhance your networking experience.                                        
                                            </p>
                                    </div>
                                </div>
                                <div className="faq-item">
                                    <div className="faq-thumb">
                                        <i className="flaticon-pdf"></i>
                                    </div>
                                    <div className="faq-content">
                                        <h4 className="title">What's special about NEOIN?</h4>
                                        <p>
                                        Neoin stands out for its innovative features like AI-enabled scanning, virtual backgrounds, and secure data sharing capabilities, providing a unique and efficient networking experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Faq Section Ends Here =============--> */}


            <div className="padding-top mt-20 mt-max-xl-0">
                <div className="custom-plan bg_img_lineBg oh">
                    <div className="container">
                        <div className="custom-wrapper">
                            <span className="circle"></span>
                            <span className="circle two"></span>
                            <div className="calculate-bg">
                                <img src={calculatebg} alt="bg" />
                            </div>
                            <div className="custom-area">
                                <div className="section-header cl-white">
                                    <h5 className="cate">Connect, Communicate & Engage</h5>
                                    <h2 className="title">Explore our Products</h2>
                                    <p>
                                        Want to see our products in action? Take a guided tour through our demo to discover the features. </p>
                                </div>
                                <div className="text-center">
                                    <a href="#0" className="custom-button large-button theme-shadow">Request Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!--============= Custom-Plan Section Starts Here =============--> */}
            <section className="help-section padding-bottom padding-top oh">
                <div className="container">
                    <div className="section-header">
                        <h5 className="cate">Stop wasting time</h5>
                        <h2 className="title">Need Some Help?</h2>
                        <p>
                            Whether you’re stuck or just want some tips on where to start, any problem, hit up our experts anytime.
                        </p>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-lg-6 rtl d-none d-lg-block">
                            <img src={help} alt="feature" />
                        </div>
                        <div className="col-lg-6 col-xl-5 mb-30-none">
                            <div className="help-item">
                                <div className="help-thumb">
                                    <img src={help1} alt="help" />
                                </div>
                                <div className="help-content">
                                    <h5 className="title">Live Chat</h5>
                                    <p>How can I assist you today?</p>
                                </div>
                            </div>
                            <div className="help-item">
                                <div className="help-thumb">
                                    <img src={help2} alt="help" />
                                </div>
                                <div className="help-content">
                                    <h5 className="title">Get Support</h5>
                                    <p>Anything I can help you with?</p>
                                </div>
                            </div>
                            <div className="help-item">
                                <div className="help-thumb">
                                    <img src={help3} alt="help" />
                                </div>
                                <div className="help-content">
                                    <h5 className="title">Explore FAQs</h5>
                                    <p><a href="#0">Go to FAQs page</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Custom-Plan Section Ends Here =============--> */}



        </>
    )


}

export default Pricing