import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import clientleft from "../assets/images/client/left.png";
import clientright from "../assets/images/client/right.png";
import client03 from "../assets/images/dummies/D3.png";
import client02 from "../assets/images/dummies/D2.png";
import client01 from "../assets/images/dummies/D1.png";
import client04 from "../assets/images/dummies/D4.png";

const Review = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex(prevIndex => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => prevIndex + 1);
  };

  return (
    <section className="testimonial-section padding-top-2">
      <div className="container">
        <div className="section-header">
          <h5 className="cate">Testimonials</h5>
          <h2 className="title">5000+ happy clients all around the world</h2>
        </div>
        <div className="testimonial-wrapper">
          <a href="#0" className="testi-prev trigger" onClick={handlePrev}>
            <img src={clientleft} alt="client" />
          </a>
          <a  href="#0" className="testi-next trigger" onClick={handleNext}>
            <img src={clientright} alt="client" />
          </a>
          <OwlCarousel
            className="testimonial-area testimonial-slider"
            loop
            margin={10}
            dots={false}
            autoplay
            autoplayTimeout={3000}
            startPosition={currentIndex}
            items={1}
          >
            <div className="testimonial-item">
              <div className="testimonial-thumb">
                <div className="thumb">
                  <img src={client03} alt="client" />
                </div>
              </div>
              <div className="testimonial-content">
                <div className="ratings">
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                </div>
                <p>Finally found a platform that prioritizes security while connecting users seamlessly. My experience with this app has been nothing short of exceptional!</p>
                <h5 className="title"><a href="#0">Siddesh</a></h5>
              </div>
              <div className="button-area">
                <a href="/addreview" className="button-2" id='btn-no-bg-flex'>Leave a review <i className="flaticon-right"></i></a>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="testimonial-thumb">
                <div className="thumb">
                  <img src={client04} alt="client" />
                </div>
              </div>
              <div className="testimonial-content">
                <div className="ratings">
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                </div>
                <p>In a digital age filled with uncertainties, this app stands out for its unwavering commitment to safeguarding my financial interests. Trust and reliability redefined!</p>
                <h5 className="title"><a href="#0">Emily</a></h5>
              </div>
              <div className="button-area">
                <a href="/addreview" className="button-2" id='btn-no-bg-flex'>Leave a review <i className="flaticon-right"></i></a>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="testimonial-thumb">
                <div className="thumb">
                  <img src={client02} alt="client" />
                </div>
              </div>
              <div className="testimonial-content">
                <div className="ratings">
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                </div>
                <p>With a blend of cutting-edge technology and user-friendly interface, this app makes managing my finances and forging connections a breeze. Convenience and security rolled into one</p>
                <h5 className="title"><a href="#0">Raihan</a></h5>
              </div>
              <div className="button-area">
                <a href="/addreview" className="button-2" id='btn-no-bg-flex'>Leave a review <i className="flaticon-right"></i></a>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="testimonial-thumb">
                <div className="thumb">
                  <img src={client01} alt="client" />
                </div>
              </div>
              <div className="testimonial-content">
                <div className="ratings">
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                  <span><i className="fas fa-star"></i></span>
                </div>
                <p>From secure transactions to effortless networking, this app has become an integral part of my daily routine. It's like having a personal guardian for my digital life.</p>
                <h5 className="title"><a href="#0">Kartik</a></h5>
              </div>
              <div className="button-area">
                <a href="/addreview" className="button-2" id='btn-no-bg-flex'>Leave a review <i className="flaticon-right"></i></a>
              </div>
            </div>
            
          </OwlCarousel>
          
        </div>
      </div>
    </section>
  );
};

export default Review;
