import React from "react";
// import pageheader from "../assets/images/page-header.png";
import pageheader1 from "../assets/css/img/page-header.png";
import privacy from "../assets/images/bg/privacy-header.png";
import calendar from "../assets/images/icon/Calendar=24px.svg"
// import faq from "../assets/images/faq/faq-menu.png";
import { useScrollToTop } from "./UseScrollToTop";


function Privacy() {

    useScrollToTop();

    return (
        <>
            <section className="page-header bg_img_pg_header oh">
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageheader1} alt="css" />
                </div>
                <div className="page-left-thumb">
                    <img src={privacy} alt="bg" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Our Privacy Policy</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                Privacy
                            </li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="privacy-section padding-top padding-bottom">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    {/* <div className="section-header">
                        <h2 className="title">Privacy Policy</h2>
                        <p>Please read these Privacy Policies before accessing this website.</p>
                    </div> */}
                </div>
            </div>
            <div className="row justify-content-between">
                {/* <div className="col-lg-4">
                    <aside className="sticky-menu">
                        <div className="faq-menu bg_img" data-background={faq}>
                            <ul id="faq-menu">
                                <li className="nav-item">
                                    <a className="nav-link" href="#general">General</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#prop">Intellectual Property</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#secu">Security and storage</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#info">Usage Information</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#cooki">Cookies</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#ipaddr">IP Address</a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div> */}
                <div className="col-lg-8 col-xl-12">
                    <article className="mt-70 mt-lg-0">

                        <div className="privacy-item">
                            <p><img src={calendar} alt="css"/>    Last updated: March 22, 2024</p>
                            <h3 className="title">Privacy Policy</h3>
                            <p>NEOIN  (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains what information is collected, used, and disclosed by NEOIN .
                            This Privacy Policy applies to our website and its associated subdomains (i.e. NEOIN .com and *.NEOIN .com). By accessing our website, you signify that you have read, understood, and agree to excerpts as described in this Privacy Policy.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="accept">
                            <h3 className="title">Definitions and key terms</h3>
                            <p>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="users">
                            <h3 className="title">Cookies</h3>
                            <p>A small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, and remember information about you such as your language preference or login information.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="data">
                            <h3 className="title">Company</h3>
                            <p>When this policy mentions “Company,” “we,” “us,” or “our,” it refers to NEOIN  Pte Ltd , (#30 Tanah Merah Kechil Road , East meadows Tower 3A , Singapore 465558) that is responsible for your information under this Privacy Policy.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="ai">
                            <h3 className="title">Visitor</h3>
                            <p>Refers to the company, organization, or person that access NEOIN .com or any of its subdomains.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="platform">
                            <h3 className="title">Device</h3>
                            <p>Any internet-connected device such as a phone, tablet, computer, or any other device that can be used to visit NEOIN .com or any of its subdomains.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="feature">
                            <h3 className="title">IP Address</h3>
                            <p>Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.</p>

                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Personnel</h3>
                            <p>Refers to those individuals who are employed by NEOIN  or are under contract to perform a NEOIN .com or any of its subdomains on behalf of one of the parties.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="terms">
                            <h3 className="title">Personal Data</h3>
                            <p>Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.</p>
                            
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Website</h3>
                            <p>NEOIN ’s site, which can be accessed via this URL: https://www.NEOIN .co/</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Third-Party</h3>
                            <p>Refers to vendors/suppliers, advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or website may interest you.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">You</h3>
                            <p>A person or entity who accesses the Website.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Information Automatically Collected</h3>
                            <p>There is some information like your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our website. This information may be used to connect your computer to the Internet. Other information collected automatically could be connection information such as browser plug-in types and versions and time zone setting, operating systems and platforms, the full Uniform Resource Locator (URL) clickstream to, through, and from our Website that may include date and time; parts of the site you viewed or searched for.</p>
                            <p>During your visits, we may use software tools such as JavaScript to measure and collect user experience information including page response times, download errors, and length of visits to certain pages. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.</p>
                            <p>We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our platform, and for our internal analytics and reporting purposes.</p>

                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Indemnification</h3>
                            <p>You agree to defend, indemnify, and hold harmless NEOIN and/or its associate entities, their officers, directors, employees, and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the web site/application material or your breach of these Terms and Conditions of Web site/application use.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Personnel</h3>
                            <p>If you are a NEOIN  worker or applicant, we collect the information you voluntarily provide to us. We use the information collected for Human Resources purposes in order to administer benefits to workers and screen applicants.
You may contact us in order to (1) update or correct your information, (2) change your preferences with respect to communications and other information you receive from us, or (3) receive a record of the information we have relating to you. Such updates, corrections, changes, and deletions will have no effect on other information that we maintain in accordance with this Privacy Policy prior to such update, correction, change, or deletion.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Personal Information</h3>
                            <p>We do not collect the Personal Information of our users who simply visit NEOIN .com or any of its subdomains.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Sale Of Business</h3>
                            <p>We reserve the right to transfer information to a third party in the event of a sale, merger, or other transfer of all or substantially all of the assets of NEOIN  or any of its Corporate Affiliates (as defined herein), or that portion of NEOIN  or any of its Corporate Affiliates to which the Website relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Affiliates</h3>
                            <p>We may disclose information to our Corporate Affiliates. For purposes of this Privacy Policy, “Corporate Affiliate” means any person or entity which directly or indirectly controls, is controlled by, or is under common control with NEOIN , whether by ownership or otherwise. Any information that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Governing Law</h3>
                            <p>This Privacy Policy is governed by the laws of India without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield.</p>
                            <p>The laws of India, excluding its conflicts of law rules, shall govern this Agreement and your use of the website. Your use of the website may also be subject to other local, state, national, or international laws.</p>
                            <p>By accessing the website or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you should not engage with our website, or use our NEOIN .com or any of its subdomains. Continued use of the website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of information will mean that you accept those changes.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Electronic Communication</h3>
                            <p>You agree that we may communicate with you by email or by posting notices on the Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. NEOIN requires your agreement during the registration process to send you promotional emails to let you know about any new changes, features or promotional activities added to the Site. If at any time, you decide that you do not wish to receive promotional emails, you can opt out of receiving such promotional emails by clicking on the link at the bottom of any promotional email.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Your Consent</h3>
                            <p>We’ve updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site and how it’s being used. By accessing our website you hereby consent to our Privacy Policy and agree to its terms.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Links To Other Websites</h3>
                            <p>This Privacy Policy applies only to this Website. The Websites may contain links to other websites not operated or controlled by NEOIN . We are not responsible for the content, accuracy, or opinions expressed in such websites, and such websites are not investigated, monitored, or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Websites to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Cookies</h3>
                            <p>NEOIN  uses “Cookies” to identify the areas of our website that you have visited. A cookie is a small piece of data stored on your computer or mobile device by your web browser. We use cookies to enhance the performance and functionality of our website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
                           </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Blocking And Disabling Cookies And Similar Technologies</h3>
                            <p>Wherever you’re located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and NEOIN .com or any of its subdomains. You should also be aware that you may also lose some saved information (e.g. site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser’s help menu for more information.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="limit">
                            <h3 className="title">Changes To Our Privacy Policy</h3>
                            <p>We may change our Website and policies, and we may need to make changes to this Privacy Policy so that it accurately reflects our Website and policies. Unless otherwise required by law, we will notify you (for example, through our Website) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Website, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, do not engage with the platform.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Third-Party Services</h3>
                            <p>We don’t display, nor include nor make available third-party content (including data, information, applications, or any other products services). However, we may provide links to third-party websites or services (i.e. job posts belonging to NEOIN  posted over third-party websites).
You acknowledge and agree that NEOIN  shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. NEOIN  does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Grievance Officer</h3>
                            <p>In accordance with the Information Technology Act, 2000 and the Rules made thereunder, the name and contact details of the Grievance Officer are provided below. You may contact the Grievance Officer to address any discrepancies and grievances you may have with respect to your Information with NEOIN . The Grievance Officer will redress your grievances expeditiously.</p>
                            <p><b>Name – Mr. Jyotimaya Mohanty</b></p>
                            <p><b>Designation- Director</b></p>
                            <p><b>Contact Number: +6580275311</b></p>
                            <p><b>Email Id: <a href="mailto:Jyotimaya.mohanty@neoin.co">Jyotimaya.mohanty@neoin.co</a></b></p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>

        </>

    )
}

export default Privacy