import React from 'react'
import PageHead from "../assets/css/img/page-header.png"
// import Pageheader from "../assets/images/page-header.png"

import Collaboration from "../assets/images/feature/colaboration.png"

import Feat01 from "../assets/images/feature/Onsite Events1.png" 
// import Feat02 from "../assets/images/feature/Onsite Events2.png"
import Creation from "../assets/images/events/Event Creation (Personal & Professional 1.png"
import EventInvite from "../assets/images/events/Event Invite & Sharing.png"
import EventNetwork from "../assets/images/events/Events Networking & workshops.png"
import EventAccess from "../assets/images/events/Event Access Card.png"
import EventSchedule from "../assets/images/events/Event Schedule & Calendar.png"
import Membership from "../assets/images/events/Alumni event membership.png"

import virtualEvent from "../assets/images/events/1. Virtual Event Cards.png"
import globalEvent from "../assets/images/events/Global Event Reach.png"
import postEvent from "../assets/images/events/Post Events.png"
import liveEvent from "../assets/images/events/Live Event Sreaming.png"
import flexibleEvent from "../assets/images/events/Flexible Events.png"
import engageEvent from "../assets/images/events/6. Engaging Event Components.png"


import Comprehensive from "../assets/images/events/Comprehensive Analytics.png"
import GlobalAccess from "../assets/images/events/Global Accessibility.png"
import HybridNetwork from "../assets/images/events/Hybrid Networking.png"
import InteractiveTech from "../assets/images/events/Interactive Technology.png"
import onDemand from "../assets/images/events/On-Demand Content.png"
import seamlessIntegration from "../assets/images/events/Seamless Integration.png"



// import Feat08 from "../assets/images/feature/feat8.png"
// import Feat09 from "../assets/images/feature/feat9.png"
// import Feat03 from "../assets/images/feature/Virtual Events2.png"

import Feat07 from "../assets/images/events/Virtual Event.png"
// import Feat06 from "../assets/images/feature/Virtual Events2.png"

import Feat10 from "../assets/images/events/Hybrid Event.png"
// import Feat11 from "../assets/images/feature/feat11.png"
import featurevideo from "../assets/images/feature/feature-video.png";


import Productivity from "../assets/images/feature/productivity.png"


import Integration from "../assets/images/feature/integrations.png"

// import Mostoclient from "../assets/images/client/mosto-client.jpg"


// import community1 from "../assets/images/comunity/1.png";
// import community2 from "../assets/images/comunity/2.png";
// import community3 from "../assets/images/comunity/3.png";
// import community4 from "../assets/images/comunity/4.png";
// import community5 from "../assets/images/comunity/5.png";
// import community6 from "../assets/images/comunity/6.png";
// import community7 from "../assets/images/comunity/7.png";
// import community8 from "../assets/images/comunity/8.png";
// import community9 from "../assets/images/comunity/9.png";
// import community10 from "../assets/images/comunity/10.png";
// import community11 from "../assets/images/comunity/11.png";
// import community12 from "../assets/images/comunity/12.png";
// import community13 from "../assets/images/comunity/13.png";
// import community14 from "../assets/images/comunity/14.png";
// import community15 from "../assets/images/comunity/15.png";
// import community16 from "../assets/images/comunity/16.png";
// import community17 from "../assets/images/comunity/17.png";
// import community18 from "../assets/images/comunity/18.png";
// import community19 from "../assets/images/comunity/19.png";
// import community20 from "../assets/images/comunity/20.png";
// import community21 from "../assets/images/comunity/21.png";


// import sponsor1 from "../assets/images/sponsor/sponsor1.png"
// import sponsor2 from "../assets/images/sponsor/sponsor2.png"
// import sponsor3 from "../assets/images/sponsor/sponsor3.png"
// import sponsor4 from "../assets/images/sponsor/sponsor4.png"
// import sponsor5 from "../assets/images/sponsor/sponsor5.png"
// import sponsor6 from "../assets/images/sponsor/sponsor6.png"
// import sponsor7 from "../assets/images/sponsor/sponsor7.png"

function Eventsconference() {
  return (
    <div id="events-page" className='events-headings'>
       {/* <!--============= Header Section Ends Here =============--> */}
    <section className="page-header bg_img_pg_header">
        <div className="bottom-shape d-none d-md-block">
            <img src={PageHead} alt="css"/>
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="Newtitle">Events & Conferences</h2>
                <ul className="breadcrumb">
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        Events & Conferences
                    </li>
                </ul>
            </div>
        </div>
    </section>
    {/* <!--============= Header Section Ends Here =============--> */}


    
    {/* <!--============= Feature Section Starts Here =============--> */}
    <section className="feature-section padding-top padding-bottom oh">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-8">
                    <div className="section-header mw-100">
                        <h5 className="cate">An Exhaustive List of Events & Conferences</h5>
                        <h2 className="title">Transform your events effortlessly with Neoin's integrated solutions.</h2>
                        {/* <p className="mw-500">Numerous features make it possible to customize the system
                            in accordance with all your needs.</p> */}
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs feature-tab-menu">
                <li>
                    <a  data-toggle="tab" href="#onsite" className="active">Onsite Events</a>
                </li>
                <li>
                    <a  data-toggle="tab" href="#virtual">Virtual Events</a>
                </li>
                <li>
                    <a  data-toggle="tab" href="#hybrid">Hybrid Events</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="onsite">
                    <div className="row">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-tab-header">
                                <h3 className="title">Onsite Events</h3>
                                {/* <p>Bring your team together. Discuss projects, share ideas, and get work done in real time.</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="cola-area">
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-content pr-1 pl-2">
                                    <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div>
                                    {/* <span className="cate">Collaboration</span> */}
                                    {/* <h5 className="title">Share all types of files and find them easily</h5> */}
                                    <p>Connect face-to-face at our onsite events: conferences, workshops, and networking with industry peers and experts. Foster relationships and build valuable connections in your industry network.</p> <br />
                                    {/* <ul>
                                        <li>Drag and Drop files</li>
                                        <li>Shared files</li>
                                        <li>Share multiple file </li>
                                    </ul> */}
                                   
                                </div>
                                <div>
                                <ul className="bal-list">
                                        <li>Event Creation </li>
                                        <li>Event Invite and Sharing</li>
                                        <li>Networking and Workshops</li>
                                        <li>Event Access Card</li>
                                        <li>Event Schedule & Calendar</li>
                                        <li>Alumni Event Membership</li>
                                        {/* <li>Event Membership Alumni</li>
                                        <li>Event Workshops</li> */}
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-thumb">
                                    <img src={Feat01} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Event Creation</span>
                                    {/* <h5 className="title">Share all types of files and find them easily</h5> */}
                                   
                                    <p>Easily create and manage both personal and professional events with NEOIN's intuitive event creation tools. Customize event details, schedule, and format to suit your needs.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Creation} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Event Invite and Sharing</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>Streamline event creation and invitations with NEOIN's intuitive tools, ensuring seamless sharing of event context and schedules for an engaging attendee experience.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={EventInvite} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Event Networking and Workshops</span>
                                    {/* <h5 className="title">Share important news and updates with the entire team</h5> */}
                                    {/* <ul>
                                        <li>Announcement channels</li>
                                        <li>Broadcast information</li>
                                        <li>Sending email updates </li>
                                    </ul> */}
                                    <p>NEOIN fosters connections among participants and offers workshops designed for professional growth and learning opportunities.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={EventNetwork} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Event Access Card</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>Access your event details and digital event card directly from NEOIN's platform. Stay informed about event updates, agenda, speakers, and more, all in one convenient location.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={EventAccess} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Event Schedule & Calendar</span>
                                    {/* <h5 className="title">Share important news and updates with the entire team</h5> */}
                                    {/* <ul>
                                        <li>Announcement channels</li>
                                        <li>Broadcast information</li>
                                        <li>Sending email updates </li>
                                    </ul> */}
                                    <p>Utilize NEOIN's meeting scheduling calendar to coordinate meetings with event participants. Seamlessly integrate schedules, set up meetings, and manage appointments for productive networking.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={EventSchedule} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Alumni Event Membership</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>Engage alumni members through exclusive event memberships and alumni gatherings. Foster a sense of community, facilitate networking opportunities, and promote ongoing collaboration.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Membership} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        
                       
                    </div>
                </div>
                <div className="tab-pane fade" id="virtual">
                    <div className="row">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-tab-header">
                                <h3 className="title">Virtual Events</h3>
                                {/* <p>Boost your team's productivity and efficiency with our inbuilt 
                                    tools. Collaborate with team members, share opinions, 
                                    and manage your tasks more efficiently.</p> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="cola-area">
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-content pr-1 pl-2">
                                    <div className="thumb">
                                        <img src={Productivity} alt="feature"/>
                                    </div>
                                    {/* <span className="cate">Collaboration</span> */}
                                    {/* <h5 className="title">Share all types of files and find them easily</h5> */}
                                    <p>Participate in global virtual events from anywhere, eliminating travel costs and geographical barriers for seamless collaboration and convenience.</p> <br />
                                    {/* <ul>
                                        <li>Drag and Drop files</li>
                                        <li>Shared files</li>
                                        <li>Share multiple file </li>
                                    </ul> */}
                                   
                                </div>
                                <div>
                                <ul className="bal-list">
                                        <li>Virtual Event Cards</li>
                                        <li>Share ideas quickly</li>
                                        <li>Post-Event Access</li>
                                        <li> Live Event Streaming</li>
                                        <li>Flexible Events</li>
                                        <li>Engaging Event Components</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-thumb">
                                    <img src={Feat07} alt="feature"/>
                                </div>
                            </div>
                        </div>


                        <div className="cola-item">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate"> Virtual Event Cards</span>
                                    {/* <h5 className="title">Share all types of files and find them easily</h5> */}
                                   
                                    <p>NEOIN's digital event cards streamline access to event information, sessions, and resources for attendees, ensuring a personalized and convenient experience.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-thumb d-flex justify-content-center">
                                    <img src={virtualEvent} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Global Event Reach</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>With NEOIN, virtual events have a global reach, connecting participants from around the world and enabling seamless collaboration and networking opportunities.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-thumb">
                                    <img src={globalEvent} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Post-Event Access</span>
                                    {/* <h5 className="title">Share important news and updates with the entire team</h5> */}
                                    {/* <ul>
                                        <li>Announcement channels</li>
                                        <li>Broadcast information</li>
                                        <li>Sending email updates </li>
                                    </ul> */}
                                    <p>NEOIN offers post-event access to recordings, presentations, and materials, allowing attendees to review content and continue learning even after the event concludes.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={postEvent} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Live Event Streaming</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>NEOIN's live event streaming feature delivers real-time content, engaging presentations, and interactive sessions to virtual attendees, creating an immersive event experience.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-thumb">
                                    <img src={liveEvent} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Flexible Events</span>
                                    {/* <h5 className="title">Share important news and updates with the entire team</h5> */}
                                    {/* <ul>
                                        <li>Announcement channels</li>
                                        <li>Broadcast information</li>
                                        <li>Sending email updates </li>
                                    </ul> */}
                                    <p>NEOIN's flexible event options cater to diverse attendee needs, offering customizable schedules, on-demand content, and interactive elements to enhance engagement and participation.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <div className="cola-thumb">
                                    <img src={flexibleEvent} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Engaging Event Components</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>NEOIN integrates engaging event components like live polls, Q&A sessions, virtual networking lounges, and interactive workshops, creating dynamic and interactive virtual experiences for attendees.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={engageEvent} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        {/* <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Productivity} alt="feature"/>
                                    </div>
                                    <span className="cate">productivity</span>
                                    <h5 className="title">Streamline and automate 
                                        your workflows</h5>
                                    <ul>
                                        <li>Simplify and automate</li>
                                        <li>Receive real-time updates</li>
                                        <li>Enhance team collaboration</li>
                                    </ul>
                                    <p>Attendees can participate in virtual events from the comfort of their own homes or offices, offering flexibility and convenience for busy professionals.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Feat09} alt="feature"/>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
               <div className="tab-pane fade" id="hybrid">
                    <div className="row">
                        <div className="col-lg-6 col-md-10">
                            <div className="feature-tab-header">
                                <h3 className="title">Hybrid Events</h3>
                                {/* <p>Integrate your most frequently used third-party apps
                                    with Mosto. Choose from over 60+ apps listed
                                    on our Appstore or build your own.</p> */}
                            </div>
                        </div>
                    </div>
                    
                    <div className="cola-area">
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-content pr-1 pl-2">
                                    <div className="thumb">
                                        <img src={Integration} alt="feature"/>
                                    </div>
                                    {/* <span className="cate">Collaboration</span> */}
                                    {/* <h5 className="title">Share all types of files and find them easily</h5> */}
                                    <p>Experience the best of both worlds with our hybrid events, combining virtual accessibility with onsite interactions for a comprehensive and engaging experience.</p> <br />
                                    {/* <ul>
                                        <li>Drag and Drop files</li>
                                        <li>Shared files</li>
                                        <li>Share multiple file </li>
                                    </ul> */}
                                   
                                </div>
                                <div>
                                <ul className="bal-list">
                                        <li>Seamless Integration</li>
                                        <li>Global Accessibility</li>
                                        <li>Interactive Technology</li>
                                        <li>On-Demand Content</li>
                                        <li>Hybrid Networking</li>
                                        <li>Comprehensive Analytics</li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-thumb">
                                    <img src={Feat10} alt="feature" style={{"width":"120%"}}/>
                                </div>
                            </div>
                        </div>


                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Seamless Integration</span>
                                    {/* <h5 className="title">Share all types of files and find them easily</h5> */}
                                   
                                    <p>NEOIN ensures seamless integration of onsite and virtual components in hybrid events, providing a unified experience for both in-person and remote attendees.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-thumb">
                                    <img src={seamlessIntegration} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Global Accessibility</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>With NEOIN, hybrid events are globally accessible, allowing participants from anywhere to join in-person or virtually, fostering inclusivity and expanding event reach.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-thumb">
                                    <img src={GlobalAccess} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Interactive Technology</span>
                                    {/* <h5 className="title">Share important news and updates with the entire team</h5> */}
                                    {/* <ul>
                                        <li>Announcement channels</li>
                                        <li>Broadcast information</li>
                                        <li>Sending email updates </li>
                                    </ul> */}
                                    <p>NEOIN incorporates interactive technology such as live polls, virtual Q&A sessions, and interactive workshops, enhancing engagement and interaction among attendees.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-thumb">
                                    <img src={InteractiveTech} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">On-Demand Content</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>NEOIN offers on-demand access to event content, enabling attendees to access recordings, presentations, and materials at their convenience post-event.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-thumb">
                                    <img src={onDemand} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate"> Hybrid Networking</span>
                                    {/* <h5 className="title">Share important news and updates with the entire team</h5> */}
                                    {/* <ul>
                                        <li>Announcement channels</li>
                                        <li>Broadcast information</li>
                                        <li>Sending email updates </li>
                                    </ul> */}
                                    <p>NEOIN facilitates hybrid networking opportunities, allowing participants to connect and network with peers, experts, and sponsors both onsite and virtually.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-thumb">
                                    <img src={HybridNetwork} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-5">
                                <div className="cola-content">
                                    {/* <div className="thumb">
                                        <img src={Collaboration} alt="feature"/>
                                    </div> */}
                                    <span className="cate">Comprehensive Analytics</span>
                                    {/* <h5 className="title">Find whatever you're looking for</h5> */}
                                    {/* <ul>
                                        <li>fast and easy Search</li>
                                        <li>Search files and links</li>
                                        <li>Advanced search</li>
                                    </ul> */}
                                    <p>NEOIN provides comprehensive analytics and insights into attendee engagement, session popularity, and interaction metrics, empowering organizers with data-driven insights for future event planning and optimization.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-7">
                                <div className="cola-thumb">
                                    <img src={Comprehensive} alt="feature"/>
                                </div>
                            </div>
                        </div>
                        {/* <div className="cola-item">
                            <div className="col-md-6 col-lg-5 col-xl-4">
                                <div className="cola-content">
                                    <div className="thumb">
                                        <img src={Productivity} alt="feature"/>
                                    </div>
                                    <span className="cate">productivity</span>
                                    <h5 className="title">Streamline and automate 
                                        your workflows</h5>
                                    <ul>
                                        <li>Simplify and automate</li>
                                        <li>Receive real-time updates</li>
                                        <li>Enhance team collaboration</li>
                                    </ul>
                                    <p>Attendees can participate in virtual events from the comfort of their own homes or offices, offering flexibility and convenience for busy professionals.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7 col-xl-8">
                                <div className="cola-thumb">
                                    <img src={Feat09} alt="feature"/>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div> 
            </div>
        </div>
    </section>
    {/* <!--============= Feature Section Ends Here =============--> */}


    {/* <!--============= Feature Video Section Starts Here =============--> */}
    <section className="feature-video-section padding-top dark-bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">Amazing features to convince you</h5>
                        <h2 className="title">Watch our video presentation</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <p>In the process of making a app, the satisfaction of users is the most 
                                    important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="pos-rel mw-100">
                        <img className="w-100" src={featurevideo} alt="bg"/>
                        <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button-2 popup">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <i className="flaticon-play"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Feature Video Section Ends Here =============-->

    {/* <!--============= Sponsor Section Section Here =============--> */}
    {/* <section className="sponsor-section padding-bottom">
        <div className="container">
            <div className="section-header mw-100">
                <h5 className="cate">Used by over 1,000,000 people worldwide</h5>
                <h2 className="title">Companies that trust us</h2>
            </div>
            <div className="sponsor-slider-4 owl-theme owl-carousel">
            <div className="sponsor-thumb">
                    <img src={sponsor1} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor2} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor3} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor4} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor5} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor6} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor7} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor1} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor2} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor3} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor4} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor5} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor6} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor7} alt="sponsor"/>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Sponsor Section Ends Here =============--> */}
    </div>
  )
}

export default Eventsconference
