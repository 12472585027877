import React,{useEffect} from 'react'
// import Banner16 from "../assets/images/banner/banner16/banner-bg-16.png"
// import play2 from "../assets/images/button/play2.png"
// import Screenbanner from "../assets/images/banner/banner16/screen.png"
// import Banner01 from "../assets/images/banner/banner16/01.png"
// import Banner02 from "../assets/images/banner/banner16/02.png"
// import Banner03 from "../assets/images/banner/banner16/03.png"
// import Banner04 from "../assets/images/banner/banner16/04.png"
// import Banner05 from "../assets/images/banner/banner16/05.png"
// import Banner06 from "../assets/images/banner/banner16/06.png"

import digiIdBanner from "../assets/images/digi_id/Digital Verfied iD.png"

// import Extra01 from "../assets/images/extra/01.png"
// import Extra03 from "../assets/images/extra/03.png"
// import Extra02 from "../assets/images/extra/02.png"

import Extra01 from "../assets/images/digi_id/Digital Verified iD- Dynamic Digital Business Profile.png"
import Extra02 from "../assets/images/digi_id/Simplifying Document Signing Process.png" 
import Extra03 from "../assets/images/digi_id/Refined Access Control Solution.png"
import Extra04 from "../assets/images/digi_id/Enabling Transparent Digital Consent.png"
import Extra05 from "../assets/images/digi_id/Nurturing Business Client Relationships.png"


// import ExtraSub01 from "../assets/images/extra/01_sub.png"
// import ExtraSub02 from "../assets/images/extra/02_sub.png"
// import ExtraSub03 from "../assets/images/extra/03_sub.png"
// import ExtraSub04 from "../assets/images/extra/04_sub.png"

// import Client01 from "../assets/images/client/client01.png"
// import Client02 from "../assets/images/client/client02.png"
// import Client03 from "../assets/images/client/client03.png"
// import Client04 from "../assets/images/client/client04.png"
// import Client05 from "../assets/images/client/client05.png"
// import Client06 from "../assets/images/client/client06.png"


// import Pricing01 from "../assets/images/extra/pricing/01.png"
// import Pricing02 from "../assets/images/extra/pricing/02.png"
// import Pricing03 from "../assets/images/extra/pricing/03.png"
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useLocation } from 'react-router-dom';

function Businesscard() {

    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
        const sectionIds = {
            businesscard: 'businesscard',
            digisign: 'digisign',
            accessmanage: 'accessmanage',
            digiconsent: 'digiconsent',
            clientmanage: 'clientmanage'
        };
        
        const params = new URLSearchParams(location.search);
        const scrollTo = params.get('scrollTo');
        
        const sectionId = sectionIds[scrollTo];
        if (sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                window.scroll({
                    top: element.offsetTop - 70,
                    behavior: 'smooth'
                });
            }
        }
    }, 100);
    }, [location.search]);
    

  return (
    <div>
       {/* <!--============= Banner Section Starts Here =============--> */}
    <section className="banner-16 bg_img_banner_16 oh bottom_left">
        <div className="container">
            <div className="row justify-content-center text-center">
                <div className="col-lg-8">
                    <div className="banner-content-3">
                        <h1 className="Newtitle">Digital Verified iD</h1>
                        <p>Unlock security and convenience with Neoin's Digital Verified iD</p>
                        <div className="banner-button-group mt-2 justify-content-center">
                            <a href="#0" className="button-4">Create ID</a>
                            {/* <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="play-button popup"> */}
                                {/* <img src={play2} alt="button"/>
                                <span className="cl-black">Play Video</span> */}
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='container text-center pt-5 mob-mb-40 tab-pb-100' >{/* <div className="banner-slider-area"> */}
                <div >{/* <div className="show-up"> */}
                    <img class="fit mob-pb-40" src={digiIdBanner} alt="banner" className='fit'/>
                </div>
                {/* <div className="mobile-slider-16 owl-theme owl-carousel">
                    <div className="mobile-item bg_img_screenBanner"></div>
                    <div className="mobile-item bg_img_screenBanner"></div>
                    <div className="mobile-item bg_img_screenBanner"></div>
                    <div className="mobile-item bg_img_screenBanner"></div>
                </div> */}

                {/* <OwlCarousel
                    className="mobile-slider-16"
                    loop
                    margin={10}
                    nav={false}
                    dots={false}
                    autoplay
                    autoplayTimeout={3000}
                    autoplayHoverPause
                    responsive={{
                    0: { items: 1 }, // Show 1 item on small screens
                    768: { items: 1 }, // Show 1 item on medium screens
                    1200: { items: 1 } // Show 1 item on large screens
                    }}>
                    <div className="mobile-item bg_img_screenBanner"></div>
                    <div className="mobile-item bg_img_screenBanner"></div>
                    <div className="mobile-item bg_img_screenBanner"></div>
                    <div className="mobile-item bg_img_screenBanner"></div>
                </OwlCarousel> */}
            </div>
        </div>
    </section>
    {/* <!--============= Banner Section Ends Here =============--> */}


    {/* <!--============= Logo Slider Section Starts Here =============--> */}
    {/* <section id="digital-card123" className="logo-slider-section padding-bottom padding-top">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="section-header mw-100">
                        <h3 className="title">Unlock security and convenience with Neoin's Digital Verified ID</h3>
                    </div>
                </div>
            </div> */}
            {/* <div className="logo-slider owl-theme owl-carousel">
                <div className="item">
                    <img src={Banner01} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner02} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner03} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner04} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner05} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner06} alt="banner"/>
                </div>
            </div> */}
        {/* </div>
    </section> */}
    {/* <!--============= Logo Slider Section Ends Here =============--> */}


    {/* <!--=============Feature Section Starts Here =============--> */}
    <section id='businesscard' className="feature-section">
        <div className="container">
            <div className="row align-items-center mb-40-none padding-bottom-2 mob-pt-40">
                <div className="col-lg-6 mb-40">
                    <div className="feature-thumb-16">
                        <img src={Extra01} alt="extra" className="fit tab-w-auto"/>
                        {/* <div className="anime-1"><img src={ExtraSub01} alt="extra"/></div>
                        <div className="anime-2"><img src={ExtraSub02} alt="extra"/></div> */}
                    </div>
                </div>
                <div className="col-lg-6 mb-40">
                    <div className="section-header left-style mb-0">
                        <h5 className="cate">Neoin's Networking Card</h5>
                        <h2 className="title">Dynamic Digital Business Profile</h2>
                        <p>Neoin offers a modern solution for networking and professional interactions with its digital business card feature. Users can create and customize their digital business cards, which can include personal, professional, and financial information. These cards are easily shareable via QR codes, enabling seamless networking and information exchange.</p>
                    </div>
                </div>
            </div>
            </div>
            </section>

            <section id='digisign' className="feature-section">
            <div className="container">
            <div className="row align-items-center flex-row-reverse mb-40-none padding-bottom-2">
                <div className="col-lg-6 mb-40">
                    <div className="feature-thumb-16">
                        <img src={Extra02} alt="extra" className="w-100 img-w-auto tab-w-auto"/>
                        {/* <div className="anime-1 anime-3"><img src={ExtraSub03} alt="extra"/></div>
                        <div className="anime-2"><img src={ExtraSub04} alt="extra"/></div> */}
                    </div>
                </div>
                <div className="col-lg-6 mb-40">
                    <div className="section-header left-style mb-0">
                        <h5 className="cate">Neoin's reliable Digital Signatures</h5>
                        <h2 className="title"> Simplifying Document Signing Process</h2>
                        <p>With Neoin's digital signature solution, users can sign documents securely and conveniently online. This feature ensures the authenticity and integrity of documents, making them legally binding. Whether it's contracts, agreements, or other important paperwork, Neoin's digital signature solution streamlines the signing process while maintaining compliance and security standards.</p>
                    </div>
                </div>
            </div>
            </div>
            </section>



            <section id='accessmanage' className="feature-section oh">
            <div className="container">
            <div className="row align-items-center mb-40-none padding-bottom-2">
                <div className="col-lg-6 mb-40">
                    <div className="feature-thumb-16">
                        <img src={Extra03} alt="extra" className="w-100 img-w-auto tab-w-auto"/>
                    </div>
                </div>
                <div className="col-lg-6 mb-40">
                    <div className="section-header left-style mb-0">
                        <h5 className="cate">Neoin's Secure Access Management</h5>
                        <h2 className="title">Refined Access Control Solution</h2>
                        <p>Neoin provides robust access management tools to control and monitor user access to sensitive information and resources. Through granular access controls and permissions, organizations can ensure that only authorized individuals have access to specific data and functionalities. This helps enhance security, confidentiality, and regulatory compliance.</p>
                    </div>
                </div>
            </div>
            </div>
            </section>

            <section id='digiconsent' className="feature-section oh">
            <div className="container">
            <div className="row align-items-center flex-row-reverse mb-40-none ">
                <div className="col-lg-6 mb-40">
                    <div className="feature-thumb-16">
                        <img src={Extra04} alt="extra" className="fit tab-w-auto"/>
                        {/* <div className="anime-1 anime-3"><img src={ExtraSub03} alt="extra"/></div>
                        <div className="anime-2"><img src={ExtraSub04} alt="extra"/></div> */}
                    </div>
                </div>
                <div className="col-lg-6 mb-40">
                    <div className="section-header left-style mb-0">
                        <h5 className="cate">Neoin's clear Digital Consent Solutions</h5>
                        <h2 className="title"> Enabling Transparent Digital Consent</h2>
                        <p>Neoin simplifies the process of obtaining and managing digital consent with its intuitive platform. Users can easily grant or revoke consent for the sharing of their personal or financial data based on specific requirements and preferences. This feature promotes transparency, privacy, and compliance with data protection regulations.</p>
                    </div>
                </div>
            </div>
            </div>
            </section>


            <section id='clientmanage' className="feature-section">
            <div className="container">
            <div className="row align-items-center mb-40-none padding-top-2 padding-bottom-2">
                <div className="col-lg-5 mb-40">
                    <div className="feature-thumb-16 text-center">
                        <img src={Extra05} alt="extra" className=""/>
                    </div>
                </div>
                <div className="col-lg-7 mb-40">
                    <div className="section-header left-style mb-0">
                        <h5 className="cate">Neoin's Client Relationship Management</h5>
                        <h2 className="title">Nurturing Business Client Relationships</h2>
                        <p>Neoin's client management solution empowers businesses to efficiently manage their client relationships and interactions. From tracking communication histories to analyzing client preferences and behaviors, this feature provides valuable insights for personalized engagement and service delivery. With Neoin, businesses can optimize client satisfaction and retention strategies.</p>
                    </div>
                </div>
            </div>
            </div>
            </section>
    {/* <!--=============Feature Section Ends Here =============--> */}


    {/* <!--=============Feature Section Starts Here =============--> */}
    {/* <section className="client-section light-blue-bg oh">
        <div className="container">
            <div className="client-wrapper-16">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-xl-8">
                        <div className="section-header mw-100 cl-white">
                            <h2 className="title">Mosto is Highly Appreciated by the Users</h2>
                        </div>
                    </div>
                    <div className="col-xl-10">
                        <div className="row justify-content-center ff-heading mb-30-none">
                            <div className="col-lg-6 col-sm-10">
                                <div className="appreciate-item">
                                    <div className="header">
                                        <h2 className="title cl-white">4.7 / 5</h2>
                                        <div className="ratings">
                                            <span><i className="fas fa-star"></i></span>
                                            <span><i className="fas fa-star"></i></span>
                                            <span><i className="fas fa-star"></i></span>
                                            <span><i className="fas fa-star"></i></span>
                                            <span><i className="fas fa-star"></i></span>
                                        </div>
                                    </div>
                                    <p className="cl-white">App rating based on more than 200000
                                        reviews on iOS and Android</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-10">
                                <div className="appreciate-item">
                                    <div className="header">
                                        <h2 className="title cl-white">9 589 053</h2>
                                    </div>
                                    <p className="cl-white">App rating based on more than 200000 reviews on iOS and Android</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="client-say-16">
                <div className="client-item-16 ff-heading">
                    <div className="client-content">
                        <div className="body-area">
                            <span className="head">Awesome App!</span>
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>“Ideas are easy. Implementation is hard.”</p>
                        </div>
                        <div className="heading-area">
                            <h5 className="title"><a href="#0">Jazmin Chowdhury</a></h5>
                            <span className="info">CEO & Founder</span>
                        </div>
                    </div>
                    <div className="client-thumb">
                        <a href="#0"><img src={Client01} alt=""/></a>
                    </div>
                </div>
                <div className="client-item-16 ff-heading active">
                    <div className="client-content active">
                        <div className="body-area">
                            <span className="head">Awesome App!</span>
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>“Ideas are easy. Implementation is hard.”</p>
                        </div>
                        <div className="heading-area">
                            <h5 className="title"><a href="#0">Flora Oliver</a></h5>
                            <span className="info">CEO & Founder</span>
                        </div>
                    </div>
                    <div className="client-thumb">
                        <a href="#0"><img src={Client02} alt=""/></a>
                    </div>
                </div>
                <div className="client-item-16 ff-heading">
                    <div className="client-content">
                        <div className="body-area">
                            <span className="head">Awesome App!</span>
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>“Ideas are easy. Implementation is hard.”</p>
                        </div>
                        <div className="heading-area">
                            <h5 className="title"><a href="#0">Perry Piper</a></h5>
                            <span className="info">CEO & Founder</span>
                        </div>
                    </div>
                    <div className="client-thumb">
                        <a href="#0"><img src={Client03} alt=""/></a>
                    </div>
                </div>
                <div className="client-item-16 ff-heading">
                    <div className="client-content">
                        <div className="body-area">
                            <span className="head">Awesome App!</span>
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>“Ideas are easy. Implementation is hard.”</p>
                        </div>
                        <div className="heading-area">
                            <h5 className="title"><a href="#0">Elsa Jean</a></h5>
                            <span className="info">CEO & Founder</span>
                        </div>
                    </div>
                    <div className="client-thumb">
                        <a href="#0"><img src={Client04} alt=""/></a>
                    </div>
                </div>
                <div className="client-item-16 ff-heading">
                    <div className="client-content">
                        <div className="body-area">
                            <span className="head">Awesome App!</span>
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>“Ideas are easy. Implementation is hard.”</p>
                        </div>
                        <div className="heading-area">
                            <h5 className="title"><a href="#0">Ava Adams</a></h5>
                            <span className="info">CEO & Founder</span>
                        </div>
                    </div>
                    <div className="client-thumb">
                        <a href="#0"><img src={Client05} alt=""/></a>
                    </div>
                </div>
                <div className="client-item-16 ff-heading">
                    <div className="client-content">
                        <div className="body-area">
                            <span className="head">Awesome App!</span>
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>“Ideas are easy. Implementation is hard.”</p>
                        </div>
                        <div className="heading-area">
                            <h5 className="title"><a href="#0">Dani Daniels</a></h5>
                            <span className="info">CEO & Founder</span>
                        </div>
                    </div>
                    <div className="client-thumb">
                        <a href="#0"><img src={Client06} alt=""/></a>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--=============Feature Section Ends Here =============--> */}


    {/* <!--=============Pricing Section Starts Here =============--> */}
    {/* <section className="pricing-section padding-top padding-bottom">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">Ready to have your best event yet?</h5>
                        <h2 className="title">Choose the all-inclusive
                            package best suited to you.</h2>
                        <p>
                            21 day free trial. No credit card required.
                        </p>
                    </div>
                </div>
            </div>
            <div className="tab-up">
                <ul className="tab-menu pricing-menu cl-black">
                    <li className="active">Monthly</li>
                    <li>Yearly</li>
                </ul>
                <div className="tab-area mb-sm-4">
                    <div className="tab-item active">
                        <div className="pricing-area-16">
                            <div className="pricing-item-16">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <img src={Pricing01} alt="extra/pricing"/>
                                    </div>
                                    <h5 className="subtitle">Starter</h5>
                                </div>
                                <ul>
                                    <li>Only pay when you publish</li>
                                    <li>Full Featured Event App</li>
                                    <li>Unlimited feature use</li>
                                    <li>24/7 Support</li>
                                    <li>Event Analytics</li>
                                </ul>
                                <h2 className="title"><sup>$</sup>499</h2>
                                <a href="#0" className="button-3 active">Start free trial</a>
                            </div>
                            <div className="pricing-item-16 active">
                                <div className="price-tags">Popular</div>
                                <div className="pricing-header">
                                    <div className="icon">
                                        <img src={Pricing02} alt="extra/pricing"/>
                                    </div>
                                    <h5 className="subtitle">Premium</h5>
                                </div>
                                <ul>
                                    <li>Only pay when you publish</li>
                                    <li>Full Featured Event App</li>
                                    <li>Unlimited feature use</li>
                                    <li>24/7 Support</li>
                                    <li>Event Analytics</li>
                                </ul>
                                <h2 className="title"><sup>$</sup>599</h2>
                                <a href="#0" className="button-3 active">Start free trial</a>
                            </div>
                            <div className="pricing-item-16">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <img src={Pricing03} alt="extra/pricing"/>
                                    </div>
                                    <h5 className="subtitle">Business</h5>
                                </div>
                                <ul>
                                    <li>Only pay when you publish</li>
                                    <li>Full Featured Event App</li>
                                    <li>Unlimited feature use</li>
                                    <li>24/7 Support</li>
                                    <li>Event Analytics</li>
                                </ul>
                                <h2 className="title"><sup>$</sup>699</h2>
                                <a href="#0" className="button-3 active">Start free trial</a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-item">
                        <div className="pricing-area-16">
                            <div className="pricing-item-16">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <img src={Pricing01} alt="extra/pricing"/>
                                    </div>
                                    <h5 className="subtitle">Starter</h5>
                                </div>
                                <ul>
                                    <li>Only pay when you publish</li>
                                    <li>Full Featured Event App</li>
                                    <li>Unlimited feature use</li>
                                    <li>24/7 Support</li>
                                    <li>Event Analytics</li>
                                </ul>
                                <h2 className="title"><sup>$</sup>499</h2>
                                <a href="#0" className="button-3 active">Start free trial</a>
                            </div>
                            <div className="pricing-item-16 active">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <img src={Pricing02} alt="extra/pricing"/>
                                    </div>
                                    <h5 className="subtitle">Premium</h5>
                                </div>
                                <ul>
                                    <li>Only pay when you publish</li>
                                    <li>Full Featured Event App</li>
                                    <li>Unlimited feature use</li>
                                    <li>24/7 Support</li>
                                    <li>Event Analytics</li>
                                </ul>
                                <h2 className="title"><sup>$</sup>599</h2>
                                <a href="#0" className="button-3 active">Start free trial</a>
                            </div>
                            <div className="pricing-item-16">
                                <div className="pricing-header">
                                    <div className="icon">
                                        <img src={Pricing03} alt="extra/pricing"/>
                                    </div>
                                    <h5 className="subtitle">Business</h5>
                                </div>
                                <ul>
                                    <li>Only pay when you publish</li>
                                    <li>Full Featured Event App</li>
                                    <li>Unlimited feature use</li>
                                    <li>24/7 Support</li>
                                    <li>Event Analytics</li>
                                </ul>
                                <h2 className="title"><sup>$</sup>699</h2>
                                <a href="#0" className="button-3 active">Start free trial</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="customize-area">
                        <h6 className="title mt-0">Want a customized solution to meet your specific needs?</h6>
                        <h5 className="subtitle"><a href="contact.html">Get in touch with us.</a></h5>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--=============Pricing Section Ends Here =============--> */}
    </div>
  )
}

export default Businesscard
