import React from 'react'

// import banner17 from "../assets/images/extra-2/banner/banner17.png"

import banner16_1 from "../assets/images/banner/banner16/01.png"
import banner16_2 from "../assets/images/banner/banner16/02.png"
import banner16_3 from "../assets/images/banner/banner16/03.png"
import banner16_4 from "../assets/images/banner/banner16/04.png"
import banner16_5 from "../assets/images/banner/banner16/05.png"
import banner16_6 from "../assets/images/banner/banner16/06.png"

// import amazing_1 from "../assets/images/extra-2/amazing/01.png"

// import amfea1 from "../assets/images/feature/am-fea1.png"
// import amfea2 from "../assets/images/feature/am-fea2.png"
// import amfea3 from "../assets/images/feature/am-fea3.png"
// import amfea4 from "../assets/images/feature/am-fea4.png"

import balls from "../assets/images/balls/balls.png"

import amazing_1 from "../assets/images/professional/mobile-mockups/mobile01.png"
import amazing_2 from "../assets/images/professional/mobile-mockups/mobile02.png"
import amazing_3 from "../assets/images/professional/mobile-mockups/mobile03.png"
import amazing_4 from "../assets/images/professional/mobile-mockups/mobile04.png"
// import amazing_5 from "../assets/images/professional/mobile-mockups/mobile05.png"

import am1 from "../assets/images/professional/Business & Finance.svg"
import am3 from "../assets/images/professional/Information Technology.svg"
import am2 from "../assets/images/professional/Healthcare & Medical.svg"
import am4 from "../assets/images/professional/Other Professionals.svg"

import profBanner from "../assets/images/professional/Banner img.png"

import advance1 from "../assets/images/professional/see-what-you-can-achieve.png"
import advance2 from "../assets/images/professional/Byte for byte.png"
import advance3 from "../assets/images/professional/Bringing Professionals and Patients.png"
import advance4 from "../assets/images/professional/Connecting Talents.png"


// import advance1 from "../assets/images/feature/advance1.png"
// import advance2 from "../assets/images/feature/advance2.png"
// import advance3 from "../assets/images/feature/advance3.png"

//import gear from "../assets/images/gear.png"

// import bannerbgdata from "../assets/images/extra-2/banner/banner-bg-17.png"
//import videobgdata from "../assets/images/extra-2/video/video-bg.png"

//pricing
// import top_shape from "../assets/css/img/top-shape-dark.png"
// import bottom_shape from"../assets/css/img/bottom-shape.png"

// import balls1 from "../assets/images/balls/1.png"
// import balls2 from "../assets/images/balls/2.png"
// import balls3 from "../assets/images/balls/3.png"
// import balls4 from "../assets/images/balls/4.png"
// import balls5 from "../assets/images/balls/5.png"
// import balls6 from "../assets/images/balls/6.png"
// import pricing5 from "../assets/images/pricing/pricing5.png"
// import pricing6 from "../assets/images/pricing/pricing6.png"
// import pricing7 from "../assets/images/pricing/pricing7.png"
//import bannershape9 from "../assets/css/img/banner-9-shape-without-shadow.png"
// import pricingbg from "../assets/images/bg/pricing-bg.jpg"
import Download from './Download'
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const ProfessionalPricing =() => {
  return (
    <>
        { /* <!--============= ScrollToTop Section Starts Here =============--> */ }
    {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
    <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
    <div className="overlay"></div>
    { /* <!--============= ScrollToTop Section Ends Here =============--> */ }


    { /* <!--============= Banner Section Starts Here =============--> */ }
    <section className="banner-17 bg_img_bnr_17 oh bottom_center">
        <div className="container">
            <div className="row align-item-start justify-content-between">
                <div className="col-lg-6">
                    <div className="banner-content-18 cl-white">
                        <h1 className="Newtitle title">An Innovative Platform for Professionals with Digital Experience</h1>
                        <p>
                            A simple, yet effective way to be more professional
                        </p>
                        <div className="banner-button-group justify-content-start">
                            <a href="/comingsoon" className="button-4">Create ID</a>
                                    {/* <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button popup">
                                        <i className="flaticon-play"></i>
                                    </a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="banner-thumb ProBannerImg">
                        <img src={profBanner} alt="banner" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Banner Section Ends Here =============--> */ }


    { /* <!--============= Logo Slider Section Starts Here =============--> */ }
    {/* <section className="logo-slider-section padding-bottom-2 padding-top">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="section-header mw-100">
                        <h3 className="title">Used by over 1,000,000 people worldwide</h3>
                    </div>
                </div>
            </div>
            <div className="logo-slider owl-theme owl-carousel">
                <div className="item">
                    <img src={banner16_1} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_2} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_3} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_4} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_5} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_6} alt="banner" />
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Logo Slider Section Ends Here =============--> */ }


    { /* <!--============= Amazing Section Starts Here =============--> */ }
    {/* <section className="amazing-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div className="section-header mw-725">
                <h5 className="cate">Cool & Amazing Design</h5>
                <h2 className="title">Amazing Mobile App</h2>
                <p>
                    We built it from ground up to give you an amazing experience
                </p>
            </div>
            <div className="amazing-slider pl-md-3 pl-2 owl-theme owl-carousel">
                <div className="amazing-thumb">
                    <img src={amazing_1} alt="extra-2/amazing" />
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Amazing Section Ends Here =============--> */ }


    { /* <!--============= Feature Section Starts Here =============--> */ }

    <section className="feature-section prof-feature-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div className="feature--wrapper-17">
                <div className="row align-items-center flex-wrap-reverse position-relative">
                    <div className="col-lg-6">
                        <div className="row mb-30-none">
                            <div className="col-md-6">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={am1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Business & Finance</h5>
                                    </div>
                                </div>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={am2} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Healthcare & Medical</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-md-120">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={am3} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Information Technology</h5>
                                    </div>
                                </div>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={am4} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Other Professionals</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-header left-style mb-lg-0 mob-pt-40">
                            <h5 className="cate">Versatile Featureset</h5>
                            <h2 className="title">Comprehensive Solutions Tailored for Professional Industries</h2>
                            <p>
                            Enhancing professional experiences with verified iD users, business cards, discounts, and seamless connectivity for enhanced productivity and success.                            </p>
                            {/* <a href="#0" className="hightlight-link">Show all features <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Feature Section Ends Here =============--> */}

    { /* <!--============= Advance Feature Section Starts Here =============--> */ }
    <section className="advance-feature-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div id="advance-features" className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb ProfessionalImg">
                    <img  src={advance1} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Benefits for Business & Financial Professionals</h5>
                        <h2 className="title">See what you can achieve!</h2>
                        <p>Business and financial professionals, such as <span className='first-word'>Chartered Accountant, Company Secretary, Tax consultant, HR specialists,</span> and other fields, enjoy perks such as business cards and enhanced networking through Verified iD.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div id='collab' className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb ProfessionalImg">
                    <img  src={advance2} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">IT Benefit Catalog</h5>
                        <h2 className="title">Byte for byte, terabyte for verified users!</h2>
                        <p>Information technology includes software development and network management, providing <span className='first-word'>digital tools</span> and <span className='first-word'>connectivity benefits</span>, while verified iD users in IT also access advanced networking for streamlined collaboration.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div id='discover' className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb ProfessionalImg">
                    <img  src={advance3} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Uniting Professionals and Patients</h5>
                        <h2 className="title">Bringing Professionals and Patients Closer for Better Care!</h2>
                        <p>Healthcare professionals like <span className='first-word'>doctors, nurses, therapists, and patients</span> benefit from perks such as consent, connection, and engagement through Verified iD within the ecosystem.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div id='flex' className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb ProfessionalImg">
                    <img  src={advance4} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Where Diverse Professions Thrive</h5>
                        <h2 className="title">Connecting Talents for Impact for All Other Professionals!</h2>
                        <p>Other professionals, including <span className='first-word'>Lawyers, Educators, Engineers, and Artists,</span> enjoy benefits such as networking, collaboration, and engagement through Verified iD within the ecosystem.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Advance Feature Section Ends Here =============--> */ }

    { /* <!--============= Amazing Section Starts Here =============--> */ }
    <section className="amazing-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div className="section-header mw-725">
                <h5 className="cate"> User Digital Identity Gallery</h5>
                <h2 className="title"> Customizable Cards for Each Verified Users</h2>
                <p>
                    We built it from the ground up to give you an amazing experience
                </p>
            </div>
            {/* <div className="amazing-slider pl-md-3 pl-2 owl-theme owl-carousel"> */}
            <OwlCarousel className="amazing-slider pl-md-3 pl-2 "
                            loop                            
                            nav={false}
                            dots={false}
                            autoplay
                            autoplayTimeout={1000}
                            autoplayHoverPause
                            responsive={{
                                0: { items: 1 }, // Show 1 item on small screens
                                768: { items: 2}, // Show 1 item on medium screens
                                1200: { items: 3 } // Show 1 item on large screens
                          }}>
                <div className="amazing-thumb">
                    <img className='StyleImg-Professional' src={amazing_2} alt="extra-2/amazing" />
                </div>
                <div className="amazing-thumb">
                    <img className='StyleImg-Professional' src={amazing_1} alt="extra-2/amazing" />
                </div>
                <div className="amazing-thumb">
                    <img className='StyleImg-Professional' src={amazing_3} alt="extra-2/amazing" />
                </div>
                <div className="amazing-thumb">
                    <img className='StyleImg-Professional' src={amazing_4} alt="extra-2/amazing" />
                </div>
                {/* <div className="amazing-thumb">
                    <img className='StyleImg-Professional' src={amazing_5} alt="extra-2/amazing" />
                </div> */}

            </OwlCarousel>
        </div>
    </section>
    { /* <!--============= Amazing Section Ends Here =============--> */ }



    { /* <!--============= Logo Slider Section Starts Here =============--> */ }
    <section className="logo-slider-section pt-40 padding-bottom-2 mob-pt-20">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="section-header mw-100">
                        <h3 className="title">Used by over 1,000,000 people worldwide</h3>
                    </div>
                </div>
            </div>
            <OwlCarousel className="logo-slider"
                            loop
                            margin={10}
                            nav={false}
                            dots={false}
                            autoplay
                            autoplayTimeout={1000}
                            autoplayHoverPause
                            responsive={{
                                0: { items: 6 }, // Show 1 item on small screens
                                768: { items: 6}, // Show 1 item on medium screens
                                1200: { items: 6 } // Show 1 item on large screens
                          }}>
                <div className="item">
                    <img src={banner16_1} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_2} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_3} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_4} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_5} alt="banner" />
                </div>
                <div className="item">
                    <img src={banner16_6} alt="banner" />
                </div>
            </OwlCarousel>
        </div>
    </section>
    { /* <!--============= Logo Slider Section Ends Here =============--> */ }


    


    { /* <!--============= Feature Section Starts Here =============--> */ }
    {/* <section className="feature-section padding-top-2 padding-bottom-2">
        <div className="container">
            <div className="feature--wrapper-17">
                <div className="row align-items-center flex-wrap-reverse position-relative">
                    <div className="col-lg-6">
                        <div className="row mb-30-none">
                            <div className="col-md-6">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={amfea1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Free Trial</h5>
                                    </div>
                                </div>
                                <div className="am-item active">
                                    <div className="am-thumb">
                                        <img src={amfea2} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Secure Data</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-md-120">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={amfea3} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Quick Access</h5>
                                    </div>
                                </div>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={amfea4} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">24h Support</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-header left-style mb-lg-0">
                            <h5 className="cate">An Exhaustive list of Amazing Features</h5>
                            <h2 className="title">Make Your Own
                                Success As Simple
                                You Clap</h2>
                            <p>
                                Numerous features make it possible to customize the system in accordance with all your needs.
                            </p>
                            <a href="#0" className="hightlight-link">Show all features <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Feature Section Ends Here =============--> */ }

    { /* <!--============= Pricing Section Ends Here =============--> */ }
    {/* <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img_pricing pos-rel" id="pricing">
        <div className="top-shape d-none d-md-block">
            <img src={top_shape} alt="css" />
        </div>
        <div className="bottom-shape d-none d-md-block">
            <img src={bottom_shape} alt="css" />
        </div>
        <div className="ball-2" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls1} alt="balls" />
        </div>
        <div className="ball-3" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls2} alt="balls" />
        </div>
        <div className="ball-4" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls3} alt="balls" />
        </div>
        <div className="ball-5" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src={balls4} alt="balls" />
        </div>
        <div className="ball-6" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls5} alt="balls" />
        </div>
        <div className="ball-7" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src={balls6} alt="balls" />
        </div>
        <div className="container">
            <div className="section-header pb-30 cl-white">
                <h5 className="cate">Choose a plan that's right for you</h5>
                <h2 className="title">Simple Pricing Plans</h2>
                <p>
                    Mosto has plans, from free to paid, that scale with your needs. Subscribe to a plan that fits the size of your business.
                </p>
            </div>
            <div className="row justify-content-center mb-30-none">
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">basic</span>
                        <div className="pricing-thumb">
                            <img src={pricing5} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>30.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">standard</span>
                        <div className="pricing-thumb">
                            <img src={pricing6} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>50.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block active">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">premium</span>
                        <div className="pricing-thumb">
                            <img src={pricing7} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>90.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
   {/* <!--============= Pricing Section Ends Here =============--> */}
   
   {/* <div className="text-center mt-70">
                        <p>Want a customized solution to meet your specific needs?</p>
                        <a href="#0" className="show-feature" style={{ color: '#00E9E7' }}>Get in touch with us.</a>


    </div> */}

{ /* <!--============= Trial Section Starts Here =============--> */ }
    <section className="trial-section padding-bottom-2 padding-top-2 mob-pb-40">
        <div className="container">
            <div className="trial-wrapper padding-top padding-bottom pr">
                <div className="ball-1">
                    <img src={balls} alt="balls" />
                </div>
                <div className="trial-content cl-white">
                    <h3 className="title">Customize your plan!</h3>
                    <p>
                        Get connected with our <br/> Team Sales for more information.
                    </p>
                </div>
                <div className="trial-button">
                    <a href="/contact" className="transparent-button">Contact Us <i className="flaticon-right ml-xl-2"></i></a>
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Trial Section Ends Here =============--> */ }

   <Download />

    </>
  )
}

export default ProfessionalPricing