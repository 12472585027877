import React from 'react'

// import banner1 from "../assets/images/banner/banner-bg-1.jpg"
import dotbig from "../assets/images/banner/dot-big.png"
import dotsm from "../assets/images/banner/dot-sm.png"
import bannershape from "../assets/css/img/banner1-shape-dark.png"
// import banner01 from "../assets/images/banner/banner1-1.png"
// import banner02 from "../assets/images/banner/banner1-2.png"
// import banner03 from "../assets/images/banner/banner1-3.png"

import studentBanner from "../assets/images/student/Navigating Digital Opportunity.png";

// import click from "../assets/images/banner/click.png"
// import arrow from "../assets/images/banner/arrow.png"

import how1 from "../assets/images/how/how2.png"
import how2 from "../assets/images/how/how1.png"
import how3 from "../assets/images/how/how3.png"

// import newsletter2 from "../assets/images/bg/newslater2.jpg"
import topshape2 from "../assets/css/img/top-shape2-dark.png"
import bottomshape from "../assets/css/img/bottom-shape2-dark.png"

// import newslatter3 from "../assets/images/newslater/newslater2.png"
import digiCampus from "../assets/images/student/Step into Digital Campus.png"
import advance1 from "../assets/images/student/Features that cultivate.png"
import advance2 from "../assets/images/student/Budget-Friendly Student Deals.png"
import advance3 from "../assets/images/student/AI-Enhanced Profile.png"

// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Download from './Download'

function Students() {
    return (
        <div>
            {/* <!--============= Banner Section Starts Here =============--> */}
            <section className=" banner-1 bg_img_studentbanner oh Studentbanner">
                <div className="dot-1 d-none d-lg-block">
                    <img src={dotbig} alt="banner" />
                </div>
                <div className="dot-2 d-none d-lg-block">
                    <img src={dotbig} alt="banner" />
                </div>
                <div className="dot-3">
                    <img src={dotsm} alt="banner" />
                </div>
                <div className="dot-4">
                    <img src={dotsm} alt="banner" />
                </div>
                <div className="banner-1-shape d-none d-lg-block">
                    <img src={bannershape} alt="css" />
                </div>
                <div className="container">
                    <div className="row align-items-top justify-content-between">
                        <div className="col-lg-6">
                            <div className="banner-content-1 cl-white">
                                <h1 className="Newtitle">Navigating Digital Opportunity for Students</h1>
                                <p>
                                    The assured access to digital resources for students includes the essential Digital Campus.
                                </p>
                                <div className="banner-button-group mob-pb-40">
                                    <a href="/comingsoon" className="button-4">Create ID</a>
                                    {/* <a href="#0" className="button-4 active">Explore Features</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="Studentbannerimg col-lg-5">
                            <div>
                                <img src={studentBanner} alt='Student-Page-img'/>
                            </div>
                        </div>
                            {/* <div className="banner-1-slider-wrapper"> */}
                                {/* <div className="banner-1-slider owl-carousel owl-theme">
                                    <div className="banner-thumb">
                                        <img src={banner01} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner02} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner03} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner01} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner02} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner03} alt="banner" />
                                    </div>
                                </div> */}

                                {/* <OwlCarousel className="banner-1-slider"
                                    loop
                                    margin={0}
                                    padding={0}
                                    nav={false}
                                    dots={false} // Show dots navigation
                                    autoplay
                                    autoplayTimeout={3000}
                                    autoplayHoverPause
                                    responsive={{
                                        0: { items: 1 }, // Show 1 item on small screens
                                        768: { items: 1 }, // Show 3 items on medium screens
                                        1200: { items: 1 } // Show 3 items on large screens
                                    }}>
                                    <div className="banner-thumb">
                                        <img src={banner01} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner02} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner03} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner01} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner02} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner03} alt="banner" />
                                    </div>
                                </OwlCarousel>
                                
                                <div className="ban-click">
                                    <div className="Newban-nav ">
                                        <a href="#0" className="ban-prev active .Newban-nav">
                                        Click here <i className="flaticon-right lefticon"></i>
                                        </a>
                                    </div> */}
                                    {/* <div className="thumb">
                                        <img src={click} alt="banner" />
                                    </div>
                                    <span className="cl-white">Click here</span> */}
                                {/* </div> */}
                                    {/* <div className="arrow">
                                        <img src={arrow} alt="banner" />
                                    </div> */}
                            {/* </div> */}
                        
                    </div>
                </div>
            </section>
            {/* <!--============= Banner Section Ends Here =============--> */}

            {/* <!--============= How Section Starts Here =============--> */}
            <section className="how-section padding-bottom-2 padding-top-2" id="how">
                <div className="container">
                    <div className="section-header">
                        <h5 className="cate">Elevate Your Educational Persona</h5>
                        <h2 className="title">Let’s Discover How It Work</h2>
                        <p>Follow 3 simple easy steps</p>
                    </div>
                    <div className="row justify-content-xl-between justify-content-center mb-40-none">
                        <div className="col-lg-4 col-sm-6 col-xl-3">
                            <div className="how-item">
                                <div className="how-thumb">
                                    <img src={how1} alt="how" />
                                </div>
                                <div className="how-content">
                                    {/* <a href="#0" className="button-3 active">Download App</a>
                            <ul className="download-options">
                                <li>
                                    <a href="#0"><i className="fab fa-windows"></i></a>
                                </li>
                                <li>
                                    <a href="#0" className="active"><i className="fab fa-apple"></i></a>
                                </li>
                                <li>
                                    <a href="#0"><i className="fab fa-android"></i></a>
                                </li>
                            </ul> */}
                                    <p className='text-center'>Establish your online identity and verify your credentials to build trust and credibility within your network.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xl-3">
                            <div className="how-item">
                                <div className="how-thumb">
                                    <img src={how2} alt="how" />
                                </div>
                                <div className="how-content">
                                    {/* <a href="#0" className="button-3">Create Account</a>
                            <h5 className="title">14 days free trial</h5> */}
                                    <p className='text-center'>Develop your Knowledge base with a wealth of educational resources and personalized learning pathways.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xl-3">
                            <div className="how-item">
                                <div className="how-thumb">
                                    <img src={how3} alt="how" />
                                </div>
                                <div className="how-content">
                                    {/* <a href="#0" className="button-3">Enjoy The App</a>
                            <h5 className="title">Have any questions
                                check our <a href="#0">FAQs</a></h5> */}
                                    <p className='text-center'>Explore job opportunities tailored to your skills and preferences, accelerating your career growth and advancement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= How Section Ends Here =============--> */}

            {/* <!--============= Advance Feature Section Starts Here =============--> */}
            <section className="advance-feature-section padding-bottom-2">
                <div className="container">
                    <div className="advance-feature-item">
                        <div className=" advance-feature-thumb Studentsimg">
                            <img src={advance1} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Unleash Your Potential as a Student!</h5>
                                <h2 className="title">Features that cultivate thriving career communities.</h2>
                                <p>Enrich your academic journey, network with peers, and engage in interactive learning to unlock career opportunities, including <span className='first-word'>jobs</span> and <span className='first-word'>internships</span>.
                                </p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    <div className="advance-feature-item padding-top-2">
                        <div className="advance-feature-thumb Studentsimg">
                            <img src={advance2} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Student Discounts Galore</h5>
                                <h2 className="title">Budget-Friendly Student Deals</h2>
                                <p>Access <span className='first-word'>exclusive discounts</span> and <span className='first-word'>irresistible offers</span> from a curated selection of <span className='first-word'>top brands</span>, ensuring students can enjoy high-quality products at budget-friendly prices.</p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    <div className="advance-feature-item padding-top-2">
                        <div className="advance-feature-thumb Studentsimg">
                            <img src={advance3} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Smart Profile Building</h5>
                                <h2 className="title">AI-Driven Student Profile Optimization</h2>
                                <p>Elevate your <span className='first-word'>personal, professional,</span> and <span className='first-word'>financial score</span> with <span className='first-word'>AI-driven recommendations</span> tailored to enhance your profile and accelerate your success.</p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    {/* <div className="advance-feature-item padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb">
                    <img src={advance1} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Advance features give you full control.</h5>
                        <h2 className="title">See what you can achieve!</h2>
                        <p>The simple, intuitive user interface is designed to help you see exactly what you need to focus on.As a team member you can focus on your work and as a team leader, you can easily manage your team.</p>
                    </div>
                    <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a>
                </div>
            </div> */}

                </div>
            </section>
            {/* <!--============= Advance Feature Section Ends Here =============--> */}

            {/* <!--============= Newslater Section Starts Here =============--> */}
            <section className="newslater-section oh bg_img_digicampus pos-rel mb-50 mb-max-lg-0">
                <div className="top-shape d-none d-lg-block">
                    <img src={topshape2} alt="css" />
                </div>
                <div className="bottom-shape d-none d-lg-block">
                    <img src={bottomshape} alt="css" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="newslater-area padding-bottom-2 padding-top-2">
                                <div className="section-header left-style cl-white">
                                    <h5 className="cate">Expand Your Digital Campus Experience</h5>
                                    <h2 className="title">Step into Digital Campus</h2>
                                    <p>
                                    Discover the Digital Campus! Dive into opportunities for academic and professional growth. From internships to networking and skill development, it's your gateway to success. Join us and unlock your potential.
                                    </p>
                                </div>
                                {/* <form className="subscribe-form">
                            <input type="text" placeholder="Enter Your Email"/>
                            <button type="submit">subscribe</button>
                        </form> */}
                            </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="subscribe-thumb-2">
                                <img src={digiCampus} alt="newslater" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Newslater Section Ends Here =============--> */}

            <Download />

        </div>
    )
}

export default Students