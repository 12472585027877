import React from 'react'
import pageheader1 from "../assets/css/img/page-header.png"
import privacy from "../assets/images/bg/privacy-header.png";
import joinus from "../assets/images/Career_img.png"

const Careers = () => {
  return (
    <>
     <section className="page-header bg_img_pg_header oh">
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageheader1} alt="css" />
                </div>
                <div className="page-left-thumb">
                    <img src={privacy} alt="bg" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Careers</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                Careers
                            </li>
                        </ul>
                    </div>
                </div>
        </section>

        <section className="advance-feature-section padding-bottom-2 oh">
                <div className="container">
                    <div className="advance-feature-item style-two padding-top-2 padding-bottom-2 who-we-are">
                        <div className="advance-feature-thumb">
                            <img src={joinus} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Efficiency at your fingertips.</h5>
                                <h2 className="title">Who we are</h2>
                                <p>
                                    <span className="first-word" >NEOIN,</span> a Digital iD technology with verified Data engine, enhance customer trust by providing Digital Identity, Identity Management, Access Control, Data Security and Privacy with personalised Data Analytics and Insights which provides reassurance about authenticity and security of online engagements across digital ecosystem by implementing robust authentication mechanisms that can protect sensitive data and mitigate the risk of identity theft or fraud.</p>
                            </div>
                            <a href="/about" className="get-button">KNOW MORE</a>
                            {/* <a href="#0" className="get-button">KNOW MORE<i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="addon-section" id="addon">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="section-header pb-30 mw-100">
                                <h5 className="cate">We Offer You</h5>
                                <h3 className="title">Great Benefits for Working with Us</h3>

                                {/* <p className='mt-3'>
                                    Provides a secure login that can store multiple ID cards, KYC Card, Employee Card, Student Card with Verified Credentials for their Personal,
                                    Professional and Financial ecosystem
                                </p> */}
                                <p className='mt-3'>
                                Join a team that values growth, innovation, and work-life balance. Enjoy competitive benefits, a diverse and inclusive environment, and meaningful work. Collaborate with talented individuals and be recognized for your contributions. Start your journey with us and shape the future together.</p>
                                {/* <p className='mt-3'>
                                    Provides a secure login that can store multiple ID cards, KYC Card, Employee Card, Student Card with Verified Credentials for their Personal,
                                    Professional and Financial ecosystem
                                </p> */}
                                <p className='mt-3'>Send your resume to <a href='Mailto:admin@neoin.co'>admin.hr@dalocal.co</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="advance-feature-section padding-bottom-2">
                <div className="container">
                            <div className="advance-feature-content">
                                <div className="section-header left-style mb-olpo">
                                    <h3 className="title">Current job opportunity</h3>
                                </div>
                            </div>
                            <div className="section-header left-style mb-4">
                                <span className="cate">Full Time| Bengaluru</span>
                            </div>
                            <div className="career-container">
                                <h5 className="myinline-block">Project Lead</h5>
                                <p className="myinline-block">Lead experienced teams in tech projects, leveraging ReactJS, Node.js, Flutter/Dart, MongoDB, cloud SQL, SaaS, and APIs. Strong leadership, coding, and architecture skills needed for enterprise & cloud solutions.</p>
                                <div className="banner-button-group justify-content-center mob-pt-20 mob-pb-20">
                                <a href="mailto:admin.hr@dalocal.co" className="button-4 career-btn">Apply Now</a>                                
                                </div>
                            </div>
                            {/* <div className="mt-3 mb-3 myline"></div> */}
                            {/* <div className="section-header left-style mb-4">
                                <span className="cate">Full Time| Bengaluru</span>
                            </div> */}
                            {/* <div className="career-container">
                                <h5 className="myinline-block">UI and Web Developer</h5>
                                <p className="myinline-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#0" className="myinline-block get-button">Apply Now</a>
                            </div> */}
                            {/* <div className="mt-3 mb-3 myline"></div>
                            <div className="section-header left-style mb-4">
                                <span className="cate">Full Time| Bengaluru</span>
                            </div>
                            <div className="career-container">
                                <h5 className="myinline-block">UI and Web Developer</h5>
                                <p className="myinline-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <a href="#0" className="myinline-block get-button">Apply Now</a>
                            </div> */}
                </div>
            </section>                       
    </>
    
  )
}

export default Careers