import logoImage from "../assets/images/Neoin Logo.svg";
import locationIcon from "../assets/images/socials/Location.svg";
import emailIcon from "../assets/images/socials/Email.svg";
import dalocalIcon from "../assets/images/socials/dalocal.svg";
import linkedinIcon from "../assets/images/socials/linkedin.svg";
import twitterIcon from "../assets/images/socials/tweeter.svg";
import discordIcon from "../assets/images/socials/discord.svg";
import instagramIcon from "../assets/images/socials/instagram.svg";
import facebookIcon from "../assets/images/socials/facebook.svg";
import youtubeIcon from "../assets/images/socials/youtube.svg";
import whatsappIcon from "../assets/images/socials/whatsapp.svg";
import bdoIcon from "../assets/images/socials/BDO-badge.png";
import pdpaIcon from "../assets/images/socials/PDPA.png";
import gdprIcon from "../assets/images/socials/GDPR.png";
import React from "react";
import "../assets/css/Footer.css";

// import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const isSmallLaptop = useMediaQuery({ maxWidth: 1200 });

  let footerContent;

  if(isSmallScreen) {
    footerContent = ( // Smaller Screens: Tabs and Mobile phones (<= 767px)
    <div className="mob-footer-content">
      {/* Introduction */}
      <div className="mb-3 ml-0">
        <div className="mb-5" id="logo">
          <a href="/">
            <img
              src={logoImage}
              alt="Company Logo"
              className="logo-image"
            ></img>
          </a>
        </div>
        <p id="footer-desc">
          A revolution for the communities to connect, communicate and
          engage GLocally on a Single Click.
        </p>
        <br />
      </div>

      {/* Collapsible Lists */}
      <div className="mb-5">
        <h5 className="mb-2" data-toggle="collapse" data-target="#exploreList">
          <span className="footer-collapsible-list">
          <span>Explore</span>
          <i className="fas fa-chevron-down ml-2"></i>
          </span>
        </h5>
        <div id="exploreList" className="collapse">
          <ul className="list-unstyled">
            <li className="mb-2">
              <a href="/sitemap">Sitemap</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/dalocalcommingsoon">Discounts</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/partners">Partners</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/comingsoon">Investors</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/testimonials">Testimonials</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/blogs">Blogs</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/faq">FAQs</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/help">Help Center</a>{" "}
            </li>
          </ul>
        </div>
      </div>

      <div className="mb-5">
        <h5 className="mb-2" data-toggle="collapse" data-target="#companyList">
        <span className="footer-collapsible-list">
          <span>Company</span>
          <i className="fas fa-chevron-down ml-2"></i>
          </span>
        </h5>
        <div id="companyList" className="collapse">
          <ul className="list-unstyled">
            <li className="mb-2">
              <a href="/about">About Us</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/careers">Careers</a>{" "}
            </li>
            <li className="mb-2">
              {" "}
              <a href="/terms">Terms & Conditions</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/privacy">Privacy Policies</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/cookies">Cookies</a>{" "}
            </li>
          </ul>
        </div>
      </div>

      <div className="mb-5">
        <h5 className="mb-2" data-toggle="collapse" data-target="#visitList">
        <span className="footer-collapsible-list">
          <span id="country-addresses">Visit Us</span>
          <i className="fas fa-chevron-down ml-2"></i>
          </span>
        </h5>
        <div id="visitList" className="collapse">
          <p className="country mb-2">Singapore</p>
          <div className="row contact-items gutter-sm">
            <div className="col-1 contact-icon d-flex">
              <img src={locationIcon} alt="LOC" />
            </div>
            <div className="col-11">
              <p className="address">
                #30, Tanah Merah Kechil Road, 09-02, East Meadows, Singapore :
                465558
              </p>
            </div>
          </div>
          <div className="row contact-items gutter-sm">
            <div className="col-1 contact-icon">
              <img src={emailIcon} alt="EML" />
            </div>
            <div className="col-11">
              <p className="email-address">admin@neoin.co</p>
            </div>
          </div>
          <br />
          <p className="country mb-2">India</p>
          <div className="row contact-items gutter-sm">
            <div className="col-1 contact-icon d-flex">
              <img src={locationIcon} alt="LOC" />
            </div>
            <div className="col-11">
              <p className="address">
                #304, 3rd Floor, #92, Kedia Arcade, Infantry Road, Bengaluru,
                Karnataka, India : 560001
              </p>
            </div>
          </div>
          <div className="row contact-items gutter-sm">
            <div className="col-1 contact-icon">
              <img src={emailIcon} alt="EML" />
            </div>
            <div className="col-11">
              <p className="email-address">dalocal@dalocal.co</p>
            </div>
          </div>
        </div>
      </div>

      {/* NewsLetter input field */}
      <div className="input-button-wrapper mb-5">
        <h5 className="newsletter mb-3">Subscribe for Newsletters</h5>
        <div className="email-wrapper">
          <input className="email-field" placeholder="E-mail" />
          <button href="#0" className="button-4 subscribe" id="banner-btn">
            SUBSCRIBE
          </button>
        </div>
      </div>

      {/* Social Icons */}
      <h6 className="mb-3"> Lets Connect, Communicate & Engage!</h6>
      <div className="row social-icons mb-4">
        <div className="col">
          <a href="/dalocalcommingsoon" target="_blank" rel="noreferrer">
            <img src={dalocalIcon} alt="Social Icon 1" />
          </a>
        </div>
        <div className="col">
          <a href="https://sg.linkedin.com/company/neoinco" target="_blank" rel="noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
        <div className="col">
          <a href="/comingsoon" target="_blank" rel="noreferrer">
            <img src={twitterIcon} alt="Twitter" />
          </a>
        </div>
        <div className="col">
          <a href="/comingsoon" target="_blank" rel="noreferrer">
            <img src={discordIcon} alt="Discord" />
          </a>
        </div>
        {/* <div className="col"></div> */}
      </div>

      {/* Second row of social icons */}
      <div className="row social-icons">
        <div className="col">
          <a href="/comingsoon" target="_blank" rel="noreferrer">
            <img src={instagramIcon} alt="Instagram" />
          </a>
        </div>
        <div className="col">
          <a href="/comingsoon" target="_blank" rel="noreferrer">
            <img src={facebookIcon} alt="Facebook" />
          </a>
        </div>
        <div className="col">
          <a href="https://www.youtube.com/@NEOIN-DALOCAL" target="_blank" rel="noreferrer">
            <img src={youtubeIcon} alt="YouTube" />
          </a>
        </div>
        <div className="col">
          <a href="/comingsoon" target="_blank" rel="noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" />
          </a>
        </div>
        {/* <div className="col"></div> */}
      </div>

      {/* Trust Badges Section */}
      <h6 className="mb-3 mt-4"> Trust starts here</h6>
      <div className="row mb-3 badges">
        <div className="col-4">
          <img src={bdoIcon} alt="BDO" />
        </div>
        <div className="col-4">
          <img src={pdpaIcon} alt="PDA" />
        </div>
        <div className="col-4">
          <img src={gdprIcon} alt="GDPR" />
        </div>
      </div>
    </div>
  )
  }
  else if(isMediumScreen) {
    footerContent = ( // Meidum Screens: Tabs (>= 768px && <= 992px)
      <div className="tab-footer-content">
        <br />
        <br />
        <div className="row bottom-nav">
        <div className="col-6 mb-3 ml-0">
          <div className="mb-5" id="logo">
            <a href="/">
              <img
                src={logoImage}
                alt="Company Logo"
                className="logo-image"
              ></img>
            </a>
          </div>
          <p id="footer-desc">
            A revolution for the communities to connect, communicate and
            engage GLocally on a Single Click.
          </p>
          <br />
          <h6 className="mb-3"> Lets Connect, Communicate & Engage!</h6>
          <div className="row social-icons mb-3">
            <div className="col">
              <a href="/dalocalcommingsoon" target="_blank" rel="noreferrer">
                <img src={dalocalIcon} alt="Social Icon 1" />
              </a>
            </div>
            <div className="col">
              <a href="https://sg.linkedin.com/company/neoinco" target="_blank" rel="noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={twitterIcon} alt="Twitter" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={discordIcon} alt="Discord" />
              </a>
            </div>
            {/* <div className="col"></div> */}
          </div>

          {/* Second row of social icons */}
          <div className="row social-icons">
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={instagramIcon} alt="Instagram" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={facebookIcon} alt="Facebook" />
              </a>
            </div>
            <div className="col">
              <a href="https://www.youtube.com/@NEOIN-DALOCAL" target="_blank" rel="noreferrer">
                <img src={youtubeIcon} alt="YouTube" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={whatsappIcon} alt="WhatsApp" />
              </a>
            </div>
            {/* <div className="col"></div> */}
          </div>
        </div>
        <div className="col-3 mb-3 mt-2 ml-0">
          <h5 className="mb-3">Explore</h5>
          <ul className="list-unstyled">
            <li className="mb-2">
              <a href="/sitemap">Sitemap</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/dalocalcommingsoon">Discounts</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/partners">Partners</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/comingsoon">Investors</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/testimonials">Testimonials</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/blogs">Blogs</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/faq">FAQs</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/help">Help Center</a>{" "}
            </li>
          </ul>
        </div>

        <div className="col-3 mb-3 mt-2 pl-0">
          <h5 className="mb-3">Company</h5>
          <ul className="list-unstyled">
            <li className="mb-2">
              <a href="/about">About Us</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/careers">Careers</a>{" "}
            </li>
            <li className="mb-2">
              {" "}
              <a href="/terms">Terms & Conditions</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/privacy">Privacy Policies</a>{" "}
            </li>
            <li className="mb-2">
              <a href="/cookies">Cookies</a>{" "}
            </li>
          </ul>
          <br />
        </div>
        </div>
        <div className="mb-3 mt-2">
          <h5 className="mb-3" id="country-addresses">Visit Us</h5>
          <div className="row address-and-mail">
              <div className="col-md-6 col-sm-12 sing-add">
                <p className="country mb-2">Singapore</p>
                <div className="row contact-items">
                  <div className="col-2 loc-icon d-flex">
                    <img src={locationIcon} alt="LOC" />
                  </div>
                  <div className="col-10">
                    <p className="address">
                      #30, Tanah Merah Kechil Road, 09-02, East Meadows, Singapore :
                      465558
                    </p>
                  </div>
                </div>
                <div className="row contact-items pt-2">
                  <div className="col-2 mail-icon">
                    <img src={emailIcon} alt="EML" />
                  </div>
                  <div className="col-10">
                    <a href="Mailto:admin@neoin.co">
                      <p className="email-address">admin@neoin.co</p>
                    </a>
                  </div>
                </div>
              </div>
              {/* </div>
         <br /> 
        <div className="col-md-2 col-sm-6 mb-3 mt-2"> */}
              {/* <h5 className="mb-3" style={{ color: "#262b57" }}>
            Visit Us
          </h5> */}
              <div className="col-md-6 col-sm-12 ind-add">
                <p className="country mb-2">India</p>
                <div className="row contact-items">
                  <div className="col-2 loc-icon d-flex">
                    <img src={locationIcon} alt="LOC" />
                  </div>
                  <div className="col-10">
                    <p className="address">
                      #304, 3rd Floor, #92, Kedia Arcade, Infantry Road, Bengaluru,
                      Karnataka, India : 560001
                    </p>
                  </div>
                </div>
                <div className="row contact-items pt-2">
                  <div className="col-2 mail-icon">
                    <img src={emailIcon} alt="EML" />
                  </div>
                  <div className="col-10">
                    <a href="Mailto:dalocal@dalocal.co">
                      <p className="email-address">dalocal@dalocal.co</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
          <h6 className="mb-3 mt-2"> Trust starts here</h6>
          <div className="row mb-3 badges">
            <div className="col-3">
              <img src={bdoIcon} alt="BDO" />
            </div>
            <div className="col-3">
              <img src={pdpaIcon} alt="PDA" />
            </div>
            <div className="col-3">
              <img src={gdprIcon} alt="GDPR" />
            </div>
          </div>
          </div>
          <div className="col-6">
          <div className="input-button-wrapper mt-0">
            <h5 className="newsletter mb-3 mt-2">Subscribe for Newsletters</h5>
            <div className="email-wrapper">
              <input className="email-field" placeholder="E-mail" />
              <button href="https://www.youtube.com/@NEOIN-DALOCAL" className="button-4 subscribe" id="banner-btn">
                SUBSCRIBE
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    footerContent = ( // Larger Screens: Laptops and Desktops (> 992px)
    <>
      <br />
      <br />
      <div className="row bottom-nav">
        <div className="col-md-4 col-sm-6 mb-3 ml-0">
          <div className="mb-5" id="logo">
            <a href="/">
              <img
                src={logoImage}
                alt="Company Logo"
                className="logo-image"
              ></img>
            </a>
          </div>
          <p className="mb-3" id="footer-desc">
            A revolution for the communities to connect, communicate and
            engage GLocally on a Single Click.
          </p>
          <br />
          <h6 className="mb-3"> Lets Connect, Communicate & Engage!</h6>
          <div className="row social-icons mb-3">
            <div className="col">
              <a href="/dalocalcommingsoon" target="_blank" rel="noreferrer">
                <img src={dalocalIcon} alt="Social Icon 1" />
              </a>
            </div>
            <div className="col">
              <a href="https://sg.linkedin.com/company/neoinco" target="_blank" rel="noreferrer">
                <img src={linkedinIcon} alt="LinkedIn" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={twitterIcon} alt="Twitter" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={discordIcon} alt="Discord" />
              </a>
            </div>
            <div className="col"></div>
          </div>

          {/* Second row of social icons */}
          <div className="row social-icons">
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={instagramIcon} alt="Instagram" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={facebookIcon} alt="Facebook" />
              </a>
            </div>
            <div className="col">
              <a href="https://www.youtube.com/@NEOIN-DALOCAL" target="_blank" rel="noreferrer">
                <img src={youtubeIcon} alt="YouTube" />
              </a>
            </div>
            <div className="col">
              <a href="/comingsoon" target="_blank" rel="noreferrer">
                <img src={whatsappIcon} alt="WhatsApp" />
              </a>
            </div>
            <div className="col"></div>
          </div>

          {/* <br /> */}
          <br />
          <h6 className="mb-3 mt-2"> Trust starts here</h6>
          <div className="row mb-3 badges">
            <div className="col-3">
              <img src={bdoIcon} alt="BDO" />
            </div>
            <div className="col-3">
              <img src={pdpaIcon} alt="PDA" />
            </div>
            <div className="col-3">
              <img src={gdprIcon} alt="GDPR" />
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-6 mb-3 mt-2 ml-0">
          <h5 className="mb-3">Explore</h5>
          <ul className="list-unstyled footer-list">
            <li>
              <a href="/sitemap">Sitemap</a>{" "}
            </li>
            <li>
              <a href="/dalocalcommingsoon">Discounts</a>{" "}
            </li>
            <li>
              <a href="/partners">Partners</a>{" "}
            </li>
            <li>
              <a href="/comingsoon">Investors</a>{" "}
            </li>
            <li>
              <a href="/testimonials">Testimonials</a>{" "}
            </li>
            <li>
              <a href="/blogs">Blogs</a>{" "}
            </li>
            <li>
              <a href="/faq">FAQs</a>{" "}
            </li>
            <li>
              <a href="/help">Help Center</a>{" "}
            </li>
          </ul>
        </div>
        <div className="col-md-2 col-sm-6 mb-3 mt-2">
          <h5 className="mb-3">Company</h5>
          <ul className="list-unstyled footer-list">
            <li>
              <a href="/about">About Us</a>{" "}
            </li>
            <li>
              <a href="/careers">Careers</a>{" "}
            </li>
            <li>
              {" "}
              <a href="/terms">Terms & Conditions</a>{" "}
            </li>
            <li>
              <a href="/privacy">Privacy Policies</a>{" "}
            </li>
            <li>
              <a href="/cookies">Cookies</a>{" "}
            </li>
          </ul>
          <br />
        </div>
        <div className="col-md-4 col-sm-6 mb-3 mt-2">
          <h5 className="mb-3" id="country-addresses">Visit Us</h5>
          {isSmallLaptop ? ( //For Smaller Laptops (<=1200)
            <div className="row address-and-mail">
            <ul className="list-unstyled">
              <li className="mb-2">
            <div className="col-12 sing-add">
            <p className="country pb-1 mb-2">Singapore</p>
            <div className="row contact-items gutter-sm">
              <div className="col-2 contact-icon d-flex">
                <img src={locationIcon} alt="LOC" />
              </div>
              <div className="col-10">
                <p className="address">
                  #30, Tanah Merah Kechil Road, 09-02, East Meadows, Singapore :
                  465558
                </p>
              </div>
            </div>
            <div className="row contact-items pt-1">
              <div className="col-2 contact-icon mt-0">
                <img src={emailIcon} alt="EML" />
              </div>
              <div className="col-10">
              <a href="Mailto:admin@neoin.co">
                <p className="email-address">admin@neoin.co</p>
              </a>
              </div>
            </div>
            </div>
            </li>
          {/* </div>
           <br /> 
          <div className="col-md-2 col-sm-6 mb-3 mt-2"> */}
            {/* <h5 className="mb-3" style={{ color: "#262b57" }}>
              Visit Us
            </h5> */}
            <li className="mb-2">
            <div className="col-12 ind-add">
            <p className="country pb-1 mb-2">India</p>
            <div className="row contact-items gutter-sm">
              <div className="col-2 contact-icon d-flex">
                <img src={locationIcon} alt="LOC" />
              </div>
              <div className="col-10">
                <p className="address">
                  #304, 3rd Floor, #92, Kedia Arcade, Infantry Road, Bengaluru,
                  Karnataka, India : 560001
                </p>
              </div>
            </div>
            <div className="row contact-items pt-1">
              <div className="col-2 contact-icon mt-0">
                <img src={emailIcon} alt="EML" />
              </div>
              <div className="col-10">
              <a href="Mailto:dalocal@dalocal.co">
                <p className="email-address">dalocal@dalocal.co</p>
                </a>
              </div>
            </div>
            </div>
            </li>
            </ul>
            </div>
          ) : ( // For Larger Laptops (>1200)
            <div className="row address-and-mail">
              <div className="col-md-6 col-sm-12 sing-add">
                <p className="country mb-3">Singapore</p>
                <div className="row contact-items">
                  <div className="col-2 loc-icon d-flex">
                    <img src={locationIcon} alt="LOC" />
                  </div>
                  <div className="col-10">
                    <p className="address mb-2">
                      #30, Tanah Merah Kechil Road, 09-02, East Meadows, Singapore :
                      465558
                    </p>
                  </div>
                </div>
                <div className="row contact-items pt-2">
                  <div className="col-2 mail-icon">
                    <img src={emailIcon} alt="EML" />
                  </div>
                  <div className="col-10">
                    <a href="Mailto:admin@neoin.co">
                      <p className="email-address">admin@neoin.co</p>
                    </a>
                  </div>
                </div>
              </div>
              {/* </div>
         <br /> 
        <div className="col-md-2 col-sm-6 mb-3 mt-2"> */}
              {/* <h5 className="mb-3" style={{ color: "#262b57" }}>
            Visit Us
          </h5> */}
              <div className="col-md-6 col-sm-12 ind-add">
                <p className="country mb-3">India</p>
                <div className="row contact-items">
                  <div className="col-2 loc-icon d-flex">
                    <img src={locationIcon} alt="LOC" />
                  </div>
                  <div className="col-10">
                    <p className="address">
                      #304, 3rd Floor, #92, Kedia Arcade, Infantry Road, Bengaluru,
                      Karnataka, India : 560001
                    </p>
                  </div>
                </div>
                <div className="row contact-items pt-2">
                  <div className="col-2 mail-icon">
                    <img src={emailIcon} alt="EML" />
                  </div>
                  <div className="col-10">
                    <a href="Mailto:dalocal@dalocal.co">
                      <p className="email-address">dalocal@dalocal.co</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>)}
          <div className="input-button-wrapper">
            <h5 className="newsletter mb-3">Subscribe for Newsletters</h5>
            <div className="email-wrapper">
              <input className="email-field" placeholder="E-mail" />
              <button href="https://www.youtube.com/@NEOIN-DALOCAL" className="button-4 subscribe" id="banner-btn">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row email-container">
        <div className="col-md-8"></div>
        <div className="col-md-4 input-button-wrapper">
          <h5 className="newsletter mb-3">Subscribe for Newsletters</h5>
          <div className="email-wrapper">
            <input className="email-field" placeholder="E-mail" />
            <button href="https://www.youtube.com/@NEOIN-DALOCAL" className="button-4 subscribe" id="banner-btn">
              SUBSCRIBE
            </button>
          </div>
        </div>
      </div> */}
      <br />
    </>
  )
  }

  return (
    <footer>
      <div className="container footer-sec">
        {footerContent}
      </div>
      <div className="copyrights">
        <p className="mb-0 mt-0">
          © 2024 by Neoin. Neoin Keeps you safe and secure.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
