import React, { useState,useEffect } from 'react';
import bank_screen1 from '../assets/images/collaboration/onboard.png';
import bank_screen2 from '../assets/images/collaboration/kyc.png';
import bank_screen3 from '../assets/images/collaboration/adAuth.png';
import bank_screen4 from '../assets/images/collaboration/fraud.png';
import bank_screen5 from '../assets/images/collaboration/encTransac.png';
import bank_screen6 from '../assets/images/collaboration/bioVerify.png';
import bank_screen7 from '../assets/images/collaboration/secComm.png';
import bank_screen8 from '../assets/images/collaboration/peace.png';
import OwlCarousel from 'react-owl-carousel';

import main from "../assets/images/collaboration/main.png";
import mobile from "../assets/images/collaboration/mobile.png";
import girl from "../assets/images/collaboration/girl.png";
import onBoardIcon from "../assets/images/banks/icons/Seamless On-Boarding.svg";
import oneClickIcon from "../assets/images/banks/icons/One Click KYC.svg";
import adAuthIcon from "../assets/images/banks/icons/Advanced Authentication.svg";
import fraudPreventionIcon from "../assets/images/banks/icons/Fraud Prevention.svg";
import encrTransacIcon from "../assets/images/banks/icons/Encrypted Transactions.svg";
import bioVerifyIcon from "../assets/images/banks/icons/Biometric Verification.svg";
import secCommIcon from "../assets/images/banks/icons/Secure Communication.svg";
import peaceOfMindIcon from "../assets/images/banks/icons/Peace Of Mind.svg";

import left from "../assets/images/collaboration/left.png";
import right from "../assets/images/collaboration/right.png";

const MobCarousel = ({ image, active }) => (
  <div className={`mobile-item bg_img_bank_carousel ${active ? 'active' : ''}`}>
    <img src={image} alt='' />
  </div>
);

const Contentpart = ({ content, title, contentImg, active }) => (
  <div className={`colaboration-item ${active ? 'active' : ''}`}>
    <div className="colaboration-thumb">
      <div className="icon icon-flex">
        <img src={contentImg} alt={title} className='bank-icon' />
      </div>
    </div>
    <div className="colaboration-content">
      <h4 className="title">{title}</h4>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
);

const Carousel = ({ features, currentSlide, handleCounterClick }) => {

  const handleRightClick = () => {
    handleCounterClick((currentSlide + 1) % features.length);
  };

  const handleLeftClick = () => {
    handleCounterClick((currentSlide - 1 + features.length) % features.length);
  };

  useEffect(() => {
    const intervalId = setInterval(handleRightClick, 1500); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Cleanup function to clear the interval on component unmount
  }, [handleCounterClick]);

  return (
    <>
      <section className="colaboration-section padding-top-2 padding-bottom-2 oh" id="screenshot">
        <div className="container">
          <div className="row align-items-center flex-wrap-reverse">
            <div className="col-lg-6 col-xl-7 rtl">
              <div className="collaboration-anime-area">
                <div className="main-thumb">
                  <img src={main} alt="colaboration" />
                </div>
                <div className="mobile wow slideInUp" data-wow-delay="1s">
                  <div className="show-up">
                    <img src={mobile} alt="colaboration" />
                  </div>

                  <OwlCarousel
                    className="mobile-slider bank-slider ltr"
                    loop
                    margin={30}
                    responsiveClass
                    nav={false}
                    dots={false}
                    autoplayHoverPause
                    items={1}
                    startPosition={currentSlide} // Set the initial slide position
                  >
                    {features.map((feature, index) => (
                      <MobCarousel
                        key={index}
                        image={feature.image}
                        active={index === currentSlide}
                      />
                    ))}
                  </OwlCarousel>
                </div>
                <div className="girl wow slideInLeft">
                  <img src={girl} alt="colaboration" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-5">
              <div className="section-header left-style">
                <h5 className="cate">Banking Made Simple</h5>
                <h2 className="title">Easy & Perfect Solution</h2>
                <p>
                  An array of features to tailor the banking experience to suit your every requirement.
                </p>
              </div>
              <div className="colaboration-wrapper">

                <OwlCarousel
                  className="colaboration-slider"
                  loop
                  margin={30}
                  responsiveClass
                  nav={false}
                  dots={false}
                  autoplayHoverPause
                  items={1}
                  startPosition={currentSlide} // Set the initial slide position
                >
                  {features.map((feature, index) => (
                    <Contentpart
                      key={index}
                      content={feature.description}
                      title={feature.title}
                      contentImg={feature.imageRightSide}
                      active={index === currentSlide}
                    />
                  ))}
                  {/* {content,title,contentImg,active} */}
                </OwlCarousel>

                <div className="cola-nav">
                  <span className="cola-prev mr-4" onClick={handleLeftClick}>
                    <img src={left} alt="colaboration" />
                  </span>
                  <span className="cola-next" onClick={handleRightClick}>
                    <img src={right} alt="colaboration" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const BankPageCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const features = [
    {
      imageRightSide: onBoardIcon,
      title: "Seamless Onboarding",
      description: `Celebrate a smooth transition into Neoin's banks and NBFC services as you embark on a hassle-free and Seamless On-Boarding experience.`,
      image: bank_screen1,
    },
    {
      imageRightSide: oneClickIcon,
      title: "One Click KYC",
      description: `Experience the convenience of instant verification and compliance with Neoin's one-click KYC process, ensuring a hassle-free onboarding journey.`,
      image: bank_screen2,
    },
    {
      imageRightSide: adAuthIcon,
      title: "Advanced Authentication",
      description: `NEOIN's smartwatch app offers advanced authentication methods, ensuring <span className='first-word'>secure access</span> to your accounts with ease.`,
      image: bank_screen3,
    },
    {
      imageRightSide: fraudPreventionIcon,
      title: "Fraud Prevention",
      description: `Benefit from robust <span className='first-word'>fraud prevention measures,</span> protecting your transactions from unauthorized access or fraudulent activities.`,
      image: bank_screen4,
    },
    {
      imageRightSide: encrTransacIcon,
      title: "Encrypted Transactions",
      description: `Enjoy peace of mind with <span className='first-word'>end-to-end encryption for all your transactions,</span> keeping your financial information secure at all times.`,
      image: bank_screen5,
    },
    {
      imageRightSide: bioVerifyIcon,
      title: "Biometric Verification",
      description: `Utilize biometric verification features such as <span className='first-word'>fingerprint or facial recognition</span> for seamless and secure authentication on your smartwatch.`,
      image: bank_screen6,
    },
    {
      imageRightSide: secCommIcon,
      title: "Secure Communication",
      description: `Stay connected securely with NEOIN's smartwatch app, utilizing <span className='first-word'>encrypted communication channels</span> to protect your sensitive informations.`,
      image: bank_screen7,
    },
    {
      imageRightSide: peaceOfMindIcon,
      title: "Peace of Mind",
      description: ` With NEOIN's smartwatch app, experience the convenience of banking on the go without <span className='first-word'>compromising security,</span> providing you with the peace of mind you deserve.`,
      image: bank_screen8,
    },
  ];

  const handleCounterClick = (index) => {
    setCurrentSlide(index);
  };

  return <Carousel features={features} currentSlide={currentSlide} handleCounterClick={handleCounterClick} />;
};

export default BankPageCarousel;
