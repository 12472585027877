import React,{useState} from "react";
import pageheader from "../assets/css/img/page-header.png";
// import pageheader1 from "../assets/images/page-header.png";
import sponsor1 from "../assets/images/icon/shuttle 1.png"
// import usercounter from "../assets/images/bg/user-counter-bg.png";
// import trial2 from "../assets/images/bg/trial2.png";
import Lightbox from './LightBox'
import { useScrollToTop } from "./UseScrollToTop";

function Help() {

    useScrollToTop();


    const [isOpen, setIsOpen] = useState(false);
    const [lightboxTitle, setlightboxTitle] = useState("");
    const [activeComponent, setActiveComponent] = useState(false);
  
    const openLightbox = (htmlOrElement) => {
      setIsOpen(true);
      // setContent(htmlOrElement);
      setlightboxTitle(htmlOrElement);
      setActiveComponent(true);
    };

    return (

        <>

            <section className="page-header bg_img_pg_header">
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageheader} alt="css" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Help Center</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                Help Center
                            </li>
                        </ul>
                    </div>
                </div>
            </section>



            {/* <!--============= Partner Section Starts Here =============--> */}
            <section className="partner-section padding-top-2 padding-bottom-2 oh">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-9">
                            <div className="section-header mw-100">
                                <h2 className="title">How can we help you?</h2>
                                <div className="email-wrapper">
                                    <input className="email-field" placeholder="Search Help Article" />

                                </div>
                            </div>
                        </div>
                    </div>

                    {activeComponent && (
                                    <Lightbox
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    lightboxTitle={lightboxTitle}
                                    setlightboxTitle={setlightboxTitle}
                                    />
                )}
                    <div className="container">
                        <div className="row justify-content-center mb-30-none">
                            <div className="col-12 col-sm-6 col-md-4" onClick={() => openLightbox("Digital Business Card")}>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={sponsor1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Digital Business Cards</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4" onClick={() => openLightbox("Email Signature")}>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={sponsor1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Email Signatures</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4" onClick={() => openLightbox("Virtual")}>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={sponsor1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Virtual Backgrounds</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4" >
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={sponsor1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Tutorials & Guide</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4" onClick={() => openLightbox("Feature")}>
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={sponsor1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Additional Features</h5>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-sm-6 col-md-4">
                                <div className="am-item">
                                    <div className="am-thumb">
                                        <img src={sponsor1} alt="feature" />
                                    </div>
                                    <div className="am-content">
                                        <h5 className="title">Free Trial</h5>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <h2 className="section-header mt-70 title">Can't find what are you looking for?</h2>
                  
                    </div>
                    <div className="banner-content-9">
                    <div className="banner-button-group">
                        <a href="/contact" className="button-4" id="banner-btn-flex">
                            Get Help
                        </a>
                    </div>
                    </div>
                  

                </div>
            </section>
           





 

        </>

    )

}

export default Help