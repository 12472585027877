import React from 'react'
// import account from "../assets/images/account-bg.jpg"
import logo from "../assets/images/Neoin Logo.svg" 
import googleicon from "../assets/images/icon/google.png"
import sponser1 from "../assets/images/banner/banner16/01.png"
import sponser2 from "../assets/images/banner/banner16/02.png"
import sponser3 from "../assets/images/banner/banner16/03.png"
import sponser4 from "../assets/images/banner/banner16/04.png"
import sponser5 from "../assets/images/banner/banner16/05.png"
import sponser6 from "../assets/images/banner/banner16/06.png"
import sponser7 from "../assets/images/banner/banner16/04.png"

function Login() {
  return (
    <div>
        {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}

    {/*============= Sign In Section Starts Here =============*/}
    <div className="account-section bg_img_account">
        <div className="container">
            <div className="account-title text-center">
                <a href="/" className="back-home"><i className="fas fa-angle-left"></i><span>Back <span className="d-none d-sm-inline-block">To NEOIN</span></span></a>
                <a href="/" className="logo">
                    <img src={logo}alt="logo"/>
                </a>
            </div>
            <div className="account-wrapper">
                <div className="account-header">
                    <h4 className="title">Let's get started</h4>
                    <a href="#0" className="sign-in-with"><img src={googleicon} alt="icon"/><span>Sign Up with Google</span></a>
                </div>
                <div className="or">
                    <span>OR</span>
                </div>
                <div className="account-body">
                    <span className="d-block mb-20">Sign up with your work email</span>
                    <form className="account-form">
                        <div className="form-group">
                            <label for="sign-up">Your Email </label>
                            <input type="text" placeholder="Enter Your Email " id="sign-up"/>
                        </div>
                        <div className="form-group text-center">
                            <button type="submit">Try It Now</button>
                            <span className="d-block mt-15">Already have an account? <a href="sign-in.html">Sign In</a></span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="sponsor-slider-wrapper cl-white text-center mt-40">
                <h5 className="slider-heading mb-3">Used by over 1,000,000 people worldwide</h5>
                <div className="sponsor-slider-4 owl-theme owl-carousel">
                    <div className="sponsor-thumb">
                        <img src={sponser1} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser2} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser3} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser4} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser5} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser6} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser7} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser1} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser2} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser3} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser4} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser5} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser6} alt="sponsor"/>
                    </div>
                    <div className="sponsor-thumb">
                        <img src={sponser7} alt="sponsor"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/*============= Sign In Section Ends Here =============*/}
      
    </div>
  )
}

export default Login
