import React from 'react'
// import bgimg from "../assets/images/404/404-bg.jpg"
import man01 from "../assets/images/404/man_01.png"
import man02 from "../assets/images/404/man_02.png"

function Page404() {    
  return (
    <div>
      {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
    {/*============= Error In Section Starts Here =============*/}
    <div className="error-section bg_img_404">
        <div className="container">
            <div className="man1">
                <img src={man01} alt="404" className="wow bounceInUp" data-wow-duration=".5s" data-wow-delay=".5s"/>
            </div>
            <div className="man2">
                <img src={man02} alt="404" className="wow bounceInUp" data-wow-duration=".5s"/>
            </div>
            <div className="error-wrapper wow bounceInDown" data-wow-duration=".7s" data-wow-delay="1s">
                <h1 className="title">404</h1>
                <h3 className="subtitle">Page Not Found</h3>
                <a href="/" className="button-3">Go Back</a>
            </div>
        </div>
    </div>
    {/*============= Error In Section Ends Here =============*/}
    </div>
  )
}

export default Page404
