import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

function MapIntegration({ selectedCountry }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 12.979871024899435, lng:  77.60654611960474 });

  useEffect(() => {
    if (selectedCountry === 'Singapore') {
      setCenter({ lat: 1.3288677879191721, lng: 103.94453319299872 });
    } else if (selectedCountry === 'India') {
      setCenter({ lat: 12.979871024899435, lng: 77.60654611960474 });
    }
    // Add more countries as needed
  }, [selectedCountry]);

  const onLoad = (map) => {
    setMap(map);
  };

  const onUnmount = () => {
    setMap(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : <></>;
}

export default MapIntegration;
