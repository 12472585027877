import React from 'react'
// import accountimg from "../assets/images/account-bg.jpg"
import logo from "../assets/images/Neoin Logo.svg"

function PasswordChange() {
  return (
    <div>
         {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
    {/*============= Sign In Section Starts Here =============*/}
    <div className="account-section bg_img_account">
        <div className="container">
            <div className="account-title text-center mb-120">
                <a href="index.html" className="back-home"><i className="fas fa-angle-left"></i><span>Back <span className="d-none d-sm-inline-block">To NEOIN</span></span></a>
                <a href="#0" className="logo">
                    <img src={logo} alt="logo"/>
                </a>
            </div>
            <div className="account-wrapper top-gap">
                <div className="account-body">
                    <div className="form-head">
                        <h4 className="title mb-20">Change Password</h4>
                        <p>You can change your password for security reasons or reset it if you forget it.</p>
                    </div>
                    <form className="account-form">
                        <div className="form-group">
                            <label for="new-pass">New Password</label>
                            <input type="password" placeholder="Enter New Password" id="new-pass"/>
                        </div>
                        <div className="form-group">
                            <label for="confirm-pass">Confirm Password</label>
                            <input type="password" placeholder="Confirm Password" id="confirm-pass"/>
                        </div>
                        <div className="form-group text-center">
                            <button type="submit" className="mt-40 mb-2">Change Password</button>
                        </div>
                    </form>
                    <span className="d-block mt-15">Remember your password? <a href="sign-in.html"> Log In</a></span>
                </div>
            </div>
        </div>
    </div>
    {/*============= Sign In Section Ends Here =============*/}
      
    </div>
  )
}

export default PasswordChange
