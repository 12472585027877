import React from 'react'
import pageheader1 from "../assets/css/img/page-header.png";
import privacy from "../assets/images/bg/privacy-header.png";
import calendar from '../assets/images/icon/Calendar=24px.svg'
import { useScrollToTop } from "./UseScrollToTop";

function Cookies() {

    useScrollToTop();
    return (
            <>
     <section className="page-header bg_img_pg_header oh">
        <div className="bottom-shape d-none d-md-block">
            <img src={pageheader1} alt="css" />
        </div>
        <div className="page-left-thumb">
            <img src={privacy} alt="bg" />
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="title">Cookies</h2>
                <ul className="breadcrumb">
                    <li>
                        <a href="/">Home</a>
                    </li>
                   
                    <li>
                        Cookies
                    </li>
                </ul>
            </div>
        </div>
    </section>


    <section className="privacy-section padding-top padding-bottom">
<div className="container">
    <div className="row justify-content-center">
        <div className="col-lg-8">
            <div className="section-header">
                <h2 className="title">Cookies</h2>
                {/* <p>Please read these Terms and Conditions before accessing this website.</p> */}
            </div>
        </div>
    </div>
    <div className="row justify-content-between">
        {/* <div className="col-lg-4">
            <aside className="sticky-menu">
                <div className="faq-menu bg_img" data-background={faq}>
                    <ul id="faq-menu">
                        <li className="nav-item">
                            <a className="nav-link" href="#general">General</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#prop">Intellectual Property</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#secu">Security and storage</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#info">Usage Information</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#cooki">Cookies</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#ipaddr">IP Address</a>
                        </li>
                    </ul>
                </div>
            </aside>
        </div> */}
        <div className="col-lg-8 col-xl-12">
            <article className="mt-70 mt-lg-0">

                <div className="privacy-item">
                    <p><img src={calendar} alt="css"/>    Last updated: March 22, 2024</p>
                    <p>This site uses cookies for registered users only. By continuing to use, you are agreeing to our use of cookies. Please read our <a href='terms'> Terms & Conditions</a> and <a href='/privacy' >Privacy Policy</a> for more information.</p>
                    <h3 className="title">What are Cookies?</h3>
                    <p>Cookies are small text files that are stored on your device when you visit a website. They help us remember your preferences and settings, improve the performance of our website, and provide a personalized browsing experience.</p>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                        euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                </div>

                <div className="privacy-item" id="accept">
                    <h3 className="title">How We Use Cookies</h3>
                    <p>We use cookies for various purposes, including:</p>
                    <ul>
                        <li className='dotslist'>Analyzing website traffic and usage patterns</li>
                        <li className='dotslist'>Personalizing content and advertisements</li>
                        <li className='dotslist'>Remembering your preferences and settings</li>
                        <li className='dotslist'>Providing social media features</li>
                    </ul>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                        euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                </div>
                <div className="privacy-item" id="users">
                    <h3 className="title">Types of Cookies We Use</h3>
                    <ul>
                    <li className='dotslist'>Essential Cookies: Necessary for the operation of our website and essential for you to navigate and use its features.</li>
                    <li className='dotslist'>Analytical/Performance Cookies: Used to analyze how users interact with our website 
and improve its performance.</li>
                    <li className='dotslist'>Functionality Cookies: Remember your preferences and settings to enhance your browsing experience.</li>
                    <li className='dotslist'>Targeting/Advertising Cookies: Used to deliver advertisements relevant to your interests based on your browsing activities.</li>
                    </ul>
                </div>

                <div className="privacy-item" id="data">
                    <h3 className="title">Managing Cookies</h3>
                    <p>You can control and manage cookies through your browser settings. However, please note that disabling cookies may affect the functionality of certain features on our website.</p>
                </div>

                <div className="privacy-item" id="data">
                    <h3 className="title">Third-Party Cookies</h3>
                    <p>We may also use third-party cookies provided by trusted partners for analytics, advertising, and social media integration. These cookies are subject to the privacy policies of the respective third parties.</p>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                        euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                </div>
                <div className="privacy-item" id="ai">
                    <h3 className="title">Updates to Cookies Policy</h3>
                    <p>We reserve the right to update or change this Cookies Policy at any time. Any changes will be posted on this page, with the date of last revision indicated at the top.</p>
                    <p>If you have any questions or concerns about our use of cookies, please contact us at <a href="mailto:admin@neoin.co">admin@neoin.co</a>.</p>
                </div>
            
            </article>
        </div>
    </div>
</div>
</section>
    
    
    </>


)
  
}

export default Cookies;
