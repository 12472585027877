import React, { useState, useEffect } from "react";
import '../assets/css/Carousel.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import arrow icons

import testingImage from "../assets/images/screenshot/Group 13.png";
// import cardImage from "../assets/images/screenshot/Group 5.png";
import cardImage1 from "../assets/images/screenshot/Group 15.png";
import cardImage2 from "../assets/images/screenshot/Group 16.png";
import cardImage3 from "../assets/images/screenshot/Group 2.png";

const Carousel = () => {  
  const images = [
    testingImage,
    cardImage1,
    cardImage2,
    cardImage3,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextImage, 3000); // Change image every 3 seconds

    return () => clearInterval(intervalId); // Cleanup function to clear the interval on component unmount
  }, [currentIndex]); // Trigger effect whenever currentIndex changes

  return (
    <div className="image-carousel">
      <FaArrowLeft className="arrow left" onClick={prevImage} />
      <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
      <FaArrowRight className="arrow right" onClick={nextImage} />
    </div>
  );
};

export default Carousel;

