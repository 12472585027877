import React from 'react'

import banner_shape_5 from "../assets/images/banner/banner-shape-5.png"
import banner_5 from "../assets/images/sme/Main Banner (1).png"
import cf1 from "../assets/images/feature/cf1.png"
// import cf2 from "../assets/images/feature/cf2.png"
// import cf3 from "../assets/images/feature/cf3.png"
// import cf4 from "../assets/images/feature/cf4.png"
// import cf5 from "../assets/images/feature/cf5.png"
import tenantOnboardingIcon from '../assets/images/sme/onboarding_icon.svg'
import CceIcon from '../assets/images/sme/CCE_icon.svg'
import DashboardIcon from '../assets/images/sme/dashboard_icon.svg'
import DigiMarketingIcon from '../assets/images/sme/digital_marketing_icon.svg'
import SkuDiscountIcon from '../assets/images/sme/SKUS-discount_icon.svg'

import growthCatalyst from "../assets/images/sme/Growth Catalyst.png";
import CCE from "../assets/images/sme/CCE.png";
import Dashboard from "../assets/images/sme/Dashboard & Insights.png";
import DigMarketing from "../assets/images/sme/Digital Marketing.png";
import SKU from "../assets/images/sme/SKUs And Discount.png";

import CCECollab from "../assets/images/sme/cce-collab.png";
import reliable_top from "../assets/css/img/reliable-top.png"
import reliable_bottom from "../assets/css/img/reliable-bottom.png"
import reliable_video from "../assets/images/sme/See how Neoin can help_video_bg.jpg"
// import data1 from "../assets/images/data/data1.png"

// import clientleft from "../assets/images/client/left.png"
// import clientright from "../assets/images/client/right.png"
// import client1 from "../assets/images/dummies/D6.png"
// import client2 from "../assets/images/dummies/D2.png"
// import cate from "../assets/images/cate.png"
import balls from "../assets/images/balls/balls.png"

import advance4 from "../assets/images/sme/Effortless Onboarding Journey.png"
// import advance5 from "../assets/images/feature/advance5.png"
import advance6 from"../assets/images/sme/Local Impact.png"

// import reliable_bg_data from "../assets/images/bg/reliable-bg.jpg"

//pricing
// import top_shape from "../assets/css/img/top-shape-dark.png"
// import bottom_shape from"../assets/css/img/bottom-shape.png"

// import balls1 from "../assets/images/balls/1.png"
// import balls2 from "../assets/images/balls/2.png"
// import balls3 from "../assets/images/balls/3.png"
// import balls4 from "../assets/images/balls/4.png"
// import balls5 from "../assets/images/balls/5.png"
// import balls6 from "../assets/images/balls/6.png"
// import pricing5 from "../assets/images/pricing/pricing5.png"
// import pricing6 from "../assets/images/pricing/pricing6.png"
// import pricing7 from "../assets/images/pricing/pricing7.png"
//import bannershape9 from "../assets/css/img/banner-9-shape-without-shadow.png"
// import pricingbg from "../assets/images/bg/pricing-bg.jpg"
import Counter from './Counter'
import Download from './Download'
// import Review from './Review'

function SmePricing() {
    return (
      <>
        { /* <!-- ============= ScrollToTop Section Starts Here ============= --> */ }
    {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
    <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
    <div className="overlay"></div>
    { /* <!--============= ScrollToTop Section Ends Here =============--> */ }

    { /* <!--============= Banner Section Starts Here =============--> */ }
    <section className="banner-5 oh smebanner">
        <div className="banner-shape-5 d-lg-block d-none">
            <img src={banner_shape_5} alt="banner" />
        </div>
        <div className="container">
            <div className="row align-items-top">
                <div className="col-lg-6">
                    <div className="banner-content-5 mb-20">
                        <h1 className="title">A Unique Saas Platform for SME’s Digital Ecosystem</h1>
                        <p>
                        From small firms to large enterprises across any Industry.
                        </p>
                        {/* <form className="banner-search-form">
                            <input type="text" placeholder="Enter your e-mail" />
                            <button type="submit">Get Neoin</button>
                        </form> */}
                        {/* <p>2836 teams moved to Neoin this week</p> */}
                        <div className="banner-button-group justify-content-start">
                            <a href="/comingsoon" className="button-4">Create iD</a>
                                    {/* <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button popup">
                                        <i className="flaticon-play"></i>
                                    </a> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                    <div className="banner-thumb-5 sme-banner-img">
                       <img src={banner_5} alt="banner" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Banner Section Ends Here =============--> */ }


    { /* <!--============= Convence Feature Section Starts Here =============--> */ }
    <section className="convence-feature padding-bottom-2 pb-xl-0">
        <div className="lg-oh padding-top pt-lg-half">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9">
                        <div className="section-header mw-100">
                            <h5 className="cate">SME-focused unique features</h5>
                            <h2 className="title NewSytleh2">NEOIN makes communication 
                                & collaboration effortless</h2>
                            {/* <p></p> */}
                        </div>
                    </div>
                </div>
                <ul className="nav nav-tabs feature-tabs">
                    <li>
                        <a data-toggle="tab" href="#conversation" className='active'>
                            <div className="thumb">
                                <img src={tenantOnboardingIcon} alt='Tenant Onboarding' className='sme-icon' />
                            </div>
                            <span className="d-block subtitle">Tenant Onboarding</span>
                        </a>
                    </li>
                    <li>
                        <a data-toggle="tab" href="#vcall">
                            <div className="thumb">
                                <img src={CceIcon} alt="C.C.E" className='sme-icon' />
                            </div>
                            <span className="d-block subtitle">C.C.E</span>
                        </a>
                    </li>
                    <li>
                        <a data-toggle="tab" href="#psearch">
                            <div className="thumb">
                                <img src={DashboardIcon} alt="Dashboards and Insights" className='sme-icon' />
                            </div>
                            <span className="d-block subtitle">Dashboard & Insights</span>
                        </a>
                    </li>
                    <li>
                        <a data-toggle="tab" href="#ptools">
                            <div className="thumb">
                                <img src={DigiMarketingIcon} alt='Digital Marketing' className='sme-icon' />
                            </div>
                            <span className="d-block subtitle">Digital Marketing</span>
                        </a>
                    </li>
                    <li>
                        <a data-toggle="tab" href="#aintergrate">
                            <div className="thumb">
                                <img src={SkuDiscountIcon} alt='SKUs and Discounts' className='sme-icon' />
                            </div>
                            <span className="d-block subtitle">SKUs and Discount</span>
                        </a>
                    </li>
                </ul>
                <div className="tab-content">

                    <div className="tab-pane fade show active" id="conversation">
                        <div className="row align-items-center flex-wrap-reverse">
                            <div className="col-lg-5 col-md-6">
                                <div className="convencing-feature-content">
                                    <h3 className="title text-nowrap">Streamlined Onboard,<br />Amplified Success</h3>
                                    <p>Our SME onboarding process offers <span className='first-word'> personalized support </span> and <span className='first-word'> streamlined procedures</span>, ensuring a seamless transition and accelerated integration into our community.</p>
                                    {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7">
                                <div className="convencing-feature-thumb">
                                    <img src={cf1} alt="feature" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade show" id="vcall">
                        <div className="row align-items-center flex-wrap-reverse">
                            <div className="col-lg-5 col-md-6">
                                <div className="convencing-feature-content">
                                    <h3 className="title"> Building Bridges, Creating Bonds.</h3>
                                    <p>Establishing <span className='first-word'>connections</span>, effective <span className='first-word'> communication</span>, and fostering <span className='first-word'>engagement</span> with stakeholders to build relationships, drive interaction, and achieve desired outcomes.</p>
                                    {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7">
                                <div className="convencing-feature-thumb d-flex justify-content-center">
                                    <img src={CCE} alt="feature" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade show" id="psearch">
                        <div className="row align-items-center flex-wrap-reverse">
                            <div className="col-lg-5 col-md-6">
                                <div className="convencing-feature-content">
                                    <h3 className="title">Real-Time Insights, Effortless Tracking</h3>
                                    <p>Access real-time data and metrics with our intuitive dashboard, empowering you to make <span className='first-word'> informed decisions </span> and <span className='first-word'> track key performance </span> indicators effortlessly.</p>
                                    {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7">
                                <div className="convencing-feature-thumb d-flex justify-content-center">
                                    <img src={Dashboard} alt="feature" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="ptools">
                        <div className="row align-items-center flex-wrap-reverse">
                            <div className="col-lg-5 col-md-6">
                                <div className="convencing-feature-content">
                                    <h3 className="title">Digital Power & Global Reach</h3>
                                    <p>Digital marketing leverages digital tools and strategies to connect with <span className='first-word'> audiences, drive brand awareness, generate leads,</span> and <span className='first-word'> achieve business objectives </span> in a digital-driven world.</p>
                                    {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7">
                                <div className="convencing-feature-thumb d-flex justify-content-center">
                                    <img src={DigMarketing} alt="feature" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="aintergrate">
                        <div className="row align-items-center justify-content-between flex-wrap-reverse">
                            <div className="col-lg-5 col-md-6">
                                <div className="convencing-feature-content">
                                    <h3 className="title">Smart SKUs and Strategic Discounts on Dalocal Social Commerce</h3>
                                    <p>Optimize inventory management with our SKU solutions, while maximizing <span className='first-word'> profitability through strategic discount offerings </span> tailored for Dalocal Ecosystem.</p>
                                    {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-7">
                                <div className="convencing-feature-thumb d-flex justify-content-center">
                                    <img src={SKU} alt="feature" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container container-height">
            <div className="sme-counter-wrapper-area-3">
            <div className="sme-counter-wrapper-3">
              <div className="sme-counter-item-3">
                <h2 className="title">
                <Counter end={8}>k</Counter>
                </h2>
                <span className="name">Users</span>
              </div>
              <div className="sme-counter-item-3">
                <h2 className="title">
                <Counter end={10}>+</Counter>
                </h2>
                <span className="name">Countries</span>
              </div>
              <div className="sme-counter-item-3">
                <h2 className="title">
                <Counter end={5}>+</Counter>
                </h2>
                <span className="name">Integrations</span>
              </div>
              <div className="sme-counter-item-3">
                <h2 className="title">
                <Counter end={95}>%</Counter>
                </h2>
                <span className="name">Satisfaction</span>
              </div>
            </div>
            </div>
        </div>
            
    </section>
    { /* <!--============= Convence Feature Section Ends Here =============--> */ }


    { /* <!--============= Realiable Section Starts Here =============--> */ }
    <section className="reliable-section padding-bottom padding-top bg_img_reliable pos-rel oh pb-xl-0 mt-120 mt-max-xl-0" id="how">
        <div className="reliable-top d-xl-block d-none">
            <img src={reliable_top} alt="css" />
        </div>
        <div className="reliable-bottom d-xl-block d-none">
            <img src={reliable_bottom} alt="css" />
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-9 col-xl-8">
                    <div className="section-header mw-100 white-xl-black">
                        <h5 className="cate">Insightful Dashboard Solutions</h5>
                        <h2 className="title">See how Neoin can help</h2>
                        <p>Organize all your conversations, productivity tools, and apps in one place. Because it’s not just the message, it’s how you work together that matters.</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="reliable-video">
                        <img src={reliable_video} alt="bg" />
                        <a href="https://www.youtube.com/watch?v=4o7wTELTpBI" className="video-button-2 popup">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <i className="flaticon-play"></i>              
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Realiable Section Ends Here =============--> */ }


    { /* <!--============= Real Time Section Starts Here =============--> */ }
    <section className="realtime-section padding-top oh">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 rtl growth-catalyst d-lg-block">
                    <div className="realtime-thumb tab-pb-40 mob-pb-40">
                        <img src={growthCatalyst} alt="data" className='fit tab-w-auto' />
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="realtime-content">
                        <div className="section-header left-style mb-olpo">
                            <h5 className="cate">Growth Catalyst for SMEs</h5>
                            <h2 className="title">Analytics To Optimize Your Business Channel</h2>
                            <p> <span className='first-word'>Data-driven tactics </span> empower SMEs to target, engage, and retain customers effectively, optimising every stage of the business funnel for growth and success.</p>
                        </div>
                        <ul className="bal-list">
                            <li>Actionable Analytics</li>
                            <li>Real-time Updates</li>
                            <li>Monitor Engagement</li>
                            <li>Vendor and Supplier</li>
                        </ul>
                        {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Real Time Section Ends Here =============--> */ }
    
    { /* <!--============= Advance Feature Section Starts Here =============--> */ }
    <section className="advance-feature-section padding-top-2 padding-bottom-2 oh">
        <div className="container">
            <div className="advance-feature-item style-two padding-bottom-2 flex-row-reverse">
                <div className="advance-feature-thumb effortless-onboarding text-center">
                    <img src={advance4} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Effortless Onboarding Journey</h5>
                        <h2 className="title">Enhancing Tenant Onboarding with Verified iD Platform</h2>
                        <p >A platform that verifies iDs for all tenants is key for <span className='first-word'> building trust, keeping things secure, </span> and <span className='first-word'> making tenant management </span> a breeze in a digital ecosystem.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item style-two padding-top-2 padding-bottom-2 flex-row">
                <div className="advance-feature-thumb text-center">
                    <img src={CCECollab} alt="feature" className='w-100'/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Connect. Communicate. Engage</h5>
                        <h2 className="title">Collaboration with Internal and External Touchpoints</h2>
                        <p>Establish robust communication channels to facilitate <span className='first-word'> cohesive interaction among stakeholders, fostering SME expansion </span> and<span className='first-word'> active participation </span> across various internal and external interfaces.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item style-two padding-top-2 padding-bottom-2 flex-row-reverse mob-pb-0">
                <div className="advance-feature-thumb text-center">
                    <img src={advance6} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Local Impact, Extended Reach</h5>
                        <h2 className="title">Inbuilt Digital Marketing, Discounts & Loyalty Module</h2>
                        <p >Utilize <span className='first-word'> hyper-local digital marketing within a 2km radius to engage nearby audiences </span> effectively, fostering community connections and driving growth for SMEs.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            {/* <div className="advance-feature-item style-two padding-top-2 padding-bottom-2">
                <div className="advance-feature-thumb">
                    <img src={advance5} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        <h5 className="cate">Powerful and Flexible</h5>
                        <h2 className="title">Collaboration for team work</h2>
                        <p>The simple, intuitive user interface is designed to help you see exactly what you need to focus on.As a team member you can focus on your work and as a team leader, you can easily manage your team.</p>
                    </div>
                    <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a>
                </div>
            </div> */}
        </div>
    </section>
    { /* <!--============= Advance Feature Section Ends Here =============--> */ }

    { /* <!--============= Testimonial Section Starts Here =============--> */ }
    {/* <Review /> */}
    {/* <section className="testimonial-section padding-bottom-2">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">Testimonials</h5>
                <h2 className="title">5000+ happy clients all around the world</h2>
            </div>
            <div className="testimonial-wrapper">
                <a href="#0" className="testi-next trigger">
                    <img src={clientleft} alt="client" />
                </a>
                <a href="#0" className="testi-prev trigger">
                    <img src={clientright} alt="client" />
                </a>
                <div className="testimonial-area testimonial-slider owl-carousel owl-theme">
                    <div className="testimonial-item">
                        <div className="testimonial-thumb">
                            <div className="thumb">
                                <img src={client1} alt="client" />
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>
                                Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                            </p>
                            <h5 className="title"><a href="#0">Bela Bose</a></h5>
                        </div>
                    </div>
                    <div className="testimonial-item">
                        <div className="testimonial-thumb">
                            <div className="thumb">
                                <img src={client2} alt="client" />
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>
                                Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                            </p>
                            <h5 className="title"><a href="#0">Raihan Rafuj</a></h5>
                        </div>
                    </div>
                </div>
                <div className="button-area">
                    <a href="#0" className="button-2">Leave a review <i className="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Testimonial Section Ends Here =============--> */ }


    { /* <!--============= Faq Section Starts Here =============--> */ }
    {/* <section className="faq-section padding-top">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="faq-header">
                        <div className="cate">
                            <img src={cate} alt="cate" />
                        </div>
                        <h2 className="title">Frequently Asked Questions</h2>
                        <a href="#0">More Questions <i className="flaticon-right"></i></a>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="faq-wrapper mb--38">
                        <div className="faq-item">
                            <div className="faq-thumb">
                                <i className="flaticon-pdf"></i>
                            </div>
                            <div className="faq-content">
                                <h4 className="title">Is the Web App Secure?</h4>
                                <p>
                                    Web application security is the process of protecting websites and online services against different security threats that exploit vulnerabilities in an application’s code.
                                </p>
                            </div>
                        </div>
                        <div className="faq-item">
                            <div className="faq-thumb">
                                <i className="flaticon-pdf"></i>
                            </div>
                            <div className="faq-content">
                                <h4 className="title">What features does the Web App have?</h4>
                                <p>
                                    Both the Mobile Apps and the Web App give you the ability to you to access your account information, view news releases, report an outage, and contact us via email or phone.
                                </p>
                            </div>
                        </div>
                        <div className="faq-item">
                            <div className="faq-thumb">
                                <i className="flaticon-pdf"></i>
                            </div>
                            <div className="faq-content">
                                <h4 className="title">How do I get the Mobile App for my phone?</h4>
                                <p>
                                    Both the Mobile Apps and the Web App give you the ability to you to access your account information, view news releases, report an outage, and contact us via email or phone.
                                </p>
                            </div>
                        </div>
                        <div className="faq-item">
                            <div className="faq-thumb">
                                <i className="flaticon-pdf"></i>
                            </div>
                            <div className="faq-content">
                                <h4 className="title">How does Neoin differ from usual apps? </h4>
                                <p>
                                    Both the Mobile Apps and the Web App give you the ability to you to access your account information, view news releases, report an outage, and contact us via email or phone.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Faq Section Ends Here =============--> */ }


    { /* <!--============= Trial Section Starts Here =============--> */ }
    <section className="trial-section padding-bottom-2 padding-top-2 mob-pt-0">
        <div className="container">
            <div className="trial-wrapper padding-top padding-bottom pr">
                <div className="ball-1">
                    <img src={balls} alt="balls" />
                </div>
                <div className="trial-content cl-white">
                    <h3 className="title">Customize your plan!</h3>
                    <p>
                        Get connected with our <br/> Team Sales for more information.
                    </p>
                </div>
                <div className="trial-button">
                    <a href="/contact" className="transparent-button">Contact Us <i className="flaticon-right ml-xl-2"></i></a>
                </div>
            </div>
        </div>
    </section>
    { /* <!--============= Trial Section Ends Here =============--> */ }
    
    { /* <!--============= Pricing Section Ends Here =============--> */ }
    {/* <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img_pricing pos-rel" id="pricing">
        <div className="top-shape d-none d-md-block">
            <img src={top_shape} alt="css" />
        </div>
        <div className="bottom-shape d-none d-md-block">
            <img src={bottom_shape} alt="css" />
        </div>
        <div className="ball-2" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls1} alt="balls" />
        </div>
        <div className="ball-3" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls2} alt="balls" />
        </div>
        <div className="ball-4" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls3} alt="balls" />
        </div>
        <div className="ball-5" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src={balls4} alt="balls" />
        </div>
        <div className="ball-6" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls5} alt="balls" />
        </div>
        <div className="ball-7" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src={balls6} alt="balls" />
        </div>
        <div className="container">
            <div className="section-header pb-30 cl-white">
                <h5 className="cate">Choose a plan that's right for you</h5>
                <h2 className="title">Simple Pricing Plans</h2>
                <p>
                Empowering Success, One Price at a Time, connect with NEOIN SaaS.
                </p>
            </div>
            <div className="row justify-content-center mb-30-none">
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">basic</span>
                        <div className="pricing-thumb">
                            <img src={pricing5} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>30.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">standard</span>
                        <div className="pricing-thumb">
                            <img src={pricing6} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>50.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block active">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">premium</span>
                        <div className="pricing-thumb">
                            <img src={pricing7} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>90.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
   {/* <!--============= Pricing Section Ends Here =============--> */}

   {/* <div className="text-center mt-70">
                        <p>Want a customized solution to meet your specific needs?</p>
                        <a href="#0" className="show-feature" style={{ color: '#00E9E7' }}>Get in touch with us.</a>


    </div> */}


   <Download />

   
      </>
    );
  }
  
  export default SmePricing;