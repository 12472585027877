import React from 'react'
import pageheader1 from "../assets/css/img/page-header.png"

import { useScrollToTop } from "./UseScrollToTop";

function Sitemap() {
useScrollToTop();
  return (
    <div>

      {/*-============= Header Section Ends Here =============*/}
    <section className="page-header bg_img_pg_header">
        <div className="bottom-shape d-none d-md-block">
            <img src={pageheader1} alt='page Header'/>
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="title">Sitemap</h2>
                <ul className="breadcrumb">
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        Sitemap
                    </li>
                </ul>
            </div>
        </div>
    </section>
    {/*============= Header Section Ends Here =============*/}


<section className='padding-top-2 padding-bottom-2 oh site-content'>
  <h2 className='color-text'>Pages</h2>
  <div className="container text-start">
    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4">
      <div className="col"><a href='/'>Home</a></div>
      <div className="col"><a href='/about'>About Us</a></div>
      <div className="col"><a href='/pricing'>Pricing</a></div>
      <div className="col"><a href='/contact'>Contact Us</a></div>
    </div>
  </div>

  {/* <h2 className='color-text mt-3'>Neoin</h2> */}
  {/* <div className="container text-start">
    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4">
      <div className="col"><a href='#'>Platform</a></div>
      <div className="col"><a href='/solution'>Solutions</a></div>
      <div className="col"><a href='/feature'>Features</a></div>
    </div>
  </div> */}

  <h2 className='color-text mt-3'>Platform Categories</h2>
  <div className="container">
  <div className="row">
    <div className="col-md-4">
      <ul>
        <div>Enterprise Saas</div>
        <li className='sub-titles'>
          <ul>
            <li><a href='/sme'>SME</a></li>
            <li><a href='/bank'>Banks & NBFC</a></li>
            <li><a href='/associations'>Associations</a></li>
            <li><a href='/professionals'>Professionals</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <div>Consumer Saas</div>
        <li className='sub-titles'>
          <ul>
            <li><a href='/individuals'>Individuals</a></li>
            <li><a href='/students'>Students</a></li>
            <li><a href='/genz'>Gen Z</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="col-md-4">
      <ul>
        <div>Ecosystem</div>
        <li className='sub-titles'>
          <ul>
            <li><a href='/dalocalcommingsoon'>DaLocal</a></li>
            <li><a href='/dalocalcommingsoon'>Discount</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>


  <h2 className='color-text mt-3'>Solutions Categories</h2>
  <div className="container">
  <div className="row">
    <div className="col-sm">
      <ul>
        <div><a href='/digital-card'>Digital verified iD</a></div>
        <li className='sub-titles'>
          <ul>
          {/* '/feature?scrollTo=feature-section123' */}
          <li><a href='/digital-card?scrollTo=businesscard'>Digital Business Card</a></li>
          <li><a href='/digital-card?scrollTo=digisign'>Digital Signature</a></li>
          <li><a href='/digital-card?scrollTo=accessmanage'>Access Management</a></li>
          <li><a href='/digital-card?scrollTo=digiconsent'>Digital Consent</a></li>
          <li><a href='/digital-card?scrollTo=clientmanage'>Client Management</a></li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="col-sm">
      <ul>
        <li><a href='/events'>Events & Conferences</a></li>
        <li><a href='/digital_engagement'>Digital Engagement</a></li>
        <li><a href='/kyc_cdd'>KYC & CDD</a></li>
      </ul>
    </div>
  </div>
</div>


  <h2 className='color-text mt-3'>Features Categories</h2>
  <div className="container">
  <div className="row">
    <div className="col-sm-6">
      <ul>
        <li><a href='/feature?scrollTo=nfc'>NFC</a></li>
        <li><a href='/feature?scrollTo=geotagging'>Geotagging</a></li>
        <li><a href='/feature?scrollTo=aiscan'>AI-Enabled Scan</a></li>
        <li><a href='/feature?scrollTo=biometrics'>Biometrics</a></li>
      </ul>
    </div>
    <div className="col-sm-6">
      <ul>
        <li><a href='/feature?scrollTo=virtualbg'>Virtual Background</a></li>
        <li><a href='/feature?scrollTo=qrcall'>QR-Call/SMS/Email</a></li>
        <li><a href='/feature?scrollTo=mfa'>Multi-factor Authentication</a></li>
        <li><a href='/feature?scrollTo=ppf'>PPF Score</a></li>
      </ul>
    </div>
  </div>
</div>

  <h2 className='color-text mt-3'>Explore</h2>
  <div className="container text-start">
  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2 g-lg-3">
    <div className="col">
      <div><a href='/sitemap'>Sitemap</a></div>
    </div>
    <div className="col">
      <div><a href='/dalocalcommingsoon'>Discounts</a></div>
    </div>
    <div className="col">
      <div><a href='/partners'>Partners</a></div>
    </div>
    <div className="col">
      <div><a href='/comingsoon'>Investors</a></div>
    </div>
    <div className="col">
      <div><a href='/testimonials'>Testimonials</a></div>
    </div>
    <div className="col">
      <div><a href='/blogs'>Blogs</a></div>
    </div>
    <div className="col">
      <div><a href='/faq'>FAQs</a></div>
    </div>
    <div className="col">
      <div><a href='/help'>Help center</a></div>
    </div>
  </div>
</div>

  <h2 className='color-text mt-3'>Company</h2>
  <div className="container text-start">
    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4">
      <div className="col"><a href='/terms'>Terms & Conditions</a></div>
      <div className="col"><a href='/privacy'>Privacy Policies</a></div>
      <div className="col"><a href='/careers'>Careers</a></div>
      <div className="col"><a href='/cookies'>Cookies</a></div>
    </div>
  </div>
</section>

    </div>
   );

}

export default Sitemap;