import React, { useState, useEffect } from 'react';
import '../assets/css/main.css'

function Captcha() {
  const [score, setScore] = useState(null);

  useEffect(() => {
    // Load reCAPTCHA Enterprise script
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LdY88cpAAAAAItQpzEI-uAgO9v6uImidlrln2dz';
    script.async = true;
    script.onload = initializeRecaptcha;
    document.body.appendChild(script);

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initializeRecaptcha = () => {
    if (typeof window.grecaptcha === 'undefined' || typeof window.grecaptcha.enterprise === 'undefined') {
      console.error('reCAPTCHA Enterprise not initialized');
      return;
    }

    // Generate and verify reCAPTCHA token
    executeRecaptcha('submit_form')
      .then(token => verifyToken(token))
      .then(response => {
        setScore(response.score);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const executeRecaptcha = (action) => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute('6LdY88cpAAAAAItQpzEI-uAgO9v6uImidlrln2dz', { action })
          .then(resolve)
          .catch(reject);
      });
    });
  };

  const verifyToken = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mail/verify-captcha`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    });

    return await response.json();
  };

  return (
    <div className="captch">
    
    </div>
  );
}

export default Captcha;
