import React,{useState} from 'react'

//import banner_shape_8 from "../assets/images/banner/banner-shape-8.png"
import assBanner from "../assets/images/assn/An Innovative Platform.png"
import counter1 from "../assets/images/icon/counter1.png"
import counter2 from "../assets/images/icon/counter2.png"
import counter5 from "../assets/images/icon/counter5.png"

// import access11 from "../assets/images/access/access1.png"
import access1 from "../assets/images/assn/Icons/group_management.svg"
import access2 from "../assets/images/assn/Icons/event_management.svg"
import access3 from "../assets/images/assn/Icons/digital_management.svg"
import access4 from "../assets/images/assn/Icons/campaining.svg"
import access5 from "../assets/images/assn/Icons/Mentorship.svg"
import access6 from "../assets/images/assn/Icons/Fundraising.svg"
import access7 from "../assets/images/assn/Icons/Scholorship.svg"
import access8 from "../assets/images/assn/Icons/Enrich alumni.svg"

// import work_shape from "../assets/css/img/work-shape.png"
// import work1 from "../assets/images/work/work1.png"
// import work1 from "../assets/images/assn/Register.png"
// import work2 from "../assets/images/assn/Customize.png"
// import work3 from "../assets/images/assn/Onboard.png"
// import work4 from "../assets/images/assn/Engage.png"

import allInclusive from "../assets/images/assn/All-Inclusive Features.png"

import featurevdo from "../assets/images/feature/feature-video.png"
import advance1 from "../assets/images/assn/Sustainability and CSR_Option02.png"
import advance2 from "../assets/images/assn/International Collaboration.png"
import advance3 from "../assets/images/assn/Powering Collaboration.png"
import advance4 from "../assets/images/assn/Subscriptions & Data Security.png"
// import advance1 from "../assets/images/feature/advance1.png"
// import advance2 from "../assets/images/feature/advance2.png"
// import advance3 from "../assets/images/feature/advance3.png"
// import advance4 from "../assets/images/feature/advance3.png"


// import clientleft from "../assets/images/client/left.png"
// import clientright from "../assets/images/client/right.png"
// import client1 from "../assets/images/client/client1.jpg"
// import cate from "../assets/images/cate.png"

import balls from "../assets/images/balls/balls.png"


// import to_accessbg from "../assets/images/feature/to-access-bg.png"
// import work_bg from "../assets/images/work/work-bg.jpg"

// import client6 from "../assets/images/client/client06.png"
// import client2 from "../assets/images/client/client02.png"
// import client3 from "../assets/images/client/client03.png"
// import client4 from "../assets/images/client/client04.png"
// import client5 from "../assets/images/client/client05.png"
import Counter from './Counter'
import Download from './Download'
import Lightbox from './LightBox'
import CarouselNew from './AssociationCarousel'
// import Review from './Review'

const ContactSales = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [lightboxTitle, setlightboxTitle] = useState("");
    const [activeComponent, setActiveComponent] = useState(false);
  
    const openLightbox = (htmlOrElement) => {
      setIsOpen(true);
      // setContent(htmlOrElement);
      setlightboxTitle(htmlOrElement);
      setActiveComponent(true);
    };

    return (
        <>
            { /* <!--============= ScrollToTop Section Starts Here =============--> */}
            {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
            <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
            { /* <!--============= ScrollToTop Section Ends Here =============--> */}


            { /* <!--============= Banner Section Starts Here =============--> */}
            <section className="banner-8 assn-banner oh">
                {/* <div className="banner-shape-8 assn-banner d-none">
                    <img src={assBanner} alt="banner" />
                </div> */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="banner-content-8">
                                <h1 className="Newtitle">An Innovative Platform for Association Networks</h1>
                                <p>
                                Digitizing alumni associations and others for enhanced connectivity and engagement. Revolutionizing association networks with seamless connections and collaborative communication.
                                </p>
                                <div className="banner-button-group">
                                    <a href="/comingsoon" className="button-4">Create ID</a>
                                    {/* <a href="#0" className="button-4 active">Explore Features</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-lg-block d-none">
                            <img src={assBanner} alt="banner" className='pos-rel'/>
                        </div>
                        <div className="col-12">
                            <div className="counter-wrapper-3">
                                <div className="counter-item">
                                    <div className="counter-thumb">
                                        <img src={counter1} alt="icon" />
                                    </div>
                                    <div className="counter-content">
                                        <h2 className="title"><Counter end={17501}></Counter></h2>
                                        <span className="name">Premium User</span>
                                    </div>
                                </div>
                                <div className="counter-item">
                                    <div className="counter-thumb">
                                        <img src={counter2} alt="icon" />
                                    </div>
                                    <div className="counter-content">
                                        <h2 className="title"><Counter end={1987}></Counter></h2>
                                        <span className="name">Daily Visitors</span>
                                    </div>
                                </div>
                                <div className="counter-item">
                                    <div className="counter-thumb">
                                        <img src={counter5} alt="icon" />
                                    </div>
                                    <div className="counter-content">
                                        <h2 className="title"><Counter end={95}>%</Counter></h2>
                                        <span className="name">Satisfaction</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Banner Section Ends Here =============--> */}


            { /* <!--============= To Access Section Starts Here =============--> */}
            <section className="to-access-section padding-top bg_img_to_access mb-lg-5" id="feature">
                <div className="container">
                    <div className="section-header">
                        <h5 className="cate">Alumni Collaboration Made Easy</h5>
                        <h2 className="title">Explore Our Features</h2>
                        <p>Creating features with a focus on user satisfaction, usability, and completeness for alumni association and other association users.</p>
                    </div>

                    {activeComponent && (
                                    <Lightbox
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    lightboxTitle={lightboxTitle}
                                    setlightboxTitle={setlightboxTitle}
                                    />
                )}

                    <div className="row mb-30 justify-content-center">
                        <div className="col-lg-3 col-sm-6" onClick={() => openLightbox('Group Management')}>
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access1} alt="access"/>
                                    </div>
                                </div>
                                <h5 className="title">Group Management</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" onClick={() => openLightbox('Event Management')}>
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access2} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Event Management</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" onClick={() => openLightbox('Digital Engagement')}>
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'>{/* Removed className="thumb" */}
                                        <img src={access3} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Digital Engagement</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6"onClick={() => openLightbox('Alumni Campaigning')} >
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access4} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Campaigning</h5>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center">
                <a href="#0" className="get-button">Explore Features</a>
            </div> */}
                </div>
            </section>
            { /* <!--============= To Access Section Ends Here =============--> */}



            { /* <!--============= To Access Section-1 Starts Here =============--> */}
            <section className="to-access-section bg_img_to_access mb-lg-5">
                <div className="container">

                    {activeComponent && (
                                    <Lightbox
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    lightboxTitle={lightboxTitle}
                                    setlightboxTitle={setlightboxTitle}
                                    />
                )}

                    <div className="row mb-30 justify-content-center">
                        <div className="col-lg-3 col-sm-6" onClick={() => openLightbox('Mentorship')}>
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access5} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Mentorship</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" onClick={() => openLightbox('Fundraising')}>
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access6} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Fundraising</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6" onClick={() => openLightbox('Scholarship')}>
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access7} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Scholarship</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6"onClick={() => openLightbox('Enrich Alumni')} >
                            <div className="to-access-item">
                                <div className="to-access-thumb">
                                    {/* <span className="anime"></span> */}
                                    <div className='assn-thumb'> {/* Removed className="thumb" */}
                                        <img src={access8} alt="access" />
                                    </div>
                                </div>
                                <h5 className="title">Enrich Alumni</h5>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center">
                <a href="#0" className="get-button">Explore Features</a>
            </div> */}
                </div>
            </section>
            { /* <!--============= To Access Section-1 Ends Here =============--> */}


            { /* <!--============= How It Works Section Starts Here =============--> */}
            {/* <section className="work-section bg_img_alumni_2 mb-md-95 pb-md-0">
                <div className="oh padding-top pos-rel">
                    <div className="top-shape d-none d-lg-block">
                        <img src={work_shape} alt="css" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-7">
                                <div className="section-header left-style cl-white">
                                    <h5 className="cate">Discover How It Works</h5>
                                    <h2 className="title">Step-by-Step Onboarding for Associations</h2>
                                    <p>It's easier than you think.</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="work-slider owl-carousel owl-theme" data-slider-id="2">
                            <div className="work-item">
                                <div className="work-thumb">
                                    <img src={work1} alt="work 1" />
                                </div>
                                <div className="work-content cl-white">
                                    <h3 className="title">Register</h3>
                                    <p>Begin by registering and verifying your account on our platform to access exclusive benefits and receive your digital business card for sharing your information.</p>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-thumb">
                                    <img src={work2} alt="work 2" />
                                </div>
                                <div className="work-content cl-white">
                                    <h3 className="title">Customize</h3>
                                    <p> Personalize your digital business card and connect with peers, explore resources, and engage in meaningful activities within alumni and society communities with consent.</p>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-thumb">
                                    <img src={work3} alt="work 3" />
                                </div>
                                <div className="work-content cl-white">
                                    <h3 className="title">Onboard</h3>
                                    <p>Navigate through our user-friendly onboarding process designed specifically for association users. Easily set up your profile, preferences, and privacy settings to tailor your experience.
                                    </p>
                                </div>
                            </div>
                            <div className="work-item">
                                <div className="work-thumb">
                                    <img src={work4} alt="work 4" />
                                </div>
                                <div className="work-content cl-white">
                                    <h3 className="title">Engage</h3>
                                    <p>Explore virtual mentorships, event management, and other engaging features tailored to your interests within the association network.
                                    </p>
                                </div>
                            </div>
                        </div>  */}

                        <CarouselNew />
                    {/* </div>
                </div>
                <div className="container">
                    <div className="count-slider owl-thumbs" data-slider-id="2">
                        <div className="count-item">
                            <span className="serial">01</span>
                            <h5 className="title">Register</h5>
                        </div>
                        <div className="count-item">
                            <span className="serial">02</span>
                            <h5 className="title">Customize</h5>
                        </div>
                        <div className="count-item">
                            <span className="serial">03</span>
                            <h5 className="title">Onboard</h5>
                        </div>
                        <div className="count-item">
                            <span className="serial">04</span>
                            <h5 className="title">Engage</h5>
                        </div>
                    </div>
                </div>
            </section> */}
            { /* <!--============= How It Works Section Ends Here =============--> */}
            
            {/* <AssnCarousel /> */}

            { /* <!--============= Access Section Starts Here =============--> */}
            <section className="access-section padding-bottom-2 padding-top-2 mob-pt-40 oh">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-4 col-xl-3 rtl d-none d-lg-block">
                            <img src={allInclusive} alt="access" />
                        </div>
                        <div className="col-lg-8">
                            <div className="access-content">
                                <div className="section-header left-style mb-olpo">
                                    <h5 className="cate">All in One Access</h5>
                                    <h2 className="title">All-Inclusive Features for Association Development</h2>
                                    <p>Explore our platform's features for association users, including group management, event tools, digital engagement, and virtual mentorship. Benefit from flexible membership plans with collective fees and customizable subscriptions.</p>
                                </div>
                                {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Access Section Ends Here =============--> */}


            { /* <!--============= Feature Video Section Starts Here =============--> */}
            <section className="feature-video-section padding-top dark-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="section-header mw-100">
                                <h5 className="cate">Amazing features to convince you</h5>
                                <h2 className="title">Watch our video presentation</h2>
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <p>In the process of making a app, the satisfaction of users is the most
                                            important and the focus is on usability and completeness</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="pos-rel mw-100">
                                <img className="w-100" src={featurevdo} alt="bg" />
                                <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button-2 popup">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <i className="flaticon-play"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Feature Video Section Ends Here =============--> */}


            { /* <!--============= Advance Feature Section Starts Here =============--> */}
            <section className="advance-feature-section padding-top-2 padding-bottom-2">
                <div className="container">
                    <div className="advance-feature-item padding-top-2 padding-bottom-2">
                        <div className="advance-feature-thumb AssociationImg">
                            <img src={advance1} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Fostering Impactful Sustainability & CSR</h5>
                                <h2 className="title">Sustainability and Corporate Social Responsibility (CSR)</h2>
                                <p>Neoin's Alumni Association platform promotes sustainability and CSR. Alumni collaborate on <span className='first-word'>projects, share best practices,</span> and <span className='first-word'> access resources</span> for positive impact. Join us in building a socially responsible community for future generations.</p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    <div className="advance-feature-item padding-top-2 padding-bottom-2">
                        <div className="advance-feature-thumb AssociationImg">
                            <img src={advance2} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Connecting Globally for Positive Impact</h5>
                                <h2 className="title">International Collaboration and Global Outreach</h2>
                                <p>Neoin's Alumni Association platform promotes global collaboration and sustainability. Our innovative features encourage <span className='first-word'>eco-friendly practices</span> and <span className='first-word'> social impact initiatives, fostering worldwide connections </span> and <span className='first-word'>collaborations</span> among alumni.</p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    <div className="advance-feature-item padding-top-2 padding-bottom-2">
                        <div className="advance-feature-thumb AssociationImg">
                            <img src={advance3} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Professional Bonds for Collective Success</h5>
                                <h2 className="title">Powering Collaboration in Alumni Associations</h2>
                                <p>Professional networking within Alumni Associations fosters <span className='first-word'>collaboration, knowledge exchange,</span> and <span className='first-word'>career advancement </span>.It enhances alumni experiences, drives innovation, and contributes to member success.</p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                    <div className="advance-feature-item padding-bottom-2"> {/* Removed padding-top-2*/}
                        <div className="advance-feature-thumb AssociationImg">
                            <img src={advance4} alt="feature" />
                        </div>
                        <div className="advance-feature-content">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">Personalized Data Services</h5>
                                <h2 className="title">Subscriptions & Data Security</h2>
                                <p>Our Alumni Association platform merges robust <span className='first-word'> data management </span> with tailored <span className='first-word'> subscriptions </span> for optimized member experiences. Personalized communication and exclusive benefits nurture a vibrant alumni community, underpinned by strong data security.</p>
                            </div>
                            {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Advance Feature Section Ends Here =============--> */}

            {/* <!--=============Feature Section Starts Here =============--> */}
            {/* <section className="client-section light-blue-bg oh">
                <div className="container">
                    <div className="client-wrapper-16">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-xl-8">
                                <div className="section-header mw-100 cl-white">
                                    <h2 className="title"> is Highly Appreciated by the Users</h2>
                                </div>
                            </div>
                            <div className="col-xl-10">
                                <div className="row justify-content-center ff-heading mb-30-none">
                                    <div className="col-lg-6 col-sm-10">
                                        <div className="appreciate-item">
                                            <div className="header">
                                                <h2 className="title cl-white">4.7 / 5</h2>
                                                <div className="ratings">
                                                    <span><i className="fas fa-star"></i></span>
                                                    <span><i className="fas fa-star"></i></span>
                                                    <span><i className="fas fa-star"></i></span>
                                                    <span><i className="fas fa-star"></i></span>
                                                    <span><i className="fas fa-star"></i></span>
                                                </div>
                                            </div>
                                            <p className="cl-white">App rating based on more than 200000
                                                reviews on iOS and Android</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-10">
                                        <div className="appreciate-item">
                                            <div className="header">
                                                <h2 className="title cl-white">9 589 053</h2>
                                            </div>
                                            <p className="cl-white">App rating based on more than 200000 reviews on iOS and Android</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="client-say-16">
                        <div className="client-item-16 ff-heading">
                            <div className="client-content">
                                <div className="body-area">
                                    <span className="head">Awesome App!</span>
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>“Ideas are easy. Implementation is hard.”</p>
                                </div>
                                <div className="heading-area">
                                    <h5 className="title"><a href="#0">Jazmin Chowdhury</a></h5>
                                    <span className="info">CEO & Founder</span>
                                </div>
                            </div>
                            <div className="client-thumb">
                                <a href="#0"><img src={client1} alt="" /></a>
                            </div>
                        </div>
                        <div className="client-item-16 ff-heading active">
                            <div className="client-content active">
                                <div className="body-area">
                                    <span className="head">Awesome App!</span>
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>“Ideas are easy. Implementation is hard.”</p>
                                </div>
                                <div className="heading-area">
                                    <h5 className="title"><a href="#0">Flora Oliver</a></h5>
                                    <span className="info">CEO & Founder</span>
                                </div>
                            </div>
                            <div className="client-thumb">
                                <a href="#0"><img src={client2} alt="" /></a>
                            </div>
                        </div>
                        <div className="client-item-16 ff-heading">
                            <div className="client-content">
                                <div className="body-area">
                                    <span className="head">Awesome App!</span>
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>“Ideas are easy. Implementation is hard.”</p>
                                </div>
                                <div className="heading-area">
                                    <h5 className="title"><a href="#0">Perry Piper</a></h5>
                                    <span className="info">CEO & Founder</span>
                                </div>
                            </div>
                            <div className="client-thumb">
                                <a href="#0"><img src={client3} alt="" /></a>
                            </div>
                        </div>
                        <div className="client-item-16 ff-heading">
                            <div className="client-content">
                                <div className="body-area">
                                    <span className="head">Awesome App!</span>
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>“Ideas are easy. Implementation is hard.”</p>
                                </div>
                                <div className="heading-area">
                                    <h5 className="title"><a href="#0">Elsa Jean</a></h5>
                                    <span className="info">CEO & Founder</span>
                                </div>
                            </div>
                            <div className="client-thumb">
                                <a href="#0"><img src={client4} alt="" /></a>
                            </div>
                        </div>
                        <div className="client-item-16 ff-heading">
                            <div className="client-content">
                                <div className="body-area">
                                    <span className="head">Awesome App!</span>
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>“Ideas are easy. Implementation is hard.”</p>
                                </div>
                                <div className="heading-area">
                                    <h5 className="title"><a href="#0">Ava Adams</a></h5>
                                    <span className="info">CEO & Founder</span>
                                </div>
                            </div>
                            <div className="client-thumb">
                                <a href="#0"><img src={client5} alt="" /></a>
                            </div>
                        </div>
                        <div className="client-item-16 ff-heading">
                            <div className="client-content">
                                <div className="body-area">
                                    <span className="head">Awesome App!</span>
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>“Ideas are easy. Implementation is hard.”</p>
                                </div>
                                <div className="heading-area">
                                    <h5 className="title"><a href="#0">Dani Daniels</a></h5>
                                    <span className="info">CEO & Founder</span>
                                </div>
                            </div>
                            <div className="client-thumb">
                                <a href="#0"><img src={client6} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--=============Feature Section Ends Here =============--> */}



            { /* <!--============= Testimonial Section Starts Here =============--> */}
           
            {/* <section className="testimonial-section padding-top pt-lg-half">
                <div className="container">
                    <div className="section-header">
                        <h5 className="cate">Testimonials</h5>
                        <h2 className="title">5000+ happy clients all around the world</h2>
                    </div>
                    <div className="testimonial-wrapper">
                        <a href="#0" className="testi-next trigger">
                            <img src={clientleft} alt="client" />
                        </a>
                        <a href="#0" className="testi-prev trigger">
                            <img src={clientright} alt="client" />
                        </a>
                        <div className="testimonial-area testimonial-slider owl-carousel owl-theme">
                            <div className="testimonial-item">
                                <div className="testimonial-thumb">
                                    <div className="thumb">
                                        <img src={client1} alt="client" />
                                    </div>
                                </div>
                                <div className="testimonial-content">
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>
                                        Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                                    </p>
                                    <h5 className="title"><a href="#0">Bela Bose</a></h5>
                                </div>
                            </div>
                            <div className="testimonial-item">
                                <div className="testimonial-thumb">
                                    <div className="thumb">
                                        <img src={client1} alt="client" />
                                    </div>
                                </div>
                                <div className="testimonial-content">
                                    <div className="ratings">
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                        <span><i className="fas fa-star"></i></span>
                                    </div>
                                    <p>
                                        Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                                    </p>
                                    <h5 className="title"><a href="#0">Raihan Rafuj</a></h5>
                                </div>
                            </div>
                        </div>
                        <div className="button-area">
                            <a href="#0" className="button-2">Leave a review <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </section> */}
            { /* <!--============= Testimonial Section Ends Here =============--> */}


            { /* <!--============= Trial Section Starts Here =============--> */}
            <section className="trial-section padding-bottom-2 padding-top-2 mob-pt-0 mob-pb-0">
                <div className="container">
                    <div className="trial-wrapper padding-top padding-bottom pr">
                        <div className="ball-1">
                            <img src={balls} alt="balls" />
                        </div>
                        <div className="trial-content cl-white">
                            <h3 className="title">Customize your Plan!</h3>
                            <p>
                                Get Connected with our <br /> Sales Team for more Information.
                            </p>
                        </div>
                        <div className="trial-button">
                            <a href="/contact" className="transparent-button">Contact Us<i className="flaticon-right ml-xl-2"></i></a>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Trial Section Ends Here =============--> */}
            <Download />

        </>
    )
}

export default ContactSales