import React from "react";
// import banner11 from "../assets/images/banner/banner11/banner11-bg.png";
// import rocket from "../assets/images/feature/rocket.png";
// import banner12 from "../assets/images/banner/banner11/banner11.png";

import engageBanner from "../assets/images/digi_eng/Digital Engagement Banner.png"
import counter1 from "../assets/images/icon/counter1.png";
import counter2 from "../assets/images/icon/counter2.png";
// import graph from "../assets/images/banner/banner11/graph.png";
// import boy from "../assets/images/banner/banner11/boy.png";
// import girl from "../assets/images/banner/banner11/girl.png"
// import tree1 from "../assets/images/banner/banner11/tree1.png";
// import tree2 from "../assets/images/banner/banner11/tree1.png";
// import am2 from "../assets/images/feature/am-obj2.png";
// import am from "../assets/images/feature/am-obj.png";
// import fea from "../assets/images/feature/am-fea1.png";
// import fea2 from "../assets/images/feature/am-fea2.png";
// import fea3 from "../assets/images/feature/am-fea3.png";
// import fea4 from "../assets/images/feature/am-fea4.png";
// import workbg from "../assets/images/work/work-bg2.jpg";
// import workshape from "../assets/css/img/work-shape.png";
// import work1 from "../assets/images/work/work1.png";
// import tool from "../assets/images/recharge/tool.png";
// import recharge1 from "../assets/images/recharge/1.png";
// import recharge2 from "../assets/images/recharge/2.png";
// import recharge3 from "../assets/images/recharge/3.png";
// import recharge4 from "../assets/images/recharge/4.png";
// import recharge5 from "../assets/images/recharge/5.png";
// import recharge6 from "../assets/images/recharge/6.png";
// import recharge7 from "../assets/images/recharge/7.png";
// import recharge8 from "../assets/images/recharge/8.png";
// import featurevideo from "../assets/images/feature/feature-video.png";
// import advance1 from "../assets/images/feature/advance1.png";
// import advance2 from "../assets/images/feature/advance2.png";
// import advance3 from "../assets/images/feature/advance3.png";

import advance1 from "../assets/images/digi_eng/interactive-customer-comm.png";
import advance2 from "../assets/images/digi_eng/Personalized Financial Insights.png";
import advance3 from "../assets/images/digi_eng/Multi-Channel Engagement.png";
import advance4 from "../assets/images/digi_eng/Gamification and Rewards Programs.png";
import advance5 from "../assets/images/digi_eng/Community Building.png";
import advance6 from "../assets/images/digi_eng/Data-Driven Insights.png";

import Counter from "./Counter";

import { useScrollToTop } from "./UseScrollToTop";

function Ppf(){

    useScrollToTop();

    return (
        <>    
    {/* <!--============= Banner Section Starts Here =============--> */ }
    <section className="banner-11 oh pos-rel engage-banner">
        <div className="extra-bg bg_img_bnr_11"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="banner-content-11">
                        <h1 className="Newtitle">Digital Engagement Solution by Neoin</h1>
                        <p>
                        Neoin's Digital Engagement solution revolutionizes all organizations with innovative tools, leveraging cutting-edge Saas technology for seamless communication, personalized experiences, and actionable insights.
                        </p>
                        {/* <form className="banner-search-form style-two">
                            <input type="text" placeholder="Enter your e-mail"/>
                            <button type="submit"><i className="flaticon-right"></i></button>
                        </form> */}
                    </div>
                    <div className="banner-odometer-two">
                        <div className="counter-item">
                            <div className="counter-thumb">
                                <img src={counter1} alt="icon"/>
                            </div>
                            <div className="counter-content">
                                <h2 className="title"><Counter end={17501}></Counter></h2>
                                <span>Premium User</span>
                            </div>
                        </div>
                        <div className="counter-item">
                            <div className="counter-thumb">
                                <img src={counter2} alt="icon"/>
                            </div>
                            <div className="counter-content">
                                <h2 className="title"><Counter end={1987}></Counter></h2>
                                <span>Daily Visitors</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-none d-lg-block">
                    <div className="banner-thumb-11">
                        <div className="main-thumb wow slideInLeft engage-banner-img">
                            <img src={engageBanner} alt="banner"/>
                        </div>
                        {/* <div className="graph wow bounceInDown" data-wow-delay="3s" data-wow-duration="1s"><img src={graph} alt="banner"/></div>
                        <div className="boy wow slideInRight" data-wow-delay="1s" data-wow-duration="1s"><img src={boy} alt="banner"/></div>
                        <div className="girl wow slideInUp" data-wow-delay="1.5s" data-wow-duration="1s"><img src={girl} alt="banner"/></div>
                        <div className="tree1 wow slideInUp" data-wow-delay="2s" data-wow-duration="1s"><img src={tree1} alt="banner"/></div>
                        <div className="tree2 wow slideInUp" data-wow-delay="2.5s" data-wow-duration="1s"><img src={tree2} alt="banner"/></div> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Banner Section Ends Here =============-->


    <!--============= How It Works Section Starts Here =============--> */}
    {/* <div className="amazing-feature padding-top padding-bottom pos-rel oh" id="feature">
        <div className="rocket d-none d-xl-block" data-paroller-factor="0.70" data-paroller-factor-lg="-0.70"
        data-paroller-type="foreground" data-paroller-direction="horizontal"><img src={rocket} alt="feature"/></div>
        <div className="am-obj"><img src={am2} alt="feature"/></div>
        
        <div className="am-obj two"><img src={am} alt="feature"/></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-7">
                    <div className="section-header">
                        <h5 className="cate">Extra Crazy Features</h5>
                        <h2 className="title">That you'll love Mosto</h2>
                        <p className="mw-100">In the process of making a app, the satisfaction of users is the most 
                            important and the focus is on usability and completeness</p>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row justify-content-center mb-30-none">
                        <div className="col-sm-6 col-lg-3">
                            <div className="am-item">
                                <div className="am-thumb">
                                    <img src={fea} alt="feature"/>
                                </div>
                                <div className="am-content">
                                    <h5 className="title">Free Trial</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="am-item active">
                                <div className="am-thumb">
                                    <img src={fea2} alt="feature"/>
                                </div>
                                <div className="am-content">
                                    <h5 className="title">Secure Data</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="am-item">
                                <div className="am-thumb">
                                    <img src={fea3} alt="feature"/>
                                </div>
                                <div className="am-content">
                                    <h5 className="title">Quick Access</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="am-item">
                                <div className="am-thumb">
                                    <img src={fea4} alt="feature"/>
                                </div>
                                <div className="am-content">
                                    <h5 className="title">24h Support</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!--============= How It Works Section Starts Here =============-->


    <!--============= How It Works Section Starts Here =============--> */}
    {/* <section className="work-section padding-bottom bg_img_workBg_enterprise mb-md-95 pb-md-0" id="how">
        <div className="oh padding-top pos-rel">
            <div className="top-shape d-none d-lg-block">
                <img src={workshape} alt="css"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xl-7">
                        <div className="section-header left-style cl-white">
                            <h5 className="cate">Describe Your App</h5>
                            <h2 className="title">Let’s See How It Work</h2>
                            <p>It's easier than you think.Follow the simple easy steps</p>
                        </div>
                    </div>
                </div>
                <div className="work-slider owl-carousel owl-theme" data-slider-id="2">
                    <div className="work-item">
                        <div className="work-thumb">
                            <img src={work1} alt="work"/>
                        </div>
                        <div className="work-content cl-white">
                            <h3 className="title">Register</h3>
                            <p>First, you need to register a user account on our website before
                                configuring and using it on a regular basis.</p>
                            <a href="#0" className="get-button white light">Get Started <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-thumb">
                            <img src={work1} alt="work"/>
                        </div>
                        <div className="work-content cl-white">
                            <h3 className="title">Configure</h3>
                            <p>First, you need to register a user account on our website before
                                configuring and using it on a regular basis.</p>
                            <a href="#0" className="get-button white light">Get Started <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-thumb">
                            <img src={work1} alt="work"/>
                        </div>
                        <div className="work-content cl-white">
                            <h3 className="title">Integrate</h3>
                            <p>First, you need to register a user account on our website before
                                configuring and using it on a regular basis.</p>
                            <a href="#0" className="get-button white light">Get Started <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="work-thumb">
                            <img src={work1} alt="work"/>
                        </div>
                        <div className="work-content cl-white">
                            <h3 className="title">Yay! Done</h3>
                            <p>First, you need to register a user account on our website before
                                configuring and using it on a regular basis.</p>
                            <a href="#0" className="get-button white light">Get Started <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="count-slider owl-thumbs" data-slider-id="2">
                <div className="count-item">
                    <span className="serial">01</span>
                    <h5 className="title">Register</h5>
                </div>
                <div className="count-item">
                    <span className="serial">02</span>
                    <h5 className="title">Configure</h5>
                </div>
                <div className="count-item">
                    <span className="serial">03</span>
                    <h5 className="title">Integrade</h5>
                </div>
                <div className="count-item">
                    <span className="serial">04</span>
                    <h5 className="title">Yay! Done</h5>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= How It Works Section Ends Here =============-->


    <!--============= Tool Section Starts Here =============--> */}
    {/* <section className="tool-section padding-bottom padding-top">
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-6 col-xl-5 rtl d-none d-lg-block">
                    <img src={tool} alt="recharge"/>
                </div>
                <div className="col-lg-6">
                    <div className="section-header left-style">
                        <h5 className="cate">Discover powerful tool</h5>
                        <h2 className="title">For your customers.</h2>
                        <p>In the process of making a app, the satisfaction of users is the most important and the focus is on usability and completeness</p>
                    </div>
                    <div className="tool-wrapper mb-40">
                        <div className="tool-slider mb-30-none owl-carousel owl-theme">
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge1} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Optimization</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge2} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Optimization</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge3} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Safety</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge4} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Sustainability</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge5} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Compliance</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge6} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Integrations</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge7} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Expandability</h5>
                            </div>
                            <div className="to-access-item style-two">
                                <div className="to-access-thumb">
                                    <span className="anime"></span>
                                    <div className="thumb">
                                        <img src={recharge8} alt="recharge"/>
                                    </div>
                                </div>
                                <h5 className="title">Multilingual</h5>
                            </div>
                        </div>
                    </div>
                    <a href="#0" className="button-3 long-light">Start Using for free <i className="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Tool Section Ends Here =============--> */}


    {/* <!--============= Feature Video Section Starts Here =============--> */}
    {/* <section className="feature-video-section padding-top dark-bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">Amazing features to convince you</h5>
                        <h2 className="title">Watch our video presentation</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <p>In the process of making a app, the satisfaction of users is the most 
                                    important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="pos-rel mw-100">
                        <img className="w-100" src={featurevideo} alt="bg"/>
                        <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button-2 popup">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <i className="flaticon-play"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Feature Video Section Ends Here =============-->


    <!--============= Advance Feature Section Starts Here =============--> */}
    <section className="advance-feature-section padding-top-2">
        <div className="container">
            <div className="advance-feature-item ">
                <div className="advance-feature-thumb EngageImg">
                    <img src={advance1} alt="feature" />
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">Advance features give you full control.</h5> */}
                        <h2 className="title">Interactive Customer Communication</h2>
                        <p>Neoin offers a suite of interactive communication tools, including chatbots, messaging platforms, and personalized notifications. Engage customers in real-time conversations, provide instant support, and deliver targeted messages to drive conversions and satisfaction.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item padding-top-2">
                <div className="advance-feature-thumb EngageImg">
                    <img src={advance2} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">Powerful and Flexible</h5> */}
                        <h2 className="title">Personalized Financial Insights</h2>
                        <p>With Neoin's AI-powered analytics, professional companies can deliver personalized financial insights and recommendations to their customers. Leverage data-driven algorithms to offer tailored advice, identify opportunities, and empower users to make informed financial decisions.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item padding-top-2">
                <div className="advance-feature-thumb EngageImg">
                    <img src={advance3} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">Discover powerful tool for your customers.</h5> */}
                        <h2 className="title">Multi-Channel Engagement</h2>
                        <p>Neoin facilitates multi-channel engagement across various touchpoints, including web, mobile, social media, and email. Seamlessly connect with customers wherever they are, delivering consistent messaging and experiences to enhance brand loyalty and satisfaction.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>

            <div className="advance-feature-item padding-top-2">
                <div className="advance-feature-thumb EngageImg">
                    <img src={advance4} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">Advance features give you full control.</h5> */}
                        <h2 className="title">Gamification and Rewards Programs</h2>
                        <p>Drive user engagement and loyalty with gamification and rewards programs powered by Neoin. Design interactive challenges, offer incentives for desired behaviors, and foster a sense of achievement and progress to keep users actively engaged with your platform.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item padding-top-2">
                <div className="advance-feature-thumb EngageImg">
                    <img src={advance5} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">Powerful and Flexible</h5> */}
                        <h2 className="title">Community Building and Networking</h2>
                        <p>Neoin enables Fintech organizations to build vibrant communities and networks around their products and services. Host forums, discussion groups, and virtual events to facilitate knowledge sharing, peer-to-peer support, and collaboration among users.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
            <div className="advance-feature-item padding-top-2">
                <div className="advance-feature-thumb EngageImg">
                    <img src={advance6} alt="feature"/>
                </div>
                <div className="advance-feature-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">Discover powerful tool for your customers.</h5> */}
                        <h2 className="title">Data-Driven Insights and Analytics</h2>
                        <p>Gain valuable insights into user behavior, preferences, and engagement metrics with Neoin's advanced analytics dashboard. Track key performance indicators, measure campaign effectiveness, and optimize strategies for continuous improvement and growth.</p>
                    </div>
                    {/* <a href="#0" className="get-button">get free  trial <i className="flaticon-right"></i></a> */}
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Advance Feature Section Ends Here =============--> */}        
        </>
    )
}

export default Ppf