import pageheader2 from "../assets/css/img/page-header.png";
import slider1 from "../assets/images/blog/Blog1_small.png";
import slider2 from '../assets/images/blog/Blog2_small.png'
import React, { useState } from 'react';
import axios from 'axios';
import { useScrollToTop } from "./UseScrollToTop";
import { toast } from 'react-toastify';



function Addreview() {
    const apiKey = process.env.REACT_APP_API_KEY; // actual API key
  const headers = {
    'x-api-key': apiKey,
    'Content-Type': 'application/json'
  };

    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    useScrollToTop();
    // const { companyName, fullName, phoneNumber, email, subject, message, rating, agreeToEmails }
    const [formData, setFormData] = useState({
        companyName: '',
        fullName: '',
        phoneNumber: '',
        email: '',
        subject: '',
        message: '',
        rating: "5",
        agreeToEmails: true,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            console.log(formData);
            // ${process.env.REACT_APP_BACKEND_URL}
            const response = await axios.post(
                'http://localhost:5000/contact/submit',
                formData,
                {
                  headers: headers
                }
              );
              
            toast.success("Review Added Successfully!");

            // You can handle success here
        } catch (error) {
            console.error('Error sending form data:', error);
            // You can handle errors here
        }

        // Reset form data
        // setFormData({
        //     companyName: '',
        //     fullName: '',
        //     phoneNumber: '',
        //     email: '',
        //     subject: '',
        //     message: '',
        //     rating: '',
        //     agreeToEmails: true
        // });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    return (

        <>
            {/* <!--============= Header Section Ends Here =============--> */}
            <section className="page-header bg_img_pg_header oh">
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageheader2} alt="css" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Add Review</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                Add Review
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <!--============= Header Section Ends Here =============-->



            <!--============= Blog Section Starts Here =============--> */}
            <section className="blog-section padding-top padding-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">


                            <div className="contact-wrapper">
                                {/* <h4 className="title text-center mb-30">Add Review</h4> */}
                                <form onSubmit={handleSubmit} className="contact-form" id="contact_form_submit">
                                    <div className="form-group">
                                        <label htmlFor="companyName">Your Company Name</label>
                                        <input type="text" name="companyName" id="companyName" placeholder="Enter Your Company Name" value={formData.companyName} required onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fullName">Your Full Name</label>
                                        <input type="text" name="fullName" id="fullName" placeholder="Enter Your Full Name" value={formData.fullName} required onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <input type="text" name="phoneNumber" id="phoneNumber" placeholder="Enter Your Phone Number" value={formData.phoneNumber} required onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Your Email </label>
                                        <input type="text" name="email" id="email" placeholder="Enter Your Email" value={formData.email}  required onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subject">Your Subject</label>
                                        <input type="text" name="subject" id="subject" placeholder="Enter Your Subject" value={formData.subject} required onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="message">Your Message </label>
                                        <textarea id="message" name="message" placeholder="Enter Your Message" required value={formData.message} onChange={handleInputChange}></textarea>
                                        <div className="form-check">
                                            <input type="checkbox" id="check" name="agreeToEmails" checked={isChecked} onChange={handleCheckboxChange} required />
                                            <label className="contactpage" htmlFor="check">I agree to receive emails, newsletters, and promotional messages</label>
                                        </div>
                                    </div>

                                    <span id="msg"></span>

                                    <div className="form-group">
                                        <button type="submit" className='inline-button banner-btn-new ' name="contact_submit" id="banner-btn">Add Review</button>
                                    </div>
                                </form>


                            </div>



                            {/* <article className="mb-40-none">
                                <div className="post-item">
                                    <div className="post-thumb">
                                        <a href="blog-single-1.html"><img src={blog1} alt="blog"/></a>
                                    </div>
                                    <div className="post-content">
                                        <h3 className="title">
                                            <a href="blog-single-1.html">Unlocking the Power of Digital Business Cards with Neoin</a>
                                        </h3>
                                        <p>In this blog post, we'll explore how Neoin is revolutionizing the way professionals and businesses connect with its innovative digital business card platform. Learn how Neoin's features such as QR code generation and customizable profiles are streamlining networking and enhancing digital communication.</p>
                                        <a href="blog-single-1.html" className="read">3 min Read</a>
                                    </div>
                                </div>
                                <div className="post-item">
                                    <div className="post-thumb">
                                        <a href="blog-single-1.html"><img src={blog2} alt="blog"/></a>
                                    </div>
                                    <div className="post-content">
                                        <h3 className="title">
                                            <a href="blog-single-1.html">Enhance Your Online Meetings with Neoin's Virtual Backgrounds</a>
                                        </h3>
                                        <p>Discover how Neoin's virtual backgrounds feature is taking online meetings to the next level. From professional settings to fun and creative designs, Neoin offers a wide range of options to suit every occasion. Say goodbye to distracting backgrounds and elevate your video calls with Neoin.</p>
                                        <a href="blog-single-1.html" className="read">3 min Read</a>
                                    </div>
                                </div>
                                <div className="post-item">
                                    <div className="post-thumb">
                                        <a href="blog-single-1.html"><img src={blog3} alt="blog"/></a>
                                    </div>
                                    <div className="post-content">
                                        <h3 className="title">
                                            <a href="blog-single-1.html">Stay Secure with Neoin: Exploring Multi-Factor Authentication (MFA)</a>
                                        </h3>
                                        <p>Security is paramount in today's digital world, and Neoin is committed to keeping your information safe. In this blog post, we'll delve into Neoin's Multi-Factor Authentication (MFA) feature, which adds an extra layer of protection to your account. Learn how MFA works and why it's essential for safeguarding your digital assets.</p>
                                        <a href="blog-single-1.html" className="read">3 min Read</a>
                                    </div>
                                </div>
                                {/* <div className="post-item">
                                    <div className="post-thumb">
                                        <a href="blog-single-1.html"><img src={blog4} alt="blog"/></a>
                                    </div>
                                    <div className="post-content">
                                        <h3 className="title">
                                            <a href="blog-single-1.html">The Most Comprehensive Mobile
                                                App Prototyping Guide</a>
                                        </h3>
                                        <p>Hybrid mobile applications are like any other applications you will see
                                            on your mobile.</p>
                                        <a href="blog-single-1.html" className="read">3 min Read</a>
                                    </div>
                                </div> 
                            </article> */}
                            {/* <div className="pagination-area text-center pt-50 pb-50 pb-lg-none">
                                <a href="#0"><i className="fas fa-angle-double-left"></i><span>Prev</span></a>
                                <a href="#0">1</a>
                                <a href="#0">2</a>
                                <a href="#0" className="active">3</a>
                                <a href="#0">4</a>
                                <a href="#0">5</a>
                                <a href="#0"><span>Next</span><i className="fas fa-angle-double-right"></i></a>
                            </div> */}
                        </div>
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <aside className="sticky-menu">
                                {/* <div className="widget widget-search">
                                    <h5 className="title">search</h5>
                                    <form className="search-form">
                                        <input type="text" placeholder="Enter your Search Content" required/>
                                            <button type="submit"><i className="flaticon-loupe"></i>Search</button>
                                    </form>
                                </div> */}
                                <div className="widget widget-post">
                                    <h5 className="title">latest post</h5>
                                    <div className="slider-nav">
                                        <span className="widget-prev"><i className="fas fa-angle-left"></i></span>
                                        <span className="widget-next active"><i className="fas fa-angle-right"></i></span>
                                    </div>
                                    <div className="widget-slider owl-carousel owl-theme">
                                        <div className="item">
                                            <div className="thumb">
                                                <a href="#0">
                                                    <img src={slider1} alt="blog" />
                                                </a>
                                            </div>
                                            <div className="content">
                                                <h6 className="p-title">
                                                    <a href="#0">Power of Digital Business Card with Neoin</a>
                                                </h6>
                                                <div className="meta-post">
                                                    <a href="#0" className="mr-4"><i className="flaticon-chat-1"></i>20 Comments</a>
                                                    <a href="#0"><i className="flaticon-eye"></i>466 View</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="thumb">
                                                <a href="#0">
                                                    <img src={slider2} alt="blog" />
                                                </a>
                                            </div>
                                            <div className="content">
                                                <h6 className="p-title">
                                                    <a href="#0">Enhance your meetings with Neoin</a>
                                                </h6>
                                                <div className="meta-post">
                                                    <a href="#0" className="mr-4"><i className="flaticon-chat-1"></i>20 Comments</a>
                                                    <a href="#0"><i className="flaticon-eye"></i>466 View</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget-follow">
                                    <h5 className="title">Follow Us</h5>
                                    <ul className="social-icons">
                                        <li>
                                            <a href="#0" className="">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0" className="">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/@NEOIN-DALOCAL" className="">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://sg.linkedin.com/company/neoinco">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="widget widget-categories">
                                    <h5 className="title">categories</h5>
                                    <ul>
                                        <li>
                                            <a href="#0">
                                                <span>Apps Feature</span><span>50</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <span>Technology</span><span>43</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <span>Marketing </span><span>34</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <span>Inspiration</span><span>63</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <span>Apple Store  </span><span>11</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <span>Branding</span><span>30</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">
                                                <span>Lifestyle </span><span>55</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                                {/* <div className="widget widget-tags">
                                    <h5 className="title">featured tags</h5>
                                    <ul>
                                        <li>
                                            <a href="#0">IOS APPS</a>
                                        </li>
                                        <li>
                                            <a href="#0">SOFTWARE</a>
                                        </li>
                                        <li>
                                            <a href="#0">APPS
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#0">ANDROID</a>
                                        </li>
                                        <li>
                                            <a href="#0" className="active">UX DESIGN</a>
                                        </li>
                                    </ul>
                                </div> */}
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Blog Section Ends Here =============--> */}
        </>
    )
}

export default Addreview