import React, { useState } from "react";
import pageheader from "../assets/css/img/page-header.png";
// import pageheader2 from "../assets/images/page-header.png";
// import manpage from "../assets/images/contact/man.png";
import contactImg from "../assets/images/contact/contact us.png"
import contact1 from "../assets/images/contact/contact1.png";
import contact2 from "../assets/images/contact/contact2.png";
import contact3 from "../assets/images/contact/contact3.png";
import earth from "../assets/images/contact/earth.png";
import { useScrollToTop } from "./UseScrollToTop";
import axios from 'axios';
import { toast } from 'react-toastify';

import MapIntegration from "./MapIntegration";


function Contact(){
    const apiKey = process.env.REACT_APP_API_KEY; // actual API key
  const headers = {
    'x-api-key': apiKey,
    'Content-Type': 'application/json'
  };

    useScrollToTop();

    const [selectedCountry, setSelectedCountry] = useState('');

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    // const [isChecked, setIsChecked] = useState(false);
    
    // const handleCheckboxChange = () => {
    //     setIsChecked(!isChecked); 
    // };

    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        companyName: '',
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmit =async (event) => {
        event.preventDefault();
        console.log(formData);
    
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/mail/send-contact-email`,
            formData,
            {
              headers: headers
            }
          )
            .then(response => {
                console.log(response.data);
                // You can show a success message to the user here
                toast.success("Form submitted successfully!");
                setFormData({
                    companyName: '',
                    name: '',
                    phone: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            })
            .catch(error => {
                console.error('Error sending form data:', error);
                // Handle error, show error message to the user
            });
    };
    

    return(
    <>
    <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
    <div className="overlay"></div>
    {/* <!--============= ScrollToTop Section Ends Here =============-->


 


    <!--============= Header Section Ends Here =============--> */}
    <section className="Featurebanner page-header single-header bg_img_pg_header oh">
        <div className="bottom-shape d-none d-md-block">
            <img src={pageheader} alt="css"/>
        </div>
    </section>
    {/* <!--============= Header Section Ends Here =============-->


    
    <!--============= Contact Section Starts Here =============--> */}
    <section className="contact-section padding-top padding-bottom">
        <div className="container">
            <div className="section-header mw-100 cl-white">
                <h2 className="title">Contact NEOIN</h2>
                <p>Whether you're looking for a demo, have a support question, or a commercial query, get in touch.</p>
            </div>
            <div className="row justify-content-center justify-content-lg-between">
                <div className="col-lg-7">
                    <div className="contact-wrapper">
                        <h4 className="title text-center mb-30">Get in Touch</h4>
                        <form onSubmit={handleSubmit} className="contact-form" id="contact_form_submit">
                                    <div className="form-group">
                                        <label htmlFor="companyName">Your Company Name</label>
                                        <input type="text" name="companyName" id="companyName" placeholder="Enter Your Company Name" required onChange={handleInputChange} value={formData.companyName} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Your Full Name</label>
                                        <input type="text" name="name" id="name" placeholder="Enter Your Full Name" required onChange={handleInputChange} value={formData.name}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone Number</label>
                                        <input type="text" name="phone" id="phone" placeholder="Enter Your Phone Number" required onChange={handleInputChange} value={formData.phone}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Your Email </label>
                                        <input type="text" name="email" id="email" placeholder="Enter Your Email" required onChange={handleInputChange} value={formData.email} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subject">Your Subject</label>
                                        <input type="text" name="subject" id="subject" placeholder="Enter Your Subject" required onChange={handleInputChange} value={formData.subject} />
                                    </div>
                                    <div className="form-group mb-0">
                                        <label htmlFor="message">Your Message </label>
                                        <textarea id="message" name="message" placeholder="Enter Your Message" required onChange={handleInputChange} value={formData.message}></textarea>
                                        <div className="form-check">
                                            <input type="checkbox" id="check" name="check" checked={isChecked} onChange={handleCheckboxChange} required /><label className="contactpage" htmlFor="check">I agree to receive emails, newsletters and promotional messages</label>
                                        </div>
                                    </div>

                                    <span id="msg"></span>

                                    <div className="form-group">
                                        <button type="submit" className='inline-button banner-btn-new ' name="contact_submit" id="banner-btn">Send Message</button>
                                    </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="contact-content">
                        <div className="man d-lg-block d-none">
                            <img src={contactImg} alt="bg" className="contact-img"/>
                        </div>
                        <div className="section-header left-style">
                            <h3 className="title">Have questions?</h3>
                            <p>Have questions about payments or price 
                                plans? We have answers!</p>
                            <a href="/faq">Read F.A.Q <i className="fas fa-angle-right"></i></a>
                        </div>
                        <div className="contact-area">
                            <div className="contact-item">
                                <div className="contact-thumb">
                                    <img src={contact1} alt="contact"/>
                                </div>
                                <div className="contact-contact">
                                    <h5 className="subtitle">Email Us</h5>
                                    <a href="Mailto:admin@neoin.co">admin@neoin.co</a>
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className="contact-thumb">
                                    <img src={contact2} alt="contact"/>
                                </div>
                                <div className="contact-contact">
                                    <h5 className="subtitle">Call Us</h5>
                                    <a href="Tel:+6580275311">+65 80275311</a><br />
                                    {/* <a href="Tel:+917506665828">+91 75066 65828</a> */}
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className="contact-thumb">
                                    <img src={contact3} alt="contact"/>
                                </div>
                                <div className="contact-contact">
                                    <h5 className="subtitle">Visit Us</h5>
                                    <a href="#country-addresses">Singapore</a>
                                    <br />
                                    <a href="#country-addresses">India</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Contact Section Ends Here =============-->


    <!--============= Map Section Starts Here =============--> */}
    <div className="map-section padding-bottom" id="countrySelect" onChange={handleCountryChange} value={selectedCountry}>
        <div className="container">
            <div className="section-header">
                <div className="thumb">
                    <img src={earth} alt="earth"/>
                </div>
                <h3 className="title">We Are Easy To Find</h3>

                <select id="countrySelect" className="form-select rounded btn-primary custom-select">
                    <option value="" selected>Select Country</option>
                    <option value="Singapore">Singapore</option>
                    <option value="India">India</option>
                    <i className="fas fa-chevron-down"></i>
                </select>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="maps-wrapper">
                        <div className="maps">
                        <MapIntegration selectedCountry={selectedCountry} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!--============= Map Section Ends Here =============-->


    <!--============= Do Section Starts Here =============--> */}
    {/* <div className="do-section padding-bottom padding-top-2">
        <div className="container">
            <div className="row mb-30-none justify-content-center">
                <div className="col-md-6">
                    <div className="do-item cl-white">
                        <h3 className="title">About Us</h3>
                        <p>Find out who we are and what we do!</p>
                        <a href="/about"><i className="fas fa-angle-left"></i></a>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="do-item cl-white">
                        <h3 className="title">Partners</h3>
                        <p>Become a NEOIN
                            Solutions Partner!</p>
                        <a href="/partners"><i className="fas fa-angle-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!--============= Do Section Ends Here =============--> */}
    



        
        
        </>


    )


}

export default Contact