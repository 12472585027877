import React from 'react'
import client01 from "../assets/images/review/client01.png"
import pageHeader from "../assets/css/img/page-header.png"
// import pageHeader1 from "../assets/images/page-header.png"
import client02 from "../assets/images/review/client02.png"
import client03 from "../assets/images/review/client03.png"
import client04 from "../assets/images/review/client04.png"
import client05 from "../assets/images/review/client05.png"
import community1 from "../assets/images/dummies/D7.png";
import community2 from "../assets/images/dummies/D1.png";
import community3 from "../assets/images/dummies/D2.png";
import community4 from "../assets/images/dummies/D3.png";
import community5 from "../assets/images/dummies/D13.png";
import community6 from "../assets/images/dummies/D16.png";
import community7 from "../assets/images/dummies/D17.png";
import community8 from "../assets/images/dummies/D20.png";
import community9 from "../assets/images/dummies/D4.png";
import community10 from "../assets/images/dummies/D9.png";
import community11 from "../assets/images/dummies/D5.png";
import community12 from "../assets/images/dummies/D6.png";
import community13 from "../assets/images/dummies/D18.png";
import community14 from "../assets/images/dummies/D21.png";
import community15 from "../assets/images/dummies/D15.png";
import community16 from "../assets/images/dummies/D16.png";
import community17 from "../assets/images/dummies/D19.png";
import community18 from "../assets/images/dummies/D10.png";
import community19 from "../assets/images/dummies/D12.png";
import community20 from "../assets/images/dummies/D11.png";
import community21 from "../assets/images/dummies/D8.png";
import community22 from "../assets/images/dummies/D13.png"

import { useScrollToTop } from "./UseScrollToTop";
// import axios from 'axios';

function Testimonials() {

    useScrollToTop();
    // const [latestContacts, setLatestContacts] = useState([]);

    // useEffect(() => {
    //     const fetchLatestContacts = async () => {
    //         try {
    //             const response = await axios.get('http://localhost:5000/contact/latest');
    //             // Extracting only fullName, email, and message attributes from each contact submission
    //             const extractedData = response.data.map(contact => ({
    //                 fullName: contact.fullName,
    //                 email: contact.email,
    //                 message: contact.message
    //             }));
    //             setLatestContacts(extractedData);
    //         } catch (error) {
    //             console.error('Error fetching latest contacts:', error);
    //         }
    //     };

    //     fetchLatestContacts();
    // }, []);

    return (
        <div>
            {/*============= ScrollToTop Section Starts Here =============/*/}
            {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
            <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
            {/*============= ScrollToTop Section Ends Here =============*/}





            {/*============= Header Section Ends Here =============*/}
            <section className="page-header bg_img_pg_header">
                {/* {console.log(latestContacts)} */}
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageHeader} alt="css" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Testimonials</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                Testimonials
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/*============= Header Section Ends Here =============*/}



            {/*============= Partner Section Starts Here =============*/}
            <section className="partner-section padding-top padding-bottom oh">
                <div className="container">

                    <div className="row justify-content-center mb-30-none">


                    {/* {latestContacts.map((contact, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-10" key={index}>
                            <div className="review-item">
                                <div className="review-content">
                                    <p>{contact.message}</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client02} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">{contact.fullName}</a></h6>
                                            <span>{contact.email}</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         ))} */}

                        <div className="col-lg-4 col-md-6 col-sm-10"> 
                            <div className="review-item">
                                <div className="review-content">
                                    <p>Success of @NEOIN - app crossed 16 million downloads with more than 4 million active users. #congratulations</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client01} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Myron </a></h6>
                                            <span>@myron</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>The new version of @NEOIN is really fantastic. My favourite manage tool; especially with its excellent #collaboration features.</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client02} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Lynette </a></h6>
                                            <span>@lynette</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>I can't stop telling people about how good @NEOIN app! Best
                                        purchase ever.</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client03} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Marco  </a></h6>
                                            <span>@marco </span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>Success of @NEOIN - app crossed 16 million downloads with more than 4 million active users. #congratulations</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client04} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Lewis </a></h6>
                                            <span>@lewis</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>Just buy a license for @NEOIN pro! Its been super great to manage my new #website! Thanks a lot!</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client05} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Wilbert </a></h6>
                                            <span>@wilbert</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>@NEOIN Would you please add vector icons to your app? Thanks in advance)</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client01} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Myron </a></h6>
                                            <span>@myron</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>I can't stop telling people about how good @NEOIN app! Best
                                        purchase ever.</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client02} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Lynette  </a></h6>
                                            <span>@lynette </span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>NEOIN is awesome! I use it a lot to manage my startup even when I’m not at the office.</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client03} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Marco  </a></h6>
                                            <span>@marco </span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>Thanks to NEOIN I have increased the quality of customer care at my company. Thank you for this app!</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client04} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Lewis </a></h6>
                                            <span>@lewis</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>I discovered #mosto_app a year ago and got instantly attracted by its numerous wonderful features that a lot easier.</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client05} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Wilbert  </a></h6>
                                            <span>@wilbert </span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>Thank you for developing such a great app that perfectly satisfies my time management needs!</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client02} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Lynette  </a></h6>
                                            <span>@lynette </span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10">
                            <div className="review-item">
                                <div className="review-content">
                                    <p>This is the best app I have found.Highly addicting! I'm sure I'll be using this for the rest of my little ones life.</p>
                                </div>
                                <div className="review-thumb">
                                    <div className="review-author-thumb">
                                        <a href="#0"><img src={client01} alt="review" /></a>
                                    </div>
                                    <div className="review-author-content">
                                        <div className="left">
                                            <h6 className="title"><a href="#0">Myron </a></h6>
                                            <span>@myron</span>
                                        </div>
                                        <div className="icon">
                                            <a href="#0"><i className="fab fa-twitter"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='banner-content-9'>
                        <div className="banner-button-group"><a href="/addreview" className="button-4" id="banner-btn-flex">Add Review</a></div>
                    </div>
                </div>
            </section>
            {/*============= Partner Section Ends Here =============*/}


            {/*============= Comunity Section Starts Here =============*/}
            <section className="comunity-section padding-top padding-bottom oh pos-rel">
                <div className="comunity-bg bg_img_mosto_client"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-header cl-white">
                                <h5 className="cate">Join the community</h5>
                                <h2 className="title">Over 1,000,000 users</h2>
                                <p>Join and share in a community of like-minded members</p>
                            </div>
                        </div>
                    </div>
                    <div className="comunity-wrapper">
                        <div className="buttons"><a href="#0" className="button-3 border-no-outline">Join the community <i className="flaticon-right"></i></a></div>
                        <div className="comunity-area">
                            <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community22} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                        </div>
                        <div className="comunity-area two">
                            <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                        </div>
                        <div className="comunity-area three">
                            <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community22} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                            <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                        </div>
                    </div>
                </div>
            </section>
            {/*============= Comunity Section Ends Here =============*/}
        </div>
    )
}

export default Testimonials
