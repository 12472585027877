import React from 'react'
// import comingsoon1 from "../assets/images/coming-soon.jpg"

import { useScrollToTop } from "./UseScrollToTop";

function Comingsoon() {
    useScrollToTop();
  return (
    <div>
       {/*============= ScrollToTop Section Starts Here =============}*/}
    {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
    <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
    <div className="overlay"></div>
    {/*============= ScrollToTop Section Ends Here =============*/}


    {/*============= Coming Section Starts Here =============*/}
    <div className="coming-soon bg_img_cmgSoon">
        <div className="container">
            <div className="account-title text-center">
                <a href="/" className="back-home"><i className="fas fa-angle-left"></i><span>Back <span className="d-none d-sm-inline-block">To NEOIN</span></span></a>
            </div>
            <div className="coming-wrapper">
                <h1 className="title">COMING SOON</h1>
                {/* <ul className="countdown">
                    <li>
                        <h3 className="c-title"><span className="days">00</span></h3>
                        <p className="days_text">days</p>
                    </li>
                    <li>
                        <h3 className="c-title"><span className="hours">00</span></h3>
                        <p className="hours_text">hours</p>
                    </li>
                    <li>
                        <h3 className="c-title"><span className="minutes">00</span></h3>
                        <p className="minu_text">minutes</p>
                    </li>
                    <li>
                        <h3 className="c-title"><span className="seconds">00</span></h3>
                        <p className="seco_text">seconds</p>
                    </li>
                </ul> */}
                
            </div>
        </div>
    </div>
    {/*============ Coming Section Ends Here =============*/}
    </div>
  )
}

export default Comingsoon
