import React from 'react'
import access1 from "../assets/images/icon/access1.png"
import access2 from "../assets/images/icon/access2.png"
import access3 from "../assets/images/icon/access3.png"
import access4 from "../assets/images/icon/access4.png"


const ProductLogin = () => {
  return (
    <>
        <section className="to-access-section padding-top padding-bottom-2 bg_img_to_access mb-lg-5" id="feature">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">Amazing features to convince you</h5>
                <h2 className="title">NEOIN Products</h2>
                <p>In the process of making a app, the satisfaction of users is the most 
                    important and the focus is on usability and completeness</p>
            </div>
            <div className="row mb-30 justify-content-center">
                <div className="col-lg-3 col-sm-6">
                    <div className="to-access-item">
                        <div className="to-access-thumb">
                            <span className="anime"></span>
                            <div className="thumb">
                                <img src={access1} alt="access" />
                            </div>
                        </div>
                        <h5 className="title">Enterprise</h5>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="to-access-item active">
                        <div className="to-access-thumb">
                            <span className="anime"></span>
                            <div className="thumb">
                                <img src={access2} alt="access" />
                            </div>
                        </div>
                        <h5 className="title">Individuals</h5>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="to-access-item">
                        <div className="to-access-thumb">
                            <span className="anime"></span>
                            <div className="thumb">
                                <img src={access3} alt="access" />
                            </div>
                        </div>
                        <h5 className="title">Students</h5>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="to-access-item">
                        <div className="to-access-thumb">
                            <span className="anime"></span>
                            <div className="thumb">
                                <img src={access4} alt="access" />
                            </div>
                        </div>
                        <h5 className="title">Gen Z</h5>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <a href="#0" className="get-button">Explore Products</a>
            </div>
        </div>
    </section>

    </>
  )
}

export default ProductLogin