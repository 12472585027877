import React from 'react'

// import pageheader from "../assets/images/page-header.png"
import Aboutimage from "../assets/images/about/about us.png"
import counterimage from "../assets/images/counter/counter1.png"
import counterimage2 from "../assets/images/counter/counter2.png"
import counterimage3 from "../assets/images/counter/counter3.png"
// import clientimage from "../assets/images/bg/client-bg.jpg"
import workshapeimage from "../assets/css/img/work-shape.png"
import howimage from "../assets/images/about/Identity Verification.png"
import howimage5 from "../assets/images/about/Identity Management.png"
import howimage6 from "../assets/images/about/Access Control Management.png"
import howimage7 from "../assets/images/about/Data Security and Privacy.png"
import Ashish from "../assets/images/team/Ashish.png"
import Ravi from "../assets/images/team/Ravi.png"
import Swetha from "../assets/images/team/Shwetha.png"
import Sangeetha from "../assets/images/team/Sangeetha.png"
import Manimegalai from "../assets/images/team/Mani.png"
import Joe from "../assets/images/team/Jyotimaya Mohanty.png"
import Chetaa from "../assets/images/team/Dileesh.png"
import Anna from "../assets/images/team/chandrashekhar.png"
// import clientleft from "../assets/images/client/left.png"
// import clientright from "../assets/images/client/right.png"
// import client1 from "../assets/images/dummies/D1.png"
// import client2 from "../assets/images/dummies/D5.png"
import sponsor1 from "../assets/images/banner/banner16/01.png"
import sponsor2 from "../assets/images/banner/banner16/02.png"
import sponsor3 from "../assets/images/banner/banner16/03.png"
import sponsor4 from "../assets/images/banner/banner16/04.png"
import sponsor5 from "../assets/images/banner/banner16/05.png"
import sponsor6 from "../assets/images/banner/banner16/06.png"
import sponsor7 from "../assets/images/banner/banner16/02.png"
import pageheader1 from "../assets/css/img/page-header.png"

import { useScrollToTop } from "./UseScrollToTop";
import Counter from './Counter'
import Review from './Review'
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';


function Aboutus() {
useScrollToTop();
  return (
    <div>

      {/*-============= Header Section Ends Here =============*/}
    <section className="page-header bg_img_pg_header">
        <div className="bottom-shape d-none d-md-block">
            <img src={pageheader1} alt='page Header'/>
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="title mb-3">About Us</h2>
                <ul className="breadcrumb">
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        About Us
                    </li>
                </ul>
            </div>
        </div>
    </section>
    {/*============= Header Section Ends Here =============*/}

    {/*============= About Section Starts Here =============*/}
    <section className="about-section oh">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-5">
                    <div className="about-thumb rtl pr-xl-15">
                        <img src={Aboutimage} alt="about"/>
                    </div>
                </div>
                <div className="col-xl-7 pl-xl-0">
                    <div className="about-content mb-gap">
                        <div className="section-header left-style">
                            <h5 className="cate">A Few Words About Us</h5>
                            <h2 className="title">Who we are</h2>
                            <p className='text-left'><span className="first-word">NEOIN,</span> a digital ID technology with a verified data engine, enhances customer trust by providing digital identity, identity management, access control, data security, and privacy. We offer personalized data analytics and insights, which provide reassurance about the authenticity and security of online engagements across the digital ecosystem by implementing robust authentication mechanisms that can protect sensitive data and mitigate the risk of identity theft or fraud.
                </p>
                        </div>
                        <div className="counter-area-5">
                            <div className="counter-item-5">
                                <div className="counter-thumb">
                                    <img src={counterimage} alt="counter"/>
                                </div>
                                <div className="counter-content">
                                    <h3 className="title"><Counter end={5}>k</Counter></h3>
                                    <p>Total User</p>
                                </div>
                            </div>
                            <div className="counter-item-5">
                                <div className="counter-thumb">
                                    <img src={counterimage2} alt="counter"/>
                                </div>
                                <div className="counter-content">
                                    <h3 className="title"><Counter end={12}>+</Counter></h3>
                                    <p>Countries</p>
                                </div>
                            </div>
                            <div className="counter-item-5">
                                <div className="counter-thumb">
                                    <img src={counterimage3} alt="counter"/>
                                </div>
                                <div className="counter-content">
                                    <h3 className="title"><Counter end={95}>%</Counter></h3>
                                    <p>Satisfaction</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/*============= About Section Ends Here =============*/}


    {/*============= How Section Starts Here =============*/}
    <section className="how-section padding-top-2 pos-rel pb-xl-0 about-us-cards oh" id="how">
        <div className="bg-xl-100 bg_img_client"></div>
            <div className="top-shape d-none d-lg-block">
                <img src={workshapeimage} alt="css"/></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <div className="client-header">
                        <div className="section-header left-style cl-white mt-4 mb-olpo">
                            <h5 className="cate">Empowering Trust Through Verified Data</h5>
                            <h2 className="title">Let's See How It Works</h2>
                            {/* <p>It's easier than you think.Follow the simple easy steps</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-30-none mob-pb-40">
                <div className="col-xl-3 col-sm-6">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={howimage} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Identity Verification</h4>
                            <p className='text-center'>Verifying the authenticity and validity of digital identities through various authentication methods, such as passwords, biometrics, cryptographic keys, or multi-factor authentication.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={howimage5} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Identity Management</h4>
                            <p className='text-center'>Managing the lifecycle of digital identities, including registration, provisioning, updating, and deprovisioning, while ensuring compliance with privacy regulations and security standards.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={howimage6} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Access Control Management</h4>
                            <p className='text-center'>Enforcing rigorously strict access control policies to regulate the permissions and privileges associated with digital identities, thereby effectively controlling access to resources and services.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="how-item-3">
                        <div className="how-thumb">
                            <img src={howimage7} alt="how"/>
                        </div>
                        <div className="how-content">
                            <h4 className="title">Data Security and Privacy</h4>
                            <p className='text-center'>Enforcing security measures to protect identity data from intrusion, including information security management with privacy regulations like GDPR or CCPA.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/*============= How Section Ends Here =============*/}


    {/*============= Team Section Starts Here =============*/}
    <section className="team-section padding-top-2 mob-pt-40 oh">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">Meet Our Most Valued</h5>
                <h2 className="title">Founders</h2>
                <p>
                Our team of creative programmers, marketing experts, and members are dedicated to doing what we love.
                </p>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Joe} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Jyotimaya Mohanty
                            </h4>
                            <span className="info">CEO</span>
                            <a href="https://www.linkedin.com/in/Jyotimaya" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Ravi} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Ravi Lalchand chhatpar
                            </h4>
                            <span className="info">CFO</span>
                            <a href="https://www.linkedin.com/in/ravi-lalchand-chhatpar-7025a85" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={team3} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Steven Mann
                            </h4>
                            <span className="info">iOS Developer</span>
                            <a href="#0" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
    {/*============= Team Section Ends Here =============*/}


    {/*============= Team Section2 Starts Here =============*/}
    <section className="team-section padding-top-2 oh">
        <div className="container">
            <div className="section-header">
                <h2 className="title">Founding Team</h2>
                <h2 className="title">Members</h2>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Chetaa} alt="Chetaa"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Dileesh
                            </h4>
                            <span className="info">Senior UI Designer</span>
                            <a href="https://www.linkedin.com/in/dileeshdt" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Anna} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Chandrashekhara
                            </h4>
                            <span className="info">Senior Software Developer</span>
                            <a href="https://in.linkedin.com/in/chandrashekhara-angadi-66364a21b" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Sangeetha} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Sangeetha
                            </h4>
                            <span className="info">UI/UX Designer</span>
                            <a href="https://www.linkedin.com/in/sangeetha-m-s-602ab3225" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Ashish} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Ashish Dash
                            </h4>
                            <span className="info">Junior Software Developer</span>
                            <a href="https://in.linkedin.com/in/ashishdash08" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Manimegalai} alt="team"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Manimegalai
                            </h4>
                            <span className="info">Software Developer Intern</span>
                            <a href="https://www.linkedin.com/in/manimegalaic/" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="team-item">
                        <div className="team-thumb">
                            <img src={Swetha} alt="Swetha"/>
                        </div>
                        <div className="team-content">
                            <h4 className="title">
                                Swetha
                            </h4>
                            <span className="info">Software Developer Intern</span>
                            <a href="https://www.linkedin.com/in/swetha-susindran-8b16b81bb " className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    {/*============= Team Section2 Ends Here =============*/}


    {/*============= History Section Starts Here =============*/}
     {/* <section className="history-section padding-top-2">
         <div className="container">
             <div className="section-header">
                 <h5 className="cate">Our history </h5>
                 <h2 className="title">How We Became Successful</h2>
                 <p>We always challenge the openness of higher possibilities 
                    without defining the limits of our teams and products. We test our hypotheses about new attempts and enjoy making our way around the world.</p>
                 <p></p>
             </div>
             <div className="history-slider owl-theme owl-carousel">
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2014</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2015</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2016</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2017</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2018</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2019</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2021</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2014</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2015</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2016</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2017</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2018</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2019</span>
                     </div>
                 </div>
                 <div className="history-item">
                     <div className="history-content">
                        <div className="content">
                            <h6 className="title">We Are Growing</h6>
                         <p>emeritis hibridas hic dignus de fortis, altus orexisstoria</p>
                        </div>
                     </div>
                     <div className="history-thumb"><div className="anime-item-2"></div>
                         <span>2021</span>
                     </div>
                 </div>
             </div>
         </div>
     </section> */}
     {/*============= History Section Ends Here =============*/}

     {/*============= Testimonial Section Starts Here =============*/}
     <Review />
     {/* <section className="testimonial-section padding-top-2">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">Testimonials</h5>
                <h2 className="title">5000+ happy clients all around the world</h2>
            </div>
            <div className="testimonial-wrapper">
                <a href="#0" className="testi-next trigger">
                    <img src={clientleft} alt="client"/>
                </a>
                <a href="#0" className="testi-prev trigger">
                    <img src={clientright} alt="client"/>
                </a>
                <div className="testimonial-area testimonial-slider owl-carousel owl-theme">
                    <div className="testimonial-item">
                        <div className="testimonial-thumb">
                            <div className="thumb">
                                <img src={client1} alt="client"/>
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>
                                Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                            </p>
                            <h5 className="title"><a href="#0">Bela Bose</a></h5>
                        </div>
                    </div>
                    <div className="testimonial-item">
                        <div className="testimonial-thumb">
                            <div className="thumb">
                                <img src={client2} alt="client"/>
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>
                                Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                            </p>
                            <h5 className="title"><a href="#0">Raihan Rafuj</a></h5>
                        </div>
                    </div>
                </div>
                <div className="button-area">
                    <a href="#0" className="button-2" id='btn-no-bg-flex'>Leave a review <i className="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </section> */}
    
    {/*============= Testimonial Section Ends Here =============*/} 


    {/*============= Sponsor Section Section Here =============*/}
    <section className="sponsor-section padding-top-2 padding-bottom-2 mob-pt-40 mob-pb-40">
        <div className="container">
            <div className="section-header mw-100">
                <h5 className="cate">Used by over 1,000,000 people worldwide</h5>
                <h2 className="title">Companies that trust us</h2>
            </div>
            {/* <div className="sponsor-slider-4 owl-theme owl-carousel"> */}
            <OwlCarousel className="sponsor-slider-4"
                            loop
                            margin={10}
                            nav={false}
                            dots={false}
                            autoplay
                            autoplayTimeout={1500}
                            autoplayHoverPause
                            responsive={{
                                0: { items: 6 }, // Show 1 item on small screens
                                768: { items: 6}, // Show 1 item on medium screens
                                1200: { items: 6 } // Show 1 item on large screens
                          }}>
                <div className="sponsor-thumb">
                    <img src={sponsor1} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor2} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor3} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor4} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor5} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor6} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor7} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor1} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor2} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor3} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor4} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor5} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor6} alt="sponsor"/>
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor7} alt="sponsor"/>
                </div>
            </OwlCarousel>
        </div>
    </section>
    {/*============= Sponsor Section Ends Here =============*/}
    
    </div>
  )
}

export default Aboutus
