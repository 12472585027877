import React, { useState } from "react";
// import pageheader from "../assets/images/page-header.png";
import pageheader1 from "../assets/css/img/page-header.png";
// import faq from "../assets/images/faq/faq-menu.png";
import video from "../assets/images/faq/video.png";
// import client from "../assets/images/client/mosto-client.jpg";
import help from "../assets/images/feature/help.png"
import help1 from "../assets/images/feature/help1.png"
import help2 from "../assets/images/feature/help2.png"
import { useScrollToTop } from "./UseScrollToTop";

function Faq() {

    useScrollToTop();

    const [faqStates, setFaqStates] = useState({});

    const toggleFaq = (id) => {
        setFaqStates(prevStates => ({
            ...prevStates,
            [id]: !prevStates[id] // Toggle the state for the clicked FAQ item
        }));
    };

    const faqItems = [
        {
            category: "Getting Started",
            catId: "get",
            faqs: [
                {
                    id: 1,
                    question: "Who is NEOIN for?",
                    answer: "Neoin is for professionals, businesses, associations, banks, students, and individuals seeking efficient networking and communication solutions."
                },
                {
                    id: 2,
                    question: "How do I get into NEOIN?",
                    answer: "Getting started with Neoin is easy! Simply sign up for an account on our website and start exploring the features tailored to your needs."
                },
                {
                    id: 3,
                    question: "What features does NEOIN have?",
                    answer: "Neoin offers a range of features including digital business cards, NFC technology, AI-enabled scanning, virtual backgrounds, and more, designed to enhance your networking experience."
                }
            ]
        },
        {
            category: "How NEOIN works",
            catId: "how",
            faqs: [
                {
                    id: 4,
                    question: "What's special about NEOIN?",
                    answer: "Neoin stands out for its innovative features like AI-enabled scanning, virtual backgrounds, and secure data sharing capabilities, providing a unique and efficient networking experience."
                },
                {
                    id: 5,
                    question: "What problem does NEOIN solve?",
                    answer: "A successful marketing plan relies heavily on the pulling-power of advertising copy. Writing result-oriented ad copy is difficult, as it must appeal to, entice, and convince consumers to take action. There is no magic formula to write perfect ad copy."
                },
                {
                    id: 6,
                    question: "How does NEOIN differ from others?",
                    answer: "Unlike other networking platforms, Neoin combines advanced features like biometrics, NFC technology, and virtual backgrounds with intuitive design and user-friendly interface, setting it apart from the rest."
                }
            ]
        },
        {
            category: "Pricing Plans",
            catId: "pricing",
            faqs: [
                {
                    id: 7,
                    question: "How much does NEOIN Plans costs?",
                    answer: "Neoin offers flexible pricing plans to suit your needs, with options ranging from free basic plans to premium subscriptions with advanced features. Visit our pricing page for more details."
                },
                {
                    id: 8,
                    question: "Can I cancel my account plan at any time?",
                    answer: "Yes, you can cancel your Neoin subscription at any time. Simply navigate to your account settings and follow the instructions to cancel your plan."
                }
            ]
        },
        {
            category: "Security",
            catId: "security",
            faqs: [
                {
                    id: 9,
                    question: "How do I switch to a different price plan?",
                    answer: "To switch to a different Neoin price plan, log in to your account and visit the billing section to select your desired plan and follow the prompts to complete the switch."
                },
                {
                    id: 10,
                    question: "How can I get my money back?",
                    answer: "If you're not satisfied with your Neoin subscription, we offer a money-back guarantee within a specified timeframe. Please refer to our refund policy for more information."
                },
                {
                    id: 11,
                    question: "What about the security of my payment?",
                    answer: "Your payment information is secure with Neoin. We utilize encryption and adhere to strict security protocols to ensure the safety and confidentiality of your payment details."
                }
            ]
        },
        {
            category: "General",
            catId: "general",
            faqs: [
                {
                    id: 12,
                    question: "Is Neoin available on mobile devices?",
                    answer: "Yes, Neoin is accessible on both desktop and mobile devices through our website or mobile app, allowing you to network and communicate on the go."
                },
                {
                    id: 13,
                    question: "Can I use Neoin for personal and professional purposes?",
                    answer: "Absolutely! Neoin caters to both personal and professional users, providing versatile features for networking, communication, and data sharing in various contexts."
                }
            ]
        },
        {
            category: "Features",
            catId: "features",
            faqs: [
                {
                    id: 14,
                    question: "Does Neoin offer customization options for digital business cards?",
                    answer: "Yes, Neoin allows users to customize their digital business cards with personal branding, contact information, logos, and other details to reflect their unique identity and style."
                },
                {
                    id: 15,
                    question: "What is the PPF score feature in Neoin?",
                    answer: "The PPF (Personal Profile Factor) score is a unique feature in Neoin that provides personalized recommendations and insights to help users optimize their profiles for networking and financial support."
                }
            ]
        },
        {
            category: "Support and Assistance",
            catId: "support",
            faqs: [
                {
                    id: 16,
                    question: "How can I get help or support with using Neoin?",
                    answer: "Neoin offers comprehensive customer support through various channels, including email, live chat, and online documentation. Our support team is ready to assist you with any questions or issues you may have."
                },
                {
                    id: 17,
                    question: "Are there tutorials or resources available to help me get started with Neoin?",
                    answer: "Yes, Neoin provides tutorials, user guides, and other resources to help you get started and make the most of our platform's features. Visit our help center or documentation for more information."
                }
            ]
        }
    ]

    return (

        <>
            {/* <!--============= Header Section Ends Here =============--> */}
            <section className="page-header bg_img_pg_header">
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageheader1} alt="css" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Frequently asked questions</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                FAQs
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <!--============= Header Section Ends Here =============-->


    
    <!--============= Faqs Section Starts Here =============--> */}
            <section className="faq-section padding-top-2 padding-bottom-2">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-4">
                            <aside className="sticky-menu">
                                <div className="faq-menu bg_img_faq_menu mb-30">
                                    <ul id="faq-menu">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#get">Getting Started</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#how">How NEOIN works</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#pricing">Pricing Plans</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#security">Security</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#general">General</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#features">Features</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#support">Support and Assistance</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="faq-video">
                                    <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-area popup">
                                        <img src={video} alt="faq" />
                                        <div className="video-button-2">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <i className="flaticon-play"></i>
                                        </div>
                                    </a>
                                    <h5 className="title">Watch Video Tour</h5>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-8 col-xl-7">
                            <article className="mt-70 mt-lg-0">
                                {faqItems.map((category) => (
                                    <div key={category.category} className="faq--wrapper" id={category.catId}>
                                        <h3 className="main-title">{category.category}</h3>
                                        <div className="faq--area">
                                            {category.faqs.map((faq) => (
                                                <div key={faq.id} className={`faq--item${faqStates[faq.id] ? ' open' : ''}`} onClick={() => toggleFaq(faq.id)}>
                                                    <div className="faq-title">
                                                        <h6 className="title">{faq.question}</h6>
                                                        <span className="icon"></span>
                                                    </div>
                                                    <div className={`faq-content`}>
                                                        <p>{faq.answer}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}

                            </article>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Faqs Section Ends Here =============-->


    <!--============= Comunity Section Starts Here =============--> */}
            <section className="comunity-section padding-top padding-bottom oh pos-rel">
                <div className="comunity-bg bg_img_mosto_client"></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="section-header cl-white">
                                <h5 className="cate">Still need help?</h5>
                                <h2 className="title">Stop Wasting Time</h2>
                                <p>Whether you’re stuck or just want some tips on where to start, any problem, hit up our experts anytime.</p>
                            </div>
                        </div>
                    </div>
                    <div className="comunity-asking">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-6 rtl d-none d-lg-block">
                                <img src={help} alt="feature" />
                            </div>
                            <div className="col-lg-6 col-xl-5 mb-30-none">
                                <div className="help-item">
                                    <div className="help-thumb">
                                        <img src={help1} alt="feature" />
                                    </div>
                                    <div className="help-content">
                                        {/* <h5 className="title">live chat</h5> */}
                                        <a href='/contact'><p><b>Get Connected With Our Team For More Details.</b></p></a>
                                    </div>
                                </div>
                                {/* <div className="help-item">
                            <div className="help-thumb">
                            <img src={help2} alt="feature"/>
                            </div>
                            <div className="help-content">
                                <h5 className="title">Send Ticket</h5>
                                <p>Anything I can help you with?</p>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--============= Comunity Section Ends Here =============--> */}


        </>


    )

}

export default Faq