
import React, { useState, useEffect } from 'react';
// import "../assets/css/main.css";
import featureImage1 from "../assets/images/feature/Consent.svg";
import featureImage2 from "../assets/images/feature/Connect.svg";
import featureImage3 from "../assets/images/feature/Communicate.svg";
import featureImage4 from "../assets/images/feature/Engage.svg";
import left from "../assets/images/feature/back.svg";
import right from "../assets/images/feature/next.svg";
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const FeatureItem = ({ title, description, image }) => (
  <div className="feature-item-2 mob-pb-40">
    <div className="feature-thumb">
      <img src={image} alt="feature" />
    </div>
    <div className="feature-content">
      <h4 className="title">{title}</h4>
      <p className="text-center">{description}</p>
    </div>
  </div>
);

const Carousel = ({ features }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  useEffect(() => {
    const leftIcon = document.querySelector(".carousel-control.left img");
    leftIcon.style.display = "none";
  }, []);

  useEffect(() => {
    if (currentSlide === 0) {
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(true);
      const leftIcon = document.querySelector(".carousel-control.left img");
      leftIcon.style.display = "block";  
    }
  }, [currentSlide]);

  const handleRightClick = () => {
    setCurrentSlide(prevSlide => (prevSlide === features.length - 1 ? 0 : prevSlide + 1));
  };

  const handleLeftClick = () => {
    setCurrentSlide(prevSlide => (prevSlide === 0 ? features.length - 1 : prevSlide - 1));
  };

  return (
    <>
      <span className={`carousel-control left ${showLeftArrow ? 'show' : 'hide'}`} onClick={handleLeftClick}>
        <img src={left} alt="Left Icon" />
      </span>

      <OwlCarousel
          className="padding-bottom-2 mb--50"
          loop
          margin={30}
          responsiveClass
          nav={false}
          dots={false}
          animateOut="fadeOut" // Use 'fadeOut' for smoother fade-out transitions
          animateIn="fadeIn" // Use 'fadeIn' for smoother fade-in transitions
          smartSpeed={1000} // Adjust the speed of the transitions (in milliseconds)
          autoplayTimeout={2000}
          autoplayHoverPause
          responsive={{
            0: { items: 1 },
            768: { items: 2 },
            1200: { items: 3 }
          }}
          startPosition={currentSlide} // Set the initial slide position
        >
          {features.map((feature, index) => (
            <FeatureItem
              key={index}
              title={feature.title}
              description={feature.description}
              image={feature.image}
            />
          ))}
      </OwlCarousel>

      <span className="carousel-control right" onClick={handleRightClick}>
        <img src={right} alt="Right Icon" />
      </span>
    </>
  );
};

const HomeCarousel = () => {
  const features = [
    {
      title: "Consent",
      description: `NEOIN Consent through Digital iD refers to the process of obtaining permission or authorization from individuals or entities using their digital identities.`,
      image: featureImage1,
    },
    {
      title: "Connect",
      description: `NEOIN Connect serves as a foundational element for enabling trusted and secure digital interactions, transactions, and collaborations in today's interconnected world`,
      image: featureImage2,
    },
    {
      title: "Communicate",
      description: `NEOIN Communication ensures the secure transmission and exchange of identity data, facilitating authentication, authorization, and access control in digital interactions.`,
      image: featureImage3,
    },
    {
      title: "Engage",
      description: `NEOIN Engage empowers active involvement, participation, or interaction of individuals, organizations, or systems with digital identity solutions or platforms.`,
      image: featureImage4,
    },
  ];

  return <Carousel features={features} />;
};

export default HomeCarousel;