import React, { useEffect, useState, useRef } from 'react';

const totalDuration = 300; // Total duration in milliseconds

const Counter = ({ end, children }) => {
    const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        });

        observer.observe(counterRef.current);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isVisible) {
            const target = Number(end);
            if (isNaN(target)) {
                console.error(`Invalid 'end' value: ${end}`);
                return;
            }

            const delta = target - 0; // Calculate the difference between the start and end counts
            const step = delta / totalDuration; // Calculate the increment for each millisecond

            let currentCount = 0;
            const interval = setInterval(() => {
                currentCount += step;
                if (currentCount >= target) {
                    currentCount = target;
                    clearInterval(interval);
                }
                setCount(Math.round(currentCount)); // Round the count to ensure it's an integer
            }, 1); // Set interval to 1 millisecond for smoother animation

            return () => clearInterval(interval);
        }
    }, [isVisible, end]);

    return (
        <div ref={counterRef}>
            <span className="counter">{count}</span>{children}
        </div>
    );
};

export default Counter;