import React from 'react'
// import comingsoon1 from "../assets/images/coming-soon.jpg"
import Dalocallogo from "../assets/images/Dalocal.png" 

function Dalocalcommingsoon() {
  return (
    <div>
        {/*============= ScrollToTop Section Starts Here =============}*/}
    {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
    <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
    <div className="overlay"></div>
    {/*============= ScrollToTop Section Ends Here =============*/}


    {/*============= Coming Section Starts Here =============*/}
    <div className="coming-soon bg_img_cmgSoon">
        <div className="container">
            <div className="coming-wrapper">
                <h1 className="title">COMING SOON</h1>
                <ul className="countdown">
                  <img src={Dalocallogo}  alt="DaLocal-Logo" />
                </ul>
                
            </div>
        </div>
    </div>
    {/*============ Coming Section Ends Here =============*/}
    </div>
  )
}

export default Dalocalcommingsoon
