import React from "react";

// import pageheader from "../assets/images/page-header.png";
import pageheader1 from "../assets/css/img/page-header.png";
import privacy from "../assets/images/bg/privacy-header.png";
import calendar from '../assets/images/icon/Calendar=24px.svg'
import { useScrollToTop } from "./UseScrollToTop";


function Terms(){

    useScrollToTop();

        return(


            <>
             <section className="page-header bg_img_pg_header oh">
                <div className="bottom-shape d-none d-md-block">
                    <img src={pageheader1} alt="css" />
                </div>
                <div className="page-left-thumb">
                    <img src={privacy} alt="bg" />
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">Terms & Conditions</h2>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                           
                            <li>
                                Terms & Conditions
                            </li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="privacy-section padding-top padding-bottom">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-header">
                        <h2 className="title">Terms & Conditions</h2>
                        <p>Please read these Terms and Conditions before accessing this website.</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-between">
                {/* <div className="col-lg-4">
                    <aside className="sticky-menu">
                        <div className="faq-menu bg_img" data-background={faq}>
                            <ul id="faq-menu">
                                <li className="nav-item">
                                    <a className="nav-link" href="#general">General</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#prop">Intellectual Property</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#secu">Security and storage</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#info">Usage Information</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#cooki">Cookies</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#ipaddr">IP Address</a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div> */}
                <div className="col-lg-8 col-xl-12">
                    <article className="mt-70 mt-lg-0">

                        <div className="privacy-item">
                            <p><img src={calendar} alt="css"/>    Last updated: March 22, 2024</p>
                            <h3 className="title">Introduction</h3>
                            <p>Welcome to NEOIN  Pte Ltd(hereinafter referred to as NEOIN). These are the terms and conditions governing your access to and use of the website NEOIN.com and its related sub-domains, mobile applications, sites, services and tools (Henceforth, called NEOIN or ‘Site’ will also refer to the above). By accepting these terms and conditions (including the linked information herein), and by using the Site, you represent that you agree to comply with these terms and conditions. This User Agreement is effective upon acceptance. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site.
                            Before you may become or continue as a member of the Site, you must read, agree with and accept this User Agreement and NEOIN’s Policy (the “ Privacy Policy ”). You should read this User Agreement and the Privacy Policy and access and read all further linked information, if any, referred to in this User Agreement, as such information contains further terms and conditions that apply to you as a user of NEOIN. Such linked information including but not limited to the Privacy Policy is hereby incorporated by reference into this User Agreement.
                                If you find a NEOIN page that you believe violates our terms of use, please get in touch with us through any of the ways listed at compliance@NEOIN.com</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="accept">
                            <h3 className="title">User Agreement</h3>
                            <p>The term of this Agreement shall continue and would be in force till the time you access this Application & its services and you agree that you are bound by the terms as mentioned herein. Nothing in these Terms of Service should be construed to confer any rights on you or any third party beneficiaries.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="users">
                            <h3 className="title">Eligibility</h3>
                            <p>To be eligible for using our Service you must be an individual who is 18 years of age or older and capable of entering into a legally binding contract in the country of your domicile or be a legal entity competent to contract. By using the service, you represent and warrant that you are 18 years or older and that you have the capacity to understand, agree to and comply with these Terms of Service, including the ability to contract on behalf of your organization.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="data">
                            <h3 className="title">Changes To Terms And Conditions</h3>
                            <p>We reserve the right to add to or change/modify the terms of this Agreement including but not limited to suspend/cancel, or discontinue any or all service at any time without notice, make modifications and alterations in any or all of the content, products, and services contained on the Application without prior notice. You are responsible for regularly reviewing these Terms of Service so that you will be apprised of any changes. You will be deemed to have accepted such changes if you continue to access the Application subsequent to such changes.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="ai">
                            <h3 className="title">Information Collected</h3>
                            <p>NEOIN will only collect, store, use, and share your personal information (including email address) as it is defined by our Privacy Policy and as regulated by Singaporen laws.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="platform">
                            <h3 className="title">Privacy Policy</h3>
                            <p>The User hereby consents expresses and agrees that he has read and fully understands the Privacy Policy of NEOIN. You further consent that the terms and contents of such Privacy Policy are acceptable to you. It is our policy to respect your privacy regarding any information we may collect from you within the framework of our privacy policy.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="feature">
                            <h3 className="title">Appropriate Access & Conduct Of User And Content</h3>
                            <p>You may not use the NEOIN service for any illegal or unauthorized purpose. International users agree to comply with all Singaporen laws & regulations, including laws regulating the export of data from the United States or your country of residence. You are solely responsible for your conduct and any data, text, information, graphics, that you submit, & post on to NEOIN service.</p>
                            <p>Other examples of illegal or unauthorized uses include, but are not limited to Modifying, adapting, translating, or reverse engineering any portion of the service.</p>
                            <p>Removing any copyright, trademark or other proprietary rights notices contained in or on the service.</p>
                            <p>Using any robot, spider, Application search/retrieval application, or other devices to retrieve or index any portion.</p>
                            <p>Creating user accounts by automated means or under false or fraudulent pretenses. Creating or transmitting unwanted electronic communications such as “spam,” or chain letters to or otherwise interfering with other user’s enjoyment of the service by blocking.</p>
                            <p>Submitting data of any third party without such third party’s prior written consent.</p>
                            <p>Submitting Materials that infringe misappropriate or violate the intellectual property, publicity, privacy or other proprietary rights of any party. Transmitting any viruses, worms, defects, Trojan horses or other items of a destructive nature.</p>
                            <p>Submitting false or misleading information.</p>
                            <p>Violating any laws, regulations or code of conduct, which are in force.</p>
                            <p>While NEOIN prohibits such conduct on its Application, you understand and agree that you nonetheless may be exposed to such misuse or blocking by other users and that you use the service at your own risk. Without the specific approval from NEOIN, you shall not, directly or indirectly, pass on any material provided by NEOIN, whether or not through this application, to other organizations or individuals outside your organization. You also agree not to share your access details (login and password) with any other person within or outside the organization.</p>

                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Copyright Trademarks And Intellectual Property Rights</h3>
                            <p>Unless otherwise stated, copyright and all intellectual property rights in all material presented on the Application (including but not limited to text, audio, video or graphical images), trademarks and logos appearing on this Application are the property of NEOIN  Pte Ltd, its parent, affiliates and associates and are protected under applicable laws. Any infringement shall be vigorously defended and pursued to the fullest extent permitted by law.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="terms">
                            <h3 className="title">Copyright Infringement</h3>
                            <p>As NEOIN asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to NEOIN violates your copyright, you are encouraged to notify us. We will respond to all such notices, which may include as required or appropriate removing the infringing material or disabling all links to the infringing material. In case any user infringes the copyrights or other intellectual property rights of NEOIN or others, NEOIN may, in its discretion, terminate or deny access to and use of the site. In the case of such termination, NEOIN will have no obligation to provide a refund of any amounts previously paid to NEOIN.</p>
                            
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Limited Permission To Copy</h3>
                            <p>You are permitted to print or download extracts from these pages for your personal or organization’s internal use only. Any copies of these pages saved to disk or to any other storage medium may only be used for subsequent viewing purposes or to print extracts for personal or organization’s use internally. You may not (whether directly or through the use of any software program) create a database in electronic or structured manual form by regularly or systematically downloading and storing all or any part of the pages from this Application unless specifically for the internal use of the organization. You shall not disseminate any extract from information provided by the Application to any third parties or affiliates or partners without NEOIN’s prior permission.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Rights Of NEOIN</h3>
                            <p>NEOIN reserves its right to restrict you from using the services without any prior notice for any violation of the Terms of Use. By submitting or furnishing any information, data or details on or through the application/services, you automatically grant to us non-exclusive, permission & irrevocable right to hold & store your, such personal information, data or details, and related information. Our use of your personal information is governed by our Privacy Policy.</p>
                            <p>NEOIN shall also be allowed to use your organization name and logo in promotional material, including on its website.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Refund Policy</h3>
                            <p>NEOIN does not entertain any request or demand for refund fees paid towards the subscription for the services. The fees paid towards the subscription shall not be refunded unless such claim arises due the breach in any terms of services by NEOIN.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Cookies</h3>
                            <p>To personalize your experience on our website/Application, we may assign your computer browser a unique random number called a cookie. “Cookies” enhance website/application performance in important ways like personalizing your experience, or making your visit more convenient. NEOIN or its affiliates or vendors may use this data to analyze trends and statistics and keep track of the domains from which people visit and also measure visitor activity on website/application, but in doing so NEOIN shall keep the information anonymous. If you do not want your transaction details used in this manner, you can either disable your cookies or opt-out at the download or request page.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Indemnification</h3>
                            <p>You agree to defend, indemnify, and hold harmless NEOIN and/or its associate entities, their officers, directors, employees, and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the web site/application material or your breach of these Terms and Conditions of Web site/application use.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Disclaimer Of Warranties And Liability</h3>
                            <p>All the contents of this Application are only for general information or use. They do not constitute advice and should not be relied upon in making (or refraining from making) any decision. Any specific advice or replies to queries in any part of the Application is/are the personal opinion of such experts/consultants/persons and are not subscribed to by this Application. NEOIN does not guarantee the accuracy, completeness or timeliness of the data. The information from or through this Application is provided on “AS IS” basis, and all warranties expressed or implied of any kind, regarding any matter pertaining to any goods, service or channel, including without limitation, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement are disclaimed and excluded.</p>
                            <p>NEOIN  Pte Ltd, its directors, employees, agents, representatives and the authors and its parent, affiliates and associates shall not be liable, at any time for damages (including, without limitation, damages for loss of business projects, or loss of profits) arising in contract, tort or otherwise from the use of or inability to use the Application, or any of its contents, or from any act or omissions as a result of using the Application or any such contents or for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Application. No representations, warranties or guarantees whatsoever are made as to the accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation. NEOIN  Pte Ltd , its directors, employees, agents, representatives disclaim all any all direct, indirect, special, consequential damages, loss arising from using the services provided by NEOIN.</p>
                            <p>Certain links on the Application lead to resources located on servers maintained by third parties and are external to NEOIN. You agree and understand that by visiting such Applications you are beyond the NEOIN web Application. NEOIN, therefore, neither endorses nor offers any judgment or warranty and accepts no responsibility or liability for the authenticity/availability of any of the goods/services/or for any damage, loss or harm, direct or consequential or any violation of local or international laws that may be incurred by your visit and/or transaction/s on these Applications.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Termination</h3>
                            <p>NEOIN may terminate your access to all or any part of the site at any time, with or without cause, with or without notice. If you wish to terminate this Agreement or your NEOIN account (if you have one), you may simply discontinue using NEOIN. In addition, NEOIN can terminate the service immediately as part of a general shut down of our service. All provisions of this Agreement, which by their nature shall survive termination, including, without limitation, ownership provisions, intellectual property rights, warranty disclaimers, indemnity and limitations of liability. Suppose you or NEOIN terminates your use of the site. In that case, NEOIN may delete any content or other materials relating to your use of the site – related to sub-domains, mobile applications, sites, services, and tools, and NEOIN will have no liability to you or any third party for doing so.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Limitation Of Liability</h3>
                            <p>In no event will NEOIN, its directors, officers, employees or agents be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for:</p>
                            <p> (i) any special, incidental or consequential damages;</p>
                            <p> (ii) the cost of procurement of substitute products or services;</p>
                            <p>(iii) for interruption of use or loss or corruption of data;</p>
                            <p>or (iv) for any amounts that exceed the amounts paid by you to NEOIN under this agreement in respect of the order under dispute or the amount paid to NEOIN in respect of the usage for past 6 months whichever is lower. NEOIN shall have no liability for any failure or delay due to matters beyond our reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">General Representation And Warranty</h3>
                            <p>You represent and warrant that your use of the site will be in strict accordance with the NEOIN Privacy Policy, with this Agreement and with all applicable laws and regulations.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Usage Conduct</h3>
                            <p>You shall solely be responsible for maintaining the necessary computer equipment and Internet connections that may be required to access use and transact on NEOIN.</p>
                            <p>You are also under an obligation to use NEOIN for reasonable and lawful purposes only, and shall not indulge in any activity that is not envisaged through NEOIN.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Electronic Communication</h3>
                            <p>You agree that we may communicate with you by email or by posting notices on the Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. NEOIN requires your agreement during the registration process to send you promotional emails to let you know about any new changes, features or promotional activities added to the Site. If at any time, you decide that you do not wish to receive promotional emails, you can opt out of receiving such promotional emails by clicking on the link at the bottom of any promotional email.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Force Majeure</h3>
                            <p>NEOIN shall have no liability to you for any interruption or delay in access to the Application irrespective of the cause. Force Majeure event shall be one when there is any cessation, interruption or delay in the performance of NEOIN’s obligations due to causes beyond its reasonable control, including but not limited to: technical, earthquake, flood, or other natural disaster, act of God, labor controversy, civil disturbance, war (whether or not officially declared) or the inability to obtain sufficient supply of service, transportation, or other essential commodity or service required in the conduct of its business, or any change in or the adoption of any law, regulation, judgment or decree. Without limiting the foregoing, under no circumstances shall NEOIN be held liable for any delay or failure in performance resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, Internet failures, computer equipment failures, telecommunication equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or fluctuations in heat, light, or air conditioning of your information and or data received and or stored or any of our service/s.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Governing Law</h3>
                            <p>The Agreement shall be governed by the Laws of Singapore. The Courts of law at Pune shall have exclusive jurisdiction over any disputes arising under this agreement.</p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Entire Agreement</h3>
                            <p>These Terms of Service constitute the entire agreement between the parties concerning with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</p>
                            <p>There are risks associated with utilizing the internet and short messaging system (chat) based information and research dissemination services. Users/subscribers are advised to understand that the services can fail due to hardware, software, and Internet connection failure. While we ensure that the email/messages are delivered in time to the user/subscribers, the delivery of these email/messages to the customer’s email inbox or message center is the responsibility of the email/messenger’s service provider Network. Email/messages may be delayed and/or not delivered to the user/subscriber’s inbox or chat box on certain days, owing to technical reasons that can only be addressed by the service provider, and NEOIN  Pte Ltdwho owns NEOIN and group companies associated with it and/or its employees cannot be held responsible for the same.</p>
                            <p>NEOIN hereby expressly disclaims any implied warranties imputed by the laws of any jurisdiction. We consider ourselves and intend to be subject to the jurisdiction only of the courts of the Bangalore in Singapore. If you don’t agree with any of our terms and conditions and disclaimers please do not read the material on any of our pages. NEOIN shall have no legal liabilities whatsoever in any laws of any jurisdiction other than Singapore. We reserve the right to make changes to our Application and these disclaimers, terms, and conditions at any time.</p>
                        </div>

                        <div className="privacy-item" id="limit">
                            <h3 className="title">Report Abuse</h3>
                            <p>If you come across any abuse or violation of these Terms, please report to <a href="mailto:admin@neoin.co">admin@neoin.co</a></p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                        <div className="privacy-item" id="limit">
                            <h3 className="title">Grievance & Redressal</h3>
                            <p>For any information regarding the various services and products offered by us or for providing any critical feedback with regard to our website/product performance/staff response, You can contact: <a href="mailto:admin@neoin.co">ADMIN@NEOIN.com</a></p>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget 
                                euismod erat, sit amet vulputate enim. Etiam enim tellus.</p> */}
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
            
            
            </>




        )



}


export default Terms