import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import QRCodeStyling from "qr-code-styling";
import NeoinLogo from "../assets/images/logo/neoin-logo.png";

const QrLogin = ({ uid }) => {
  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null); // Persist the QR code instance

  const qrCodeOptions = {
    width: 110,
    height: 110,
    data: uid,
    image: NeoinLogo,
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.3,
      crossOrigin: "anonymous",
    },
    dotsOptions: { color: "#000000" },
    cornersSquareOptions: { color: "#000000", type: "dot" },
    cornersDotOptions: { color: "#000000", type: "dot" },
    backgroundOptions: { color: "#ffffff" },
  };

  useEffect(() => {
    if (!qrCodeInstance.current) {
      // Create a new QRCodeStyling instance only if it doesn't already exist
      qrCodeInstance.current = new QRCodeStyling(qrCodeOptions);
    }

    // Update the QR code when the UID changes
    qrCodeInstance.current.update({ data: uid });

    // Append the QR code only if not already appended
    if (qrCodeRef.current && qrCodeRef.current.childNodes.length === 0) {
      qrCodeInstance.current.append(qrCodeRef.current);
    }
  }, [uid]);

  return <div ref={qrCodeRef}></div>;
};

QrLogin.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default QrLogin