import React,{ useEffect } from 'react'
import page_header from "../assets/css/img/page-header.png"
import page_header2 from "../assets/images/page-header.png"
import feature_shape2 from "../assets/images/feature/feature-shape2.png"

// import pro2 from "../assets/images/feature/pro2.png"
// import pro3 from "../assets/images/feature/pro3.png"
// import pro4 from "../assets/images/feature/pro4.png"
// import pro_main5 from "../assets/images/feature/pro-main5.png"
// import pro_main6 from "../assets/images/feature/pro-main6.png"
// import pro_main7 from "../assets/images/feature/pro-main7.png"

import feat1 from "../assets/images/features/Seamless Connectivity.png";
import feat2 from "../assets/images/features/Geo-tagging_02.png";
import feat3 from "../assets/images/features/Effortless Data Processing.png";
import feat4 from "../assets/images/features/Enhanced Security.png";
import feat5 from "../assets/images/features/Customize Your Video Calls.png";
import feat6 from "../assets/images/features/Instant Communication.png";
import feat7 from "../assets/images/features/Layered Security.png";
import feat8 from "../assets/images/features/Track and Improve with Neoin PPF Score.png";

import { useLocation } from 'react-router-dom';

function Features() {

    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const scrollTo = params.get('scrollTo');
        
        const sectionIds = {
            nfc: 'nfc',
            geotagging: 'geotagging',
            aiscan: 'aiscan',
            biometrics: 'biometrics',
            virtualbg: 'virtualbg',
            qrcall: 'qrcall',
            mfa: 'mfa',
            ppf: 'ppf'
        };
    
        const sectionId = sectionIds[scrollTo];
        if (sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                window.scroll({
                    top: element.offsetTop - 70,
                    behavior: 'smooth'
                });
            }
        }
        }, 100);
    }, [location.search]);
    
    return (
   <>
    { /* <!--============= Header Section Ends Here =============--> */ }
    <section className="Featurebanner page-header bg_img_pg_header" >
        <div className="bottom-shape d-none d-md-block">
            <img src={page_header} alt="css" />
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="Newtitle">Features</h2>
                <ul className="breadcrumb">
                    <li>
                        <a href="">Products</a>
                    </li>
                    <li>
                        Features
                    </li>
                </ul>
            </div>
        </div>
    </section>
    { /* <!--============= Header Section Ends Here =============--> */ }


    
    { /* <!--============= Feature Section Starts Here =============--> */ }
    <section  className="feature-section padding-top oh pos-rel padding-bottom-2 pb-xl-0">
        <div className="feature-shapes style-two d-none d-lg-block">
            <img src={feature_shape2} alt="feature" />
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-xl-8">
                    <div className="section-header mw-100">
                        <h5 className="cate">Discover Neoin's Advanced Features</h5>
                        <h2 className="title"> Explore our offerings and revolutionize the way you interact online</h2>
                        {/* <p className="mw-500">Numerous features make it possible to customize the system
                            in accordance with all your needs.</p> */}
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-lg-5 rtl">
                    <div className="feature--thumb style-two pr-xl-4 ltr">
                        <div className="feat-slider owl-carousel owl-theme" data-slider-id="1">
                            <div className="main-thumb">
                                <img src={pro_main5} alt="feature" />
                            </div>
                            <div className="main-thumb">
                                <img src={pro_main6} alt="feature" />
                            </div>
                            <div className="main-thumb">
                                <img src={pro_main7} alt="feature" />
                            </div>
                            <div className="main-thumb">
                                <img src={pro_main6} alt="feature" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="feature-wrapper mb-30-none owl-thumbs" data-slider-id="1">
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={pro_main5} alt="feature" />
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Latest Technology</h4>
                                <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={pro2} alt="feature" />
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Flexible Usability</h4>
                                <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={pro3} alt="feature" />
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Easy to Manage Your All Data</h4>
                                <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={pro4} alt="feature" />
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Designed for all devices</h4>
                                <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={pro3} alt="feature" />
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Easy to Manage Your All Data</h4>
                                <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <div className="feature-thumb">
                                <div className="thumb">
                                    <img src={pro4} alt="feature" />
                                </div>
                            </div>
                            <div className="feature-content">
                                <h4 className="title">Designed for all devices</h4>
                                <p>The satisfaction of users is the most important and the focus is on usability and completeness</p>
                            </div>
                        </div>
                    </div>
                    <div className="feat-nav">
                        <a href="#0" className="feat-prev"><i className="flaticon-left"></i></a>
                        <a href="#0" className="feat-next active"><i className="flaticon-right"></i></a>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
    { /* <!--============= Feature Section Ends Here =============--> */ }


     { /* <!--============= Faster Section Starts Here 1 =============--> */ }
     <div id="nfc" className="faster-section padding-top-2 oh padding-bottom">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-7">
                    <div className="faster-content">
                        <div className="section-header left-style mb-olpo">
                        <h2 className="title">Seamless Connectivity with Neoin NFC</h2>
                            <p>Experience effortless data exchange and secure transactions with Neoin's Near Field Communication (NFC) technology. Tap into the future of connectivity with seamless device pairing and contactless transactions.</p>
                        </div>
                        <ul className="bal-list">
                            <li>Connectivity</li>
                            <li>Data exchange</li>
                            <li>Secure transactions</li>
                            <li>Device Compatibility</li>
                            <li>Seamless pairing</li>
                            <li>Contactless transactions</li>

                        </ul>
                        {/* <a href="#0" className="get-button">Get Free Trial<i className="flaticon-right"></i></a> */}
                    </div>
                </div>
                <div className="Feature1 col-lg-5 col-xl-5 FeatureImg">  {/*  d-none d-lg-block */}
                    <img src={feat1} alt="feature 1" />
                </div>
            </div>
        </div>
    </div>
    { /* <!--============= Faster Section Ends Here =============--> */ }

    { /* <!--============= Faster Section Starts Here 1 =============--> */ }
    <div id="geotagging" className="faster-section padding-top-2 oh padding-bottom">
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-last mb-4 mb-lg-0">
                <div className="faster-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">All-in-one responsive app for you</h5> */}
                        <h2 className="title">Contextualize Your Content with Neoin Geo-tagging</h2>
                        <p>Neoin's Geo-tagging feature allows you to add geographical context to your digital content. Organize your photos, videos, and notes with ease, and enhance your storytelling by adding location metadata.</p>
                    </div>
                    <ul className="bal-list">
                            <li>Location Insights</li>
                            <li>Personalized Mapping</li>
                            <li>Asset tracking</li>
                            <li>Location Security</li>
                            <li>Geo-fencing</li>
                            <li>Efficient Navigation</li>
                        </ul>
                    {/* <div className="group">
                        <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                        <a href="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a>
                    </div> */}
                </div>
            </div>
            <div className="Feature2 col-lg-5 col-xl-5 order-lg-first FeatureImg"> {/*  d-none d-lg-block */}
                <img src={feat2} alt="feature 2" className="img-fluid" />
            </div>
        </div>
    </div>
</div>
    { /* <!--============= Faster Section Ends Here =============--> */ }

    { /* <!--============= Faster Section Starts Here 1 =============--> */ }
    <div id='aiscan' className="faster-section padding-top-2 oh padding-bottom">
    <div className="container">
        <div className="row justify-content-between align-items-center"> {/* Added align-items-center to vertically align the content */}
            <div className="col-lg-7">
                <div className="faster-content">
                    <div className="section-header left-style mb-olpo">
                        <h2 className="title">Effortless Data Processing with Neoin AI-enabled Scan</h2>
                        <p>Streamline your document processing tasks with Neoin's AI-enabled scanning feature. Our advanced algorithms automate data extraction and analysis, saving you time and improving accuracy.</p>
                    </div>
                    <ul className="bal-list">
                        <li>Intelligent Parsing (AI)</li>
                        <li>Automatic Extractions</li>
                        <li>Data Insights</li>
                        <li>Enhanced Efficiency</li>
                        <li>Accuracy Improvement</li>
                        <li>Document Management</li>
                    </ul>
                </div>
            </div>
            <div className="Feature3 col-lg-5 col-xl-5 FeatureImg">
                <img src={feat3} alt="feature 3" className="img-fluid" /> {/* Added img-fluid class for responsive images */}
            </div>
        </div>
    </div>
</div>

    { /* <!--============= Faster Section Ends Here =============--> */ }

    { /* <!--============= Faster Section Starts Here 1 =============--> */ }
    <div id='biometrics' className="faster-section padding-top-2 oh padding-bottom">
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-last mb-4 mb-lg-0">
                <div className="faster-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">All-in-one responsive app for you</h5> */}
                        <h2 className="title">Enhanced Security through Neoin Biometrics</h2>
                        <p>Protect your devices and accounts with Neoin's biometric authentication. Utilize unique physical or behavioral characteristics for secure access control and enjoy peace of mind with robust security measures.</p>
                    </div>
                    <ul className="bal-list">
                            <li>Unique identifiers</li>
                            <li>Fingerprint scanning</li>
                            <li>Facial recognition</li>
                            <li>Voice authentication</li>
                            <li>Enhanced security</li>
                            <li>User-friendly access</li>
                        </ul>
                    {/* <div className="group">
                        <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                        <a href="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a>
                    </div> */}
                </div>
            </div>
            <div className="Feature4section col-lg-5 col-xl-5 order-lg-first FeatureImg">
                <img src={feat4} alt="feature 4" className="img-fluid" />
            </div>
        </div>
    </div>
</div>
    { /* <!--============= Faster Section Ends Here =============--> */ }

    { /* <!--============= Faster Section Starts Here 1 =============--> */ }
     <div id='virtualbg' className="faster-section padding-top-2 oh padding-bottom">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-7">
                    <div className="faster-content">
                        <div className="section-header left-style mb-olpo">
                            {/* <h5 className="cate">All-in-one responsive app for you</h5> */}
                            <h2 className="title">Customize Your Video Calls with Neoin Virtual Backgrounds</h2>
                            <p>Personalize your online presence with Neoin's virtual backgrounds feature. Showcase your brand or personality with custom backgrounds during video calls, enhancing your visual presentation and privacy.</p>
                        </div>
                        <ul className="bal-list">
                            <li>Customized backgrounds</li>
                            <li>Visual enhancement</li>
                            <li>Privacy protection</li>
                            <li>Professional appearance</li>
                            <li>Personalization options</li>
                            <li>Improved video presentation</li>
                        </ul>
                        {/* <div className="group">
                            <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                            <a href="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a>
                        </div> */}
                    </div>
                </div>
                <div className="Feature5 col-lg-5 col-xl-5 FeatureImg">
                    <img src={feat5} alt="feature 5" />
                </div>
            </div>
        </div>
    </div>
    { /* <!--============= Faster Section Ends Here =============--> */ }


    { /* <!--============= Faster Section Starts Here 1 =============--> */ }
    <div id='qrcall' className="faster-section padding-top-2 oh padding-bottom">
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-last mb-4 mb-lg-0">
                <div className="faster-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">All-in-one responsive app for you</h5> */}
                        <h2 className="title">Instant Communication with Neoin QR Code Interaction</h2>
                        <p>Simplify communication with Neoin's QR code feature. Initiate calls, send messages, or compose emails instantly with scan-to-connect capabilities, making communication effortless.</p>
                    </div>
                    <ul className="bal-list">
                            <li>Seamless communication</li>
                            <li> Instant Dialing</li>
                            <li>Easy SMS message sending</li>
                            <li>Effortless email composition</li>
                            <li>Smartphone scanning convenience</li>
                            <li>Streamlined interaction</li>
                        </ul>
                    {/* <div className="group">
                        <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                        <a href="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a>
                    </div> */}
                </div>
            </div>
            <div className="Feature6 col-lg-5 col-xl-5 order-lg-first FeatureImg">
                <img src={feat6} alt="feature 6" className="img-fluid" />
            </div>
        </div>
    </div>
</div>
    { /* <!--============= Faster Section Ends Here =============--> */ }
     { /* <!--============= Faster Section Starts Here 1 =============--> */ }
     <div id='mfa' className="faster-section padding-top-2 oh padding-bottom">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-7">
                    <div className="faster-content">
                        <div className="section-header left-style mb-olpo">
                            {/* <h5 className="cate">All-in-one responsive app for you</h5> */}
                            <h2 className="title">Layered Security for Your Peace of Mind with Neoin MFA</h2>
                        <p>Secure your accounts with Neoin's Multi-Factor Authentication (MFA). Implement multiple forms of verification for added security, and enjoy the confidence of knowing your accounts are protected.</p>
                        </div>
                        <ul className="bal-list">
                            <li>Enhanced security</li>
                            <li>Diverse Verification</li>
                            <li>Access Control</li>
                            <li>Password protection</li>
                            <li>Biometric verification</li>
                            <li>One-time code authentication</li>
                        </ul>
                        {/* <div className="group">
                            <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                            <a href=One-time code authentication="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a>
                        </div> */}
                    </div>
                </div>
                <div className="Feature7 col-lg-5 col-xl-5 FeatureImg">
                    <img src={feat7} alt="feature 7" />
                </div>
            </div>
        </div>
    </div>
    { /* <!--============= Faster Section Ends Here =============--> */ }

    { /* <!--============= Faster Section Starts Here 1 =============--> */ }
    <div id='ppf' className="faster-section padding-top-2 oh padding-bottom">
    <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-last mb-4 mb-lg-0">
                <div className="faster-content">
                    <div className="section-header left-style mb-olpo">
                        {/* <h5 className="cate">All-in-one responsive app for you</h5> */}
                        <h2 className="title">Track and Improve with Neoin PPF Score</h2>
                        <p>Monitor your progress and optimize your performance with Neoin's Personalized Performance Feedback (PPF) Score. Receive tailored insights to help you reach your goals and achieve success.</p>
                    </div>
                    <ul className="bal-list">
                            <li>Performance evaluation</li>
                            <li>Performance Insights</li>
                            <li>Metric Feedback</li>
                            <li>Improvement Suggestions</li>
                            <li>Tailored guidance</li>
                            <li>Enhancing personal and professional endeavors</li>
                        </ul>
                    {/* <div className="group">
                        <a href="#0" className="get-button active">Start Using for free<i className="flaticon-right"></i></a>
                        <a href="#0" className="get-button">More About Mosto<i className="flaticon-right"></i></a>
                    </div> */}
                </div>
            </div>
            <div className="col-lg-5 col-xl-5 order-lg-first FeatureImg">
                <img src={feat8} alt="feature 8" className="img-fluid" />
            </div>
        </div>
    </div>
</div>
    { /* <!--============= Faster Section Ends Here =============--> */ }

    { /* <!--============= Comunity Section Starts Here =============--> */ }
    {/* <section id="feature-section123" className="comunity-section padding-top padding-bottom oh pos-rel">
        <div className="comunity-bg bg_img" data-background={mostoclient}></div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-header cl-white">
                        <h5 className="cate">Join to comunity</h5>
                        <h2 className="title">Over 1 000 000 users</h2>
                        <p>Join and share in a community of like-minded members</p>
                    </div>
                </div>
            </div>
            <div className="comunity-wrapper">
                <div className="buttons"><a href="#0" className="button-3 active">Join to comunity <i className="flaticon-right"></i></a></div>
                <div className="comunity-area">
                    <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community22} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                </div>
                <div className="comunity-area two">
                    <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community22} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                </div>
                <div className="comunity-area three">
                    <div className="comunity-item"><img src={community1} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community2} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community3} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community4} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community5} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community6} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community7} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community8} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community9} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community10} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community11} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community12} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community13} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community14} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community15} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community16} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community17} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community18} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community19} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community22} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community20} alt="comunity" /></div>
                    <div className="comunity-item"><img src={community21} alt="comunity" /></div>
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Comunity Section Ends Here =============--> */ }


    { /* <!--============= Sponsor Section Section Here =============--> */ }
    {/* <section className="sponsor-section padding-bottom">
        <div className="container">
            <div className="section-header mw-100">
                <h5 className="cate">Used by over 1,000,000 people worldwide</h5>
                <h2 className="title">Companies that trust us</h2>
            </div>
            <div className="sponsor-slider-4 owl-theme owl-carousel">
                <div className="sponsor-thumb">
                    <img src={sponsor1} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor2} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor3} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor4 } alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor5} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor6} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor7} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor1} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor2} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor3} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor4} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor5} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor6} alt="sponsor" />
                </div>
                <div className="sponsor-thumb">
                    <img src={sponsor7} alt="sponsor"/>
                </div>
            </div>
        </div>
    </section> */}
    { /* <!--============= Sponsor Section Ends Here =============--> */ }

   </>
  )
}
export default Features;