import React, { useState, useEffect, useRef } from 'react'
// import Bannerbg from "../assets/images/banner/banner-bg-4.png"
// import Playbtn from "../assets/images/button/play2.png"
// import Slide01 from "../assets/images/banner/slide1.png"
// import Slide02 from "../assets/images/banner/slide2.png"
// import Slide03 from "../assets/images/banner/slide3.png"
// import Slide04 from "../assets/images/banner/slide4.png"

import Counter01 from "../assets/images/icon/counter1.png"
import Counter02 from "../assets/images/icon/counter2.png"


// import Feature01 from "../assets/images/feature/01.png"
// import Feature02 from "../assets/images/feature/02.png"
// import Feature03 from "../assets/images/feature/03.png"
// import Feature04 from "../assets/images/feature/04.png"
// import Feature05 from "../assets/images/feature/05.png"
// import Feature06 from "../assets/images/feature/06.png"
// import Feat02 from "../assets/images/feature/feature02.png"

import main from "../assets/images/collaboration/main.png"
import mobile from "../assets/images/collaboration/mobile.png"
import girl from "../assets/images/collaboration/girl.png"
import leftArrowImage from "../assets/images/collaboration/left.png"
import rightArrowImage from "../assets/images/collaboration/right.png"
import google from "../assets/images/button/google.png"
import apple from "../assets/images/button/apple.png"
// import watch2 from  "../assets/images/watch/watch2.png"
import smartWatchBank from "../assets/images/banks/Smart Watch Frame.png"
import financialJourney from "../assets/images/banks/Your Financial Journey, Our Expert Guidance.png"
import BankingEfficiency from "../assets/images/banks/Banking Efficiency.png"

import bg1 from "../assets/images/bg/1.png"
import bg2 from "../assets/images/bg/2.png"

import SeeWhatYou from "../assets/images/banks/See what you.png"

import onBoardIcon from "../assets/images/banks/icons/Seamless On-Boarding.svg"
import oneClickIcon from "../assets/images/banks/icons/One Click KYC.svg"
import adAuthIcon from "../assets/images/banks/icons/Advanced Authentication.svg"
import fraudPreventionIcon from "../assets/images/banks/icons/Fraud Prevention.svg"
import encrTransacIcon from "../assets/images/banks/icons/Encrypted Transactions.svg"
import bioVerifyIcon from "../assets/images/banks/icons/Biometric Verification.svg"
import secCommIcon from "../assets/images/banks/icons/Secure Communication.svg"
import peaceOfMindIcon from "../assets/images/banks/icons/Peace Of Mind.svg"

// import newsletter1 from "../assets/images/newslater/newslater1.png"
// import clientleft from "../assets/images/client/left.png"
// import clientright from  "../assets/images/client/right.png"
// import client1 from "../assets/images/dummies/D3.png"
// import client2 from "../assets/images/dummies/D6.png"
// import cate from "../assets/images/cate.png"
import balls from "../assets/images/balls/balls.png"

import slide1 from "../assets/images/banks/screen1.png";
import slide2 from "../assets/images/banks/screen2.png";
import slide3 from "../assets/images/banks/screen3.png";
import slide4 from "../assets/images/banks/screen4.png";
import slide5 from "../assets/images/banks/screen5.png";
import slide6 from "../assets/images/banks/screen6.png";

// import worldmapdata from "../assets/images/bg/world-map.png"
// import newslaterdata from "../assets/images/bg/newslater.jpg"
// import smartbgdata from "../assets/images/bg/smart-bg-1.png"

// import screendata1 from "../assets/images/collaboration/screen1.png"
// import screendata2 from "../assets/images/collaboration/screen2.png"
// import screendata3 from "../assets/images/collaboration/screen3.png"
// import screendata4 from "../assets/images/collaboration/screen4.png"
import OwlCarousel from 'react-owl-carousel';
import img1 from '../assets/images/banks/Smart Watch Version_slide1.png'
import img2 from '../assets/images/banks/Smart Watch Version_slide2.png'
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

import Counter from './Counter'
import Download from './Download'
// import Review from './Review'
import BankPageCarousel from './BankPageCarousel'

function Bank() {

    const carouselImages = [
        img1,
        img2,
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(intervalId);
    }, [carouselImages]);

    const [activeIndex, setActiveIndex] = useState(0);
    const [collabIndex, setCollabIndex] = useState(0);
    const BannercarouselRef = useRef(null);
    const CollabCarouselRef = useRef(null);

    const BannerCarItems = [
        { id: 1, slide: slide1 },
        { id: 2, slide: slide2 },
        { id: 3, slide: slide3 },
        { id: 4, slide: slide4 },
        { id: 5, slide: slide5 },
        { id: 6, slide: slide6 },
    ];

    const changeSlide = (index) => {
        setActiveIndex(index); // Update the active index state
        if (BannercarouselRef.current) {
            BannercarouselRef.current.to(index, 300); // Navigate to the slide at the specified index
        }
    };

    const handleCollabItemChange = (event) => {
        setCollabIndex(event.item.index); // Update collaboration index state
    };
    

    const CollabCarousel = [
        {
            id:1,
            title: "Cost-Effective Integration",
            description: "NEOIN offers an affordable solution for banks, with cost-effective integration processes tailored to streamline implementation and minimize expenses."
        },
        {
            id:2,
            title: "Flexible Licensing Options",
            description: "Banks can benefit from NEOIN's flexible licensing options, allowing them to choose the most suitable package that aligns with their budget and requirements."
        },
        {
            id:3,
            title: "Scalable Solutions",
            description: "NEOIN provides scalable solutions that grow with the bank's needs, ensuring that the investment remains affordable both now and in the future."
        },
        {
            id:4,
            title: "Efficiency Savings",
            description: "By leveraging NEOIN's efficient technology and streamlined processes, banks can save on operational costs, making it a financially viable option for institutions of all sizes."
        },
        {
            id:5,
            title: "Value-Driven Features",
            description: "Despite its affordability, NEOIN doesn't compromise on quality or functionality, offering banks a comprehensive suite of features that add value to their services without breaking the bank."
        },
        {
            id:6,
            title: "ROI Assurance",
            description: "With NEOIN, banks can expect a solid return on investment (ROI) through enhanced customer satisfaction, increased efficiency, and reduced overheads, making it a financially prudent choice for any banking institution."
        }
    ];
    

    return (
        <div>
            { /* <!--============= Banner Section Starts Here =============--> */}
            <section className="banner-4 bg_img_bnr_bg_4 oh bottom_left bankbanner tab-pb-0">
                <div className="container">
                    <div className="row align-items-top">
                        <div className="col-xl-7">
                            <div className="banner-content-3 pt-4">
                                <h1 className="Newtitle">Simplify your Banking experience with NEOIN </h1>
                                <p>Experience seamless banking with NEOIN's innovative platform, simplifying your financial journey. Discover tailored banking solutions designed for you.</p>
                                <div className="banner-button-group">
                                    <a href="/comingsoon" className="button-4">Create iD</a>
                                    {/* <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="play-button popup">
                                <img src={Playbtn} alt="button"/>
                                <span className="cl-black">Play Video</span>
                            </a> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-5">
                    <div className="banner-nav-container bg_img_bnr_4 bg_contain bottom_center">
                        <div className="ban-nav">
                            <a href="#0" className="ban-prev active">
                                <i className="flaticon-left"></i>
                            </a>
                            <a href="#0" className="ban-next">
                                <i className="flaticon-right"></i>
                            </a>
                        </div>
                        <div className="banner-4-slider owl-theme owl-carousel">
                            <div className="slide-item bg_img_bank_slide1">
                            </div>
                            <div className="slide-item bg_img_bank_slide2">
                            </div>
                            <div className="slide-item bg_img_bank_slide3">
                            </div>
                            <div className="slide-item bg_img_bank_slide4">
                            </div>
                        </div>
                    </div>
                </div> */}
                        <div className="col-xl-5">
                            <div className="banner-nav-container bg_img_bnr_4 bg_contain bottom_center">
                                <div className="ban-nav">
                                    <a href="#0" className="ban-prev active" onClick={() => changeSlide(activeIndex - 1)}>
                                        <i className="flaticon-left"></i>
                                    </a>
                                    <a href="#0" className="ban-next" onClick={() => changeSlide(activeIndex + 1)}>
                                        <i className="flaticon-right"></i>
                                    </a>
                                </div>
                                <OwlCarousel
                                    className="banner-4-slider bank-carousel"
                                    loop
                                    margin={10}
                                    nav={false}
                                    dots={false}
                                    autoplay={true}
                                    autoplayTimeout={4000}
                                    autoplayHoverPause
                                    responsive={{
                                        0: { items: 1 },
                                        768: { items: 1 },
                                        1200: { items: 1 }
                                    }}
                                    ref={BannercarouselRef}
                                    startPosition={activeIndex}
                                >
                                    {BannerCarItems.map((item) => (
                                        <div key={item.id} className={`slide-item ${item.id === activeIndex ? 'active' : ''}`} onClick={() => changeSlide(item.id - 1)}>
                                            <img src={item.slide} alt={`Slide ${item.id}`} />
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>

                        <div className="col-12 mt-2 mt-lg-3">
                            <div className="banner-odometer">
                                <div className="counter-item">
                                    <div className="counter-thumb">
                                        <img src={Counter01} alt="icon" />
                                    </div>
                                    <div className="counter-content">
                                        <h2 className="title"><Counter end={500}>+</Counter></h2>
                                        <span>Premium User</span>
                                    </div>
                                </div>
                                <div className="counter-item">
                                    <div className="counter-thumb">
                                        <img src={Counter02} alt="icon" />
                                    </div>
                                    <div className="counter-content">
                                        <h2 className="title"><Counter end={1987}></Counter></h2>
                                        <span>Daily Visitors</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Banner Section Ends Here =============--> */}


            { /* <!--============= Exclusive Section Starts Here =============--> */}
            <section className="exclusive-section bank-exclu-items padding-bottom-2 padding-top oh wow slideInUp" id="feature">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6">
                            <div className="section-header left-style">
                                <h5 className="cate">Advanced Security, Simplified Banking</h5>
                                <h2 className="title">See what you can do with NEOIN</h2>
                                <p>
                                    <span className='first-word'> NEOIN,</span> powered by Secure iD, offers top-notch banking security. Advanced authentication and encrypted transactions ensure your financial information remains protected.
                                </p>
                            </div>
                            <div className="row mb--20">
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={onBoardIcon} alt="On-boarding" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Seamless On-Boarding</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={oneClickIcon} alt="One click KYC" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">One Click KYC</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={adAuthIcon} alt="Advanced Authentication" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Advanced Authentication</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={fraudPreventionIcon} alt="Fraud Prevention" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Fraud Prevention</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={encrTransacIcon} alt="Encrypted Transaction" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Encrypted Transactions</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={bioVerifyIcon} alt="feature" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Biometric Verification</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={secCommIcon} alt="Secure Communication" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Secure Communication</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="exclusive-item">
                                        <div className="exclusive-thumb bank-icon-bg">
                                            <img src={peaceOfMindIcon} alt="feature" className='bank-icon' />
                                        </div>
                                        <div className="exclusive-content">
                                            <h6 className="title">Peace of Mind</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 d-lg-block d-none">
                            <div className="feature-1-thumb mt-0 ex-feature wow slideInRight">
                                <img src={SeeWhatYou} alt="feature" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            { /* <!--============= Exclusive Section Ends Here =============--> */}


            { /* <!--============= Colaboration Section Starts Here =============--> */}
            <BankPageCarousel />
            { /* <!--============= Colaboration Section Ends Here =============--> */}


            { /* <!--============= Smart Watch Section Starts Here =============--> */}
            <section className="smart-watch-section">
                <div className="smart-bg bg_img_smartbg d-lg-block d-none"></div>
                <div className="oh padding-top-2 padding-bottom-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mt-lg-4">
                                <div className="smart-watch-content mt-lg-5">
                                    <div className="section-header left-style">
                                        <h5 className="cate">Secure Banking, Right on Your Wrist</h5>
                                        <h2 className="title">Smart Watch Version </h2>
                                        <p>
                                            Secure banking, simplified on your wrist with NEOIN's smartwatch app. <span className='first-word'>Enjoy advanced authentication, fraud prevention, and encrypted transactions</span> seamlessly. Biometric verification and secure communication ensure peace of mind on the go.
                                        </p>
                                    </div>
                                    <div className="app-button-group">
                                        <a href="https://play.google.com/store/games?hl=en_IN&gl=US&pli=1" target='_blank' rel='noreferrer' className="app-button">
                                            <img src={google} alt="button" />
                                        </a>
                                        <a href="https://www.apple.com/in/app-store/" target='_blank' rel='noreferrer' className="app-button">
                                            <img src={apple} alt="button" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-lg-block d-none">
                                <div className="smart-watch">
                                    <img src={smartWatchBank} alt="watch" />
                                </div>
                            </div>

                            <div className="carousel-container-new col-lg-6 d-lg-block d-none">
                                <img src={carouselImages[currentImageIndex]} alt="slide" className="carousel-image" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Smart Watch Section Ends Here =============--> */}


            { /* <!--============= Pricing Section Starts Here =============--> */}
            <section className="pricing-section padding-bottom padding-top-2 oh" id="pricing">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-xl-6">
                            <div className="pricing-content">
                                <div className="section-header left-style">
                                    <h5 className="cate">Banking Efficiency Solutions</h5>
                                    <div className="colaboration-wrapper">
                                        <OwlCarousel
                                            className='colaboration-slider owl-theme bank-collab-slider'
                                            loop
                                            nav
                                            items={1} // Show only one item
                                            dots={false} 
                                            autoPlay={false}               
                                            navText={[
                                                `<img src=${leftArrowImage} alt="Prev">`,
                                                `<img src=${rightArrowImage} alt="Next">`
                                            ]}
                                            onChanged={handleCollabItemChange} // Attach onChanged event handler
                                            startPosition={collabIndex} // Set startPosition to the current collaboration index
                                            >
                                            <div className="item collab-items">
                                                <div className="colaboration-content">
                                                    <h2 className="title">Cost-Effective Integration</h2>
                                                    <p>
                                                        NEOIN offers an <span className='first-word'>affordable solution for banks</span>, with cost-effective integration processes tailored to streamline implementation and minimize expenses.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item collab-items">
                                                <div className="colaboration-content">
                                                    <h2 className="title">Flexible Licensing Options</h2>
                                                    <p>
                                                        Banks can benefit from NEOIN's flexible licensing options, allowing them to <span className='first-word'>choose the most suitable package</span> that aligns with their budget and requirements.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item collab-items">
                                                <div className="colaboration-content">
                                                    <h2 className="title">Scalable Solutions</h2>
                                                    <p>
                                                        NEOIN provides scalable solutions that grow with the bank's needs, ensuring that the <span className='first-word'>investment remains affordable both now and in the future</span>.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item collab-items">
                                                <div className="colaboration-content">
                                                    <h2 className="title">Efficiency Savings</h2>
                                                    <p>
                                                        By leveraging NEOIN's efficient technology and streamlined processes, banks can <span className='first-word'>save on operational costs, making it a financially viable</span> option for institutions of all sizes.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item collab-items">
                                                <div className="colaboration-content">
                                                    <h2 className="title">Value-Driven Features</h2>
                                                    <p>
                                                        Despite its affordability, NEOIN doesn't compromise on quality or functionality, offering banks a comprehensive suite of features that <span className='first-word'>add value to their services without breaking the bank</span>.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item collab-items">
                                                <div className="colaboration-content">
                                                    <h2 className="title">ROI Assurance</h2>
                                                    <p>
                                                        With NEOIN, banks can expect a solid return on investment (ROI) through <span className='first-word'>enhanced customer satisfaction, increased efficiency, and reduced overheads, making it a financially prudent choice</span> for any banking institution.
                                                    </p>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                </div>
                                {/* <div className="button-group">
                            <a href="#0" className="button-3 bank">Start a free trial</a>
                            <a href="#0"  className="button-3 bank">View pricing</a>
                        </div> */}
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6">
                            <div className="range-wrapper">
                                <div className="shape-1 d-none d-lg-block">
                                    <img src={bg1} alt="bg" />
                                </div>
                                <div className="shape-2 d-none d-lg-block">
                                    <img src={bg2} alt="bg" />
                                </div>
                                <div className="pricing-range">
                                    <img class="fit" src={BankingEfficiency} alt='Banking Efficiency Solutions' />

                                    {/* <div className="pricing-range-top">
                                <div className="pricing-header">
                                    <span className="cate">Pricing Options</span>
                                    <div className="select-container">    
                                        <select className="select-bar">
                                            <option value="basic">Basic</option>
                                            <option value="standard">Standard</option>
                                            <option value="premium">Premium</option>
                                            <option value="pro">Premium Pro</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="amount-area">
                                    <div className="item">
                                        <h2 className="title"><sup>$</sup>15.00</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="item">
                                        <h2 className="title"><sup>$</sup>199.00</h2>
                                        <span className="info">Per Year</span>
                                    </div>
                                </div>
                                <div className="invest-range-area">
                                    <div className="invest-amount" data-min="1.00 USD" data-max="1000 USD">
                                        <div id="usd-range" className="invest-range-slider"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-range-bottom">
                                <a href="#0" className="custom-button">Download App</a>
                                <ul className="download-options">
                                    <li>
                                        <a href="#0" className="active"><i className="fab fa-windows"></i></a>
                                    </li>
                                    <li>
                                        <a href="#0"><i className="fab fa-apple"></i></a>
                                    </li>
                                    <li>
                                        <a href="#0"><i className="fab fa-android"></i></a>
                                    </li>
                                </ul>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Pricing Section Ends Here =============--> */}


            { /* <!--============= Newslater Section Starts Here =============--> */}
            <section className="newslater-section oh subscribe-pt">
                <div className="bg_img_newsletter right_center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block">
                                <div className="rtl subscribe-thumb">
                                    <img src={financialJourney} alt="newslater" style={{ "width": "670px" }} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="newslater-area padding-bottom-2 padding-top">
                                    <div className="section-header left-style cl-white">
                                        <h5 className="cate">Secure, Smart Banking</h5>
                                        <h2 className="title">Your Financial Journey, Our Expert Guidance</h2>
                                        <p>
                                            Discover tailored financial solutions with our comprehensive services for Banks and Non-Banking Financial Companies (NBFCs). Access cutting-edge banking technologies, robust risk management tools, and personalized financial strategies designed to optimize your operations and drive growth.
                                        </p>
                                    </div>
                                    {/* <form className="subscribe-form">
                                <input type="text" placeholder="Enter Your Email" />
                                <button type="submit">subscribe</button>
                            </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Newslater Section Ends Here =============--> */}


            { /* <!--============= Coverage Section Starts Here =============--> */}
            {/* <section className="coverage-section padding-top padding-bottom-2" id="coverage">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="section-header left-style coverage-header">
                        <h5 className="cate">Our stats say more than any words</h5>
                        <h2 className="title">Apps Without Borders</h2>
                        <p>
                            Mosta app are growing by 300% every year with a steady love from users around the world. We are also close to achieving 10 million cumulative downloads.
                        </p>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="coverage-right-area text-lg-right">
                        <div className="rating-area">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <span className="average">5.0 / 5.0</span>
                        </div>
                        <h2 className="amount">312,921+</h2>
                        <a href="#0">Total User Reviews <i className="fas fa-paper-plane"></i></a>
                    </div>
                </div>
            </div>
            <div className="coverage-wrapper bg_img_worldmap">
                <div className="border-item-1">
                    <span className="name">North America</span>
                    <h2 className="title">70.7%</h2>
                </div>
                <div className="border-item-2">
                    <span className="name">Asia</span>
                    <h2 className="title">14.4%</h2>
                </div>
                <div className="border-item-3">
                    <span className="name">North Europe</span>
                    <h2 className="title">8.4%</h2>
                </div>
                <div className="border-item-4">
                    <span className="name">South America</span>
                    <h2 className="title">1.8%</h2>
                </div>
                <div className="border-item-5">
                    <span className="name">Africa</span>
                    <h2 className="title">1.8%</h2>
                </div>
                <div className="border-item-6">
                    <span className="name">Australia</span>
                    <h2 className="title">3%</h2>
                </div>
            </div>
        </div>
    </section> */}
            { /* <!--============= Coverage Section Ends Here =============--> */}


            { /* <!--============= Testimonial Section Starts Here =============--> */}
            {/* <Review /> */}
            {/* <section className="testimonial-section padding-top-2">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">Testimonials</h5>
                <h2 className="title">5000+ happy clients all around the world</h2>
            </div>
            <div className="testimonial-wrapper">
                <a href="#0" className="testi-next trigger">
                    <img src={clientleft} alt="client" />
                </a>
                <a href="#0" className="testi-prev trigger">
                    <img src={clientright} alt="client" />
                </a>
                <div className="testimonial-area testimonial-slider owl-carousel owl-theme">
                    <div className="testimonial-item">
                        <div className="testimonial-thumb">
                            <div className="thumb">
                                <img src={client1} alt="client" />
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>
                                Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                            </p>
                            <h5 className="title"><a href="#0">Bela Bose</a></h5>
                        </div>
                    </div>
                    <div className="testimonial-item">
                        <div className="testimonial-thumb">
                            <div className="thumb">
                                <img src={client2} alt="client" />
                            </div>
                        </div>
                        <div className="testimonial-content">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <p>
                                Awesome product. The guys have put huge effort into this app and focused on simplicity and ease of use.
                            </p>
                            <h5 className="title"><a href="#0">Raihan Rafuj</a></h5>
                        </div>
                    </div>
                </div>
                <div className="button-area">
                    <a href="#0" className="button-2">Leave a review <i className="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </section> */}
            { /* <!--============= Testimonial Section Ends Here =============--> */}


            { /* <!--============= Trial Section Starts Here =============--> */}
            <section className="trial-section padding-bottom-2 padding-top-2">
                <div className="container">
                    <div className="trial-wrapper padding-top padding-bottom pr">
                        <div className="ball-1">
                            <img src={balls} alt="balls" />
                        </div>
                        <div className="trial-content cl-white">
                            <h3 className="title">Customize your Plan!</h3>
                            <p>
                                Get Connected with our <br /> Sales Team for more Information.
                            </p>
                        </div>
                        <div className="trial-button">
                            <a href="/contact" className="transparent-button">Contact Us <i className="flaticon-right ml-xl-2"></i></a>
                        </div>
                    </div>
                </div>
            </section>
            { /* <!--============= Trial Section Ends Here =============--> */}
            <Download />
        </div>
    )
};

export default Bank;
