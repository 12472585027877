import React, { useState, useEffect } from 'react';
import work1 from "../assets/images/assn/Register.png";
import work2 from "../assets/images/assn/Customize.png";
import work3 from "../assets/images/assn/Onboard.png";
import work4 from "../assets/images/assn/Engage.png";
import work_shape from "../assets/css/img/work-shape.png"
import left from "../assets/images/feature/back.svg";
import right from "../assets/images/feature/next.svg";
import OwlCarousel from 'react-owl-carousel';

const FeatureItem = ({ title, description, image, active }) => (
  <div className={`work-item ${active ? 'active' : ''}`}>
    <div className="work-thumb">
      <img src={image} alt={title} />
    </div>
    <div className="work-content cl-white">
      <h3 className="title">{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);

const Carousel = ({ features, currentSlide, handleCounterClick }) => {
  const handleRightClick = () => {
    handleCounterClick((currentSlide + 1) % features.length);
  };

  const handleLeftClick = () => {
    handleCounterClick((currentSlide - 1 + features.length) % features.length);
  };

  useEffect(() => {
    const leftIcon = document.querySelector(".carousel-control.left img");
    const rightIcon = document.querySelector(".carousel-control.right img");

    if (currentSlide ==0) {
      leftIcon.style.display = "none";
    }
    else{
       leftIcon.style.display = "block";
    }
    
    if (currentSlide ==3) {
        rightIcon.style.display = "none";
    }
    else{
        rightIcon.style.display = "block";
    }
  }, [currentSlide]);

  return (
    <>
      <section className="work-section bg_img_alumni_2 mb-md-95 pb-md-0">
        <div className="oh padding-top pos-rel">
          <div className="top-shape d-none d-lg-block">
            <img src={work_shape} alt="css" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-7">
                <div className="section-header left-style cl-white">
                  <h5 className="cate">Discover How It Works</h5>
                  <h2 className="title">Step-by-Step Onboarding for Associations</h2>
                  <p>It's easier than you think.</p>
                </div>
              </div>
            </div>
            <span className={`carousel-control left`} onClick={handleLeftClick}>
              <img src={left} alt="Left Icon" />
            </span>

            <OwlCarousel
              className="padding-bottom-2 mb--50"
              loop
              margin={30}
              responsiveClass
              nav={false}
              dots={false}
              animateOut="fadeOut" // Use 'fadeOut' for smoother fade-out transitions
              animateIn="fadeIn" // Use 'fadeIn' for smoother fade-in transitions
              smartSpeed={1000} // Adjust the speed of the transitions (in milliseconds)
              autoplayTimeout={2000}
              autoplayHoverPause
              items={1}
              startPosition={currentSlide} // Set the initial slide position
            >
              {features.map((feature, index) => (
                <FeatureItem
                  key={index}
                  title={feature.title}
                  description={feature.description}
                  image={feature.image}
                  active={index === currentSlide}
                />
              ))}
            </OwlCarousel>

            <span className={`carousel-control right`} onClick={handleRightClick}>
              <img src={right} alt="Right Icon" />
            </span>
          </div>
        </div>
        <div className="container mob-pb-20">
          <div className="count-slider owl-thumbs" data-slider-id="2">
            {features.map((feature, index) => (
              <div className="count-item" key={index} onClick={() => handleCounterClick(index)}>
                <span className="serial">0{index + 1}</span>
                <h5 className="title">{feature.title}</h5>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const CarouselNew = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const features = [
    {
      title: "Register",
      description: `Begin by registering and verifying your account on our platform to access exclusive benefits and receive your digital business card for sharing your information.`,
      image: work1,
    },
    {
      title: "Customize",
      description: ` Personalize your digital business card and connect with peers, explore resources, and engage in meaningful activities within alumni and society communities with consent.`,
      image: work2,
    },
    {
      title: "Onboard",
      description: `Navigate through our user-friendly onboarding process designed specifically for association users. Easily set up your profile, preferences, and privacy settings to tailor your experience.`,
      image: work3,
    },
    {
      title: "Engage",
      description: `Explore virtual mentorships, event management, and other engaging features tailored to your interests within the association network.`,
      image: work4,
    },
  ];

  const handleCounterClick = (index) => {
    setCurrentSlide(index);
  };

  return <Carousel features={features} currentSlide={currentSlide} handleCounterClick={handleCounterClick} />;
};

export default CarouselNew;
