import React from "react";
import pageheader from "../assets/css/img/page-header.png";
// import pageheader1 from "../assets/images/page-header.png";
import sponsor1 from "../assets/images/banner/banner16/01.png"
import sponsor2 from "../assets/images/banner/banner16/02.png"
import sponsor3 from "../assets/images/banner/banner16/03.png"
import sponsor4 from "../assets/images/banner/banner16/04.png"
import sponsor5 from "../assets/images/banner/banner16/05.png"
import sponsor6 from "../assets/images/banner/banner16/06.png"
import sponsor7 from "../assets/images/banner/banner16/05.png"
import sponsor8 from "../assets/images/banner/banner16/03.png"
// import usercounter from "../assets/images/bg/user-counter-bg.png";
// import trial2 from "../assets/images/bg/trial2.png";

import Counter from "./Counter";

import { useScrollToTop } from "./UseScrollToTop";

function Partners(){

    useScrollToTop();

    return(

        <>
        
    <section className="page-header bg_img_pg_header">
        <div className="bottom-shape d-none d-md-block">
            <img src={pageheader} alt="css"/>
        </div>
        <div className="container">
            <div className="page-header-content cl-white">
                <h2 className="title">Our Partners</h2>
                <ul className="breadcrumb">
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        Our Partners
                    </li>
                </ul>
            </div>
        </div>
    </section>


    
    {/* <!--============= Partner Section Starts Here =============--> */}
    <section className="partner-section padding-top-2 padding-bottom-2 oh">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9">
                    <div className="section-header mob-pt-40 mw-100">
                        <h5 className="cate">Meet With Our Valuable Partner</h5>
                        <h2 className="title">They trust us... would you?</h2>
                        <p>
                            Our partners are some of the most reputable lenders on the market. Join their ranks and cooperate with us to increase your profit.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-30-none">
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                            <img src={sponsor1} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                            <img src={sponsor2} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                        <img src={sponsor3} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                        <img src={sponsor4} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                        <img src={sponsor5} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                        <img src={sponsor6} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                        <img src={sponsor7} alt="sponsor"/>
                        </a>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                    <div className="partner-item">
                        <a href="#0">
                        <img src={sponsor8} alt="sponsor"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Partner Section Ends Here =============-->


    <!--============= User Counter Section Starts Here =============--> */}
    <section className="user-counter padding-bottom mob-pb-40 oh">
        <div className="container">
            <div className="user-counter-wrapper padding-bottom padding-top">
                <div className="user-counter-bg bg_contain bg_img_userCounter"></div>
                <div className="section-header cl-white mb-0">
                    <h5 className="cate">Worldwide</h5>
                    <h2 className="title"><Counter end={2756}></Counter></h2>
                    <p>Clients Satisfied with our services</p>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= User Counter Section Ends Here =============-->


    <!--============= Join Team Section Ends Here =============--> */}
    <section className="trial-section padding-bottom">
        <div className="container">
            <div className="trial-wrapper padding-top padding-bottom bg_img_trial2 style-three">
                <div className="trial-content cl-white">
                    <h3 className="title m-0">Are You Want to Join Our Team?</h3>
                </div>
                <div className="trial-button">
                    <a href="#0" className="button-3 long-light active text-light">JOIN OUR TEAM <i className="flaticon-right ml-xl-2"></i></a>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Join Team Section Ends Here =============--> */}

        </>

    )

}

export default Partners