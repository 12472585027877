import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Importing Routes, Route, and useLocation
import Footer from './components/Footer';
import Header from './components/Header';
import Aboutus from './components/Aboutus';
import Home from './components/Index9'; // Assuming this is your Home component
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
// import Solution from './components/Solution';
import DigitalEngage from './components/DigitalEngage';
// import Saas from './components/Saas';
// import Enterprise from './components/Enterprise';
import Blog from './components/Blog';
import Faq from './components/Faq';
import Partners from './components/Partners';
import Terms from './components/Terms';
import Testimonials from './components/Testimonials';
import Comingsoon from './components/Comingsoon';
import Login from './components/Login';
import SME from './components/SME';
import GenZ from './components/Genz';
// import Corporate from  './components/Corporate';
import Professional from  './components/Professional';
// import Clubsandsociety from './components/Clubsandsociety';
import Association from './components/Associations';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/PasswordChange';
// import DocumentService from './components/DocumentService';
// import AccessManage from './components/Accessmanage';
import DigitalID from './components/DigitalID';
import Kyc from './components/Kyc';
import Events from './components/Eventsconference';
import Bank from './components/Bank';
import Page404 from './components/Page404';
import Dalocalcommingsoon from './components/Dalocalcommingsoon';
import Features from './components/Features';
import Sitemap from './components/Sitemap'
import Careers from './components/Careers';
import ProductLogin from './components/ProductLogin';
import Individual from './components/Individuals';
import Student from './components/Students';
import Help from './components/Help'
import LoginNew from './components/Login_New'
import AddReview from './components/Addreview'
import Demo from './components/Demo'
import Cookies from './components/Cookies'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Captcha from './components/Captcha';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <HeaderAndFooter />
      </Router>
    </div>
  );
}

function HeaderAndFooter() {
  const location = useLocation();

  // Check if the current location is the login page
  if (location.pathname === '/login' || location.pathname==='/login_new') {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login_new" element={<LoginNew showCaptcha={true}/>} />
      </Routes>
    );
  }

  return (
    <div>
      <Header />
      <Routes> {/* Using Routes instead of Route */}
        {/* Define your routes here */}
        <Route path="/" element={<Home />} /> {/* Using element prop */}
        <Route path="/about" element={<Aboutus />} /> {/* Using element prop */}
        <Route path="/pricing" element={<Pricing />} /> {/* Using element prop */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path='/sme' element={<SME />} />
        <Route path='/genz' element={<GenZ />} />
        <Route path="/events" element={<Events />} />
                {/* <Route path="/corporate" element={<Corporate />} /> */}
        {/* <Route path="/club" element={<Clubsandsociety />} /> */}
        <Route path="/associations" element={<Association />} />
        {/* <Route path="/solution" element={<Solution />} /> */}
        <Route path="/digital_engagement" element={<DigitalEngage />} />
        {/* <Route path="/captcha" element={<Captcha />} /> */}
        {/* <Route path="/consumer-saas" element={<Saas />} />
        <Route path="/enterprise-saas" element={<Enterprise />} /> */}
        <Route path="/blogs" element={<Blog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
        <Route path="/reset" element={<ResetPassword />} />
        {/* <Route path="/doc-services" element={<DocumentService />} />
        <Route path="/access-mgmt" element={<AccessManage />} /> */}
        <Route path="/digital-card" element={<DigitalID />} />
        <Route path="/kyc_cdd" element={<Kyc />} />
        <Route path="/bank" element={<Bank />} />
        <Route path="/professionals" element={<Professional />} />
        <Route path="/change" element={<ChangePassword />} />
        <Route path="/dalocalcommingsoon" element={<Dalocalcommingsoon />} />
        <Route path="/feature" element={<Features />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/productlogin" element={<ProductLogin />} />
        <Route path="/individuals" element={<Individual />} />
        <Route path="/students" element={<Student />} />
        <Route path="/help" element={<Help />} />
        <Route path="/login_new" element={<LoginNew showCaptcha={true}/>} />
        <Route path="/addreview" element={<AddReview />} />
        <Route path="/demo" element={<Demo />} />

        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;