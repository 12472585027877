import React from 'react'
// import Bannerbg from "../assets/images/extra-2/banner/banner-bg-20-dark.jpg"
// import Bannerimg from "../assets/images/extra-2/banner/banner-20.png"
import Bannerimg from "../assets/images/kyc/kyc-cdd-banner.png"
// import Banner01 from "../assets/images/banner/banner16/01.png"
// import Banner02 from "../assets/images/banner/banner16/02.png"
// import Banner03 from "../assets/images/banner/banner16/03.png"
// import Banner04 from "../assets/images/banner/banner16/04.png"
// import Banner05 from "../assets/images/banner/banner16/05.png"
// import Banner06 from "../assets/images/banner/banner16/06.png"
// import Featurebg from "../assets/images/extra-2/feature/feature-bg.png"
// import Phone from "../assets/images/extra-2/feature/phone.png"
// import Phone02 from "../assets/images/extra-2/feature/phone2.png"
// import Phone03 from "../assets/images/extra-2/feature/phone3.png"
import KycFeatures from "../assets/images/kyc/Accelerate Compliance.png"

// import Feature01 from "../assets/images/extra-2/feature/01.png"
import Identity from "../assets/images/kyc/icons/Identity Verification.svg"
import Risk from "../assets/images/kyc/icons/Risk Assessment.svg"
import Compliance from "../assets/images/kyc/icons/Compliance Monitoring.svg"
import Diligence from "../assets/images/kyc/icons/Enhance Due Deligince.svg"
import Integration from "../assets/images/kyc/icons/Seamless integration.svg"

import { ReactComponent as How1 } from "../assets/images/kyc/icons/Simplified Verification.svg"
import { ReactComponent as How2 } from "../assets/images/kyc/icons/Automated Validation.svg"
import { ReactComponent as How3 } from "../assets/images/kyc/icons/Regulatory Compliance.svg"

import Background from "../assets/images/extra-2/screenshot/background.png"
// import Group1 from "../assets/images/extra-2/screenshot/group1.png"
// import Group2 from "../assets/images/extra-2/screenshot/group2.png"

import simplifiedKyc from "../assets/images/kyc/Simplified KYC _Option 02.png"
import reliableCdd from "../assets/images/kyc/Reliable CDD Solutions.png"
import OwlCarousel from 'react-owl-carousel';

// import Client03 from "../assets/images/client/client03.png"
// import Client01 from "../assets/images/client/client01.png"
// import Client02 from "../assets/images/client/client02.png"
// import Client04 from "../assets/images/client/client04.png"
// import Client05 from "../assets/images/client/client05.png"


// import Mocup from "../assets/images/extra-2/screenshot/mocup.png"
// import Mocupscreen from "../assets/images/extra-2/screenshot/mocup-screen.png"

function DigitalCampus() {
  return (
    <div>
       {/* <!--============= Banner Section Starts Here =============--> */}
       <section className="banner-20 pos-rel oh bg_img_bnr_20 mob-pb-40">
        <div className="container">
            <div className="row flex-wrap-reverse align-items-center justify-content-lg-between">
                <div className="col-lg-5">
                    <div className="banner-thumb-20 rtl wow slideInLeft">
                        <img src={Bannerimg} alt="extra-2/banner"/>
                        {/* <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="video-button popup">
                            <i className="flaticon-play"></i></a> */}
                    </div>
                </div>
                <div className="col-lg-6 p-lg-0">
                    <div className="banner-content-20">
                        <h1 className="Newtitle">
                        KYC & CDD Solutions by Neoin</h1>
                        <p>
                        Neoin's comprehensive KYC & CDD solutions streamline customer onboarding, mitigate risks, and ensure regulatory compliance with advanced technology.
                        </p>
                        <a href="#0" className="button-4">Create ID</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Banner Section Ends Here =============--> */}


    {/* <!--============= Logo Slider Section Starts Here =============--> */}
    {/* <section className="logo-slider-section section--bg padding-bottom padding-top">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 text-center mb-4 mb-lg-5">
                    <h5 className="title">Trusted by the World's Best Team</h5>
                </div>
            </div>
            <div className="logo-slider owl-theme owl-carousel">
                <div className="item">
                    <img src={Banner01} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner02} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner03} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner04} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner05} alt="banner"/>
                </div>
                <div className="item">
                    <img src={Banner06} alt="banner"/>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Logo Slider Section Ends Here =============--> */}




    {/* <!--============= How Section Starts Here =============--> */}
    <section className="how-section-20 ovelflow-hidden mob-pt-40 mob-pb-40">
        <div className="container">
            <div className="row align-items-end">
                <div className="col-lg-4 col-xl-5">
                    <div className="move-top--lg-70">
                        <div className="section-header left-style mb-olpo">
                            <h5 className="cate">Verify with Ease</h5>
                            <h2 className="title">Simplified KYC Process</h2>
                            <p>Neoin's streamlined KYC procedure prioritizes security and compliance while ensuring a seamless experience for users.</p>
                        </div>
                        <div className="how-item-wrapper-20">
                            <div className="how-item-20 kyc-icon">
                                <h6 className="title">Simplified Verification</h6>
                                <div className="how-thumb">
                                    <How1 className='kyc-icon-svg'/>
                                </div>
                            </div>
                            <div className="how-item-20 kyc-icon">
                                <h6 className="title">Automated Validation</h6>
                                <div className="how-thumb">
                                    <How2 className='kyc-icon-svg'/>
                                </div>
                            </div>
                            <div className="how-item-20 kyc-icon">
                                <h6 className="title">Regulatory Compliance</h6>
                                <div className="how-thumb">
                                    <How3 className='kyc-icon-svg'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-xl-7 kyc-feature-circle mob-pt-40">
                    {/* <div className="how-slider-wrapper mt-4 mt-lg-0 ml--lg-78 mr--lg-65">
                        <div className="how-slider-20 owl-theme owl-carousel">
                            <div className="thumbs">
                                <img src={Phone02} alt="extra-2/feature"/>
                            </div>
                            <div className="thumbs">
                                <img src={Phone03} alt="extra-2/feature"/>
                            </div>
                            <div className="thumbs">
                                <img src={Phone02} alt="extra-2/feature"/>
                            </div>
                            <div className="thumbs">
                                <img src={Phone03} alt="extra-2/feature"/>
                            </div>
                        </div>
                        <div className="dots-2"></div>
                    </div> */}
                    <div>
                        <img src={simplifiedKyc} alt="Features Circle" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= How Section Ends Here =============--> */}
    

    {/* <!--============= Pricing Section Starts Here =============--> */}
    <section className="apps-screenshot-section-20 padding-top-2 padding-bottom-2 pb-max-lg-0 overflow-hidden position-relative reliable-cdd-solutions">
        <div className="app-screenshot-20 d-none d-lg-flex">
            <img src={Background} alt="extra-2/screenshot" className='d-lg-block d-none' />
        </div>
        <div className="container">
            <div className="row justify-content-lg-between justify-content-center align-items-center flex-wrap-reverse">
                <div className="col-md-8 col-lg-7 col-xl-7">
                    {/* <div className="app-screenshot-slider-20 owl-theme owl-carousel">
                        <div className="thumbs">
                            <img src={Group1} alt="extra-2/screenshot"/>
                        </div>
                        <div className="thumbs">
                            <img src={Group2} alt="extra-2/screenshot"/>
                        </div>
                        <div className="thumbs">
                            <img src={Group1} alt="extra-2/screenshot"/>
                        </div>
                    </div> */}
                    <div>
                        <img class="fit phish-phone" src={reliableCdd} alt="Reliable CDD" />
                    </div>
                </div>
                <div className="col-lg-5 col-xl-5">
                    <div className="mb-3 mb-lg-0">
                        <div className="section-header left-style mb-olpo">
                            <h5 className="cate">A beautiful way to present</h5>
                            <h2 className="title">Reliable CDD Solutions</h2>
                            <p>Neoin's CDD process is designed to enhance security measures and protect against financial crime, fostering trust and transparency with our users.</p>
                            {/* <div className="feat-nav mt-0">
                                <a href="#0" className="feat-prev"><i className="flaticon-left"></i></a>
                                <a href="#0" className="feat-next active"><i className="flaticon-right"></i></a>
                            </div> */}
                        </div>
                        {/* <h6 className="title mb-4">Ready to get started?</h6>
                        <ul className="download-options justify-content-start download-option-20">
                            <li>
                                <a href="#0" className="active"><i className="fab fa-apple"></i></a>
                                <span>Android</span>
                            </li>
                            <li>
                                <a href="#0"><i className="fab fa-android"></i></a>
                                <span>ios</span>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Pricing Section Starts Here =============--> */}


    {/* <!--============= Pricing Section Starts Here =============--> */}
    {/* <section className="pricing-section-20 body--bg position-relative padding-bottom padding-top">
        <div className="container">
            <div className="section-header">
                <h5 className="cate">An Exhaustive list of Amazing Features</h5>
                <h2 className="title">Simple,Affordable 
                    Pricing Plans.</h2>
                <p>
                    Mosto has plans, from free to paid, that scale with your needs. Subscribe to  a plan that fits  the size of your business.
                </p>
            </div>
            <div className="tab">
                <ul className="tab-menu pricing-menu cl-black">
                    <li className="active">Monthly</li>
                    <li>Yearly</li>
                </ul>
                <div className="tab-area">
                    <div className="tab-item active">
                        <div className="row mb-30-none justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="pricing-item-20">
                                    <div className="pricing-header">
                                        <span className="name">BRONXE</span>
                                        <h2 className="price"><sup>$</sup>15.99</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="pricing-body">
                                        <ul>
                                            <li>
                                                10 projects
                                            </li>
                                            <li>
                                                Unlimited hosted projects
                                            </li>
                                            <li>
                                                Real-Time Feedback
                                            </li>
                                            <li>
                                                Code export
                                            </li>
                                            <li>
                                                24/7 Chat Support
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer text-center">
                                        <a href="#0" className="button-4">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="pricing-item-20">
                                    <div className="pricing-header">
                                        <span className="name">SILVER</span>
                                        <h2 className="price"><sup>$</sup>35.99</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="pricing-body">
                                        <ul>
                                            <li>
                                                10 projects
                                            </li>
                                            <li>
                                                Unlimited hosted projects
                                            </li>
                                            <li>
                                                Real-Time Feedback
                                            </li>
                                            <li>
                                                Code export
                                            </li>
                                            <li>
                                                24/7 Chat Support
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer text-center">
                                        <a href="#0" className="button-4">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="pricing-item-20">
                                    <div className="pricing-header">
                                        <span className="name">GOLD</span>
                                        <h2 className="price"><sup>$</sup>65.99</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="pricing-body">
                                        <ul>
                                            <li>
                                                10 projects
                                            </li>
                                            <li>
                                                Unlimited hosted projects
                                            </li>
                                            <li>
                                                Real-Time Feedback
                                            </li>
                                            <li>
                                                Code export
                                            </li>
                                            <li>
                                                24/7 Chat Support
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer text-center">
                                        <a href="#0" className="button-4">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-item">
                        <div className="row mb-30-none justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="pricing-item-20">
                                    <div className="pricing-header">
                                        <span className="name">BRONXE</span>
                                        <h2 className="price"><sup>$</sup>15.99</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="pricing-body">
                                        <ul>
                                            <li>
                                                10 projects
                                            </li>
                                            <li>
                                                Unlimited hosted projects
                                            </li>
                                            <li>
                                                Real-Time Feedback
                                            </li>
                                            <li>
                                                Code export
                                            </li>
                                            <li>
                                                24/7 Chat Support
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer text-center">
                                        <a href="#0" className="button-4">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="pricing-item-20">
                                    <div className="pricing-header">
                                        <span className="name">SILVER</span>
                                        <h2 className="price"><sup>$</sup>35.99</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="pricing-body">
                                        <ul>
                                            <li>
                                                10 projects
                                            </li>
                                            <li>
                                                Unlimited hosted projects
                                            </li>
                                            <li>
                                                Real-Time Feedback
                                            </li>
                                            <li>
                                                Code export
                                            </li>
                                            <li>
                                                24/7 Chat Support
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer text-center">
                                        <a href="#0" className="button-4">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="pricing-item-20">
                                    <div className="pricing-header">
                                        <span className="name">GOLD</span>
                                        <h2 className="price"><sup>$</sup>65.99</h2>
                                        <span className="info">Per Month</span>
                                    </div>
                                    <div className="pricing-body">
                                        <ul>
                                            <li>
                                                10 projects
                                            </li>
                                            <li>
                                                Unlimited hosted projects
                                            </li>
                                            <li>
                                                Real-Time Feedback
                                            </li>
                                            <li>
                                                Code export
                                            </li>
                                            <li>
                                                24/7 Chat Support
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer text-center">
                                        <a href="#0" className="button-4">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Pricing Section Ends Here =============--> */}

    
    {/* <!--============= Feature Section Starts Here =============--> */}
    <section className="feature-section ovelflow-hidden padding-bottom-2 padding-top-2">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">Features</h5>
                        <h2 className="title">Accelerate Compliance with KYC & CDD Features</h2>
                        <p className="mx-auto mw-540px">
                        Explore Neoin's KYC & CDD solutions—advanced tools for streamlined verification, risk mitigation, and regulatory compliance.
                        </p>
                    </div>

                </div>
            </div>
            <div className="feature-wrapper-20">
                <div className="feature-wrapper-bg-20 bg_img_featureBg">
                    <div className="row align-items-center">
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="feature-thumb-20 rtl">
                                <img src={KycFeatures} alt="" className='Kyc-features-thumb'/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="feature-content-20 cl-white">
                            <div>
      <OwlCarousel className="kyc-feature-carousel feature-content-slider-20 owl-theme owl-carousel" margin={10} items={1} dots={false} nav={true} navText={[
          "<i class='flaticon-left'></i>",
          "<i class='flaticon-right'></i>"
        ]} loop>
        <div className="feature-content-item-20">
          <div className="feature-content-icon-18">
            <img src={Identity} alt="extra-2/feature" />
          </div>
          <h3 className="title">
            Identity Verification
          </h3>
          <p>
            Neoin's KYC solutions utilize advanced identity verification techniques, including document verification, biometric authentication, and facial recognition technology, to verify the identity of customers accurately and securely.
          </p>
        </div>
        <div className="feature-content-item-20">
          <div className="feature-content-icon-18">
            <img src={Risk} alt="extra-2/feature" />
          </div>
          <h3 className="title">
            Risk Assessment
          </h3>
          <p>
            Our platform employs sophisticated risk assessment algorithms and data analysis tools to evaluate customer risk profiles and detect potential fraudulent activities, ensuring enhanced security and compliance with regulatory requirements.
          </p>
        </div>
        <div className="feature-content-item-20">
          <div className="feature-content-icon-18">
            <img src={Compliance} alt="extra-2/feature" />
          </div>
          <h3 className="title">
            Compliance Monitoring
          </h3>
          <p>
            Neoin enables all professional and other organizations to monitor and manage compliance with KYC & CDD regulations effectively. Our platform automates compliance checks, generates audit trails, and provides real-time reporting to facilitate regulatory oversight and risk management.
          </p>
        </div>
        <div className="feature-content-item-20">
          <div className="feature-content-icon-18">
            <img src={Diligence} alt="extra-2/feature" />
          </div>
          <h3 className="title">
            Enhanced Due Diligence
          </h3>
          <p>
            With Neoin's CDD solutions, all industries can conduct enhanced due diligence procedures on high-risk customers or transactions. Our platform offers customizable risk assessment criteria, enhanced monitoring capabilities, and enhanced reporting features to support thorough due diligence processes.
          </p>
        </div>
        <div className="feature-content-item-20">
          <div className="feature-content-icon-18">
            <img src={Integration} alt="extra-2/feature" />
          </div>
          <h3 className="title">
            Seamless Integration
          </h3>
          <p>
            Neoin's KYC & CDD solutions seamlessly integrate with existing systems and workflows, enabling organizations to streamline customer onboarding processes and enhance operational efficiency.
          </p>
        </div>
      </OwlCarousel>
      {/* <div className="feat-nav mt-4">
        <OwlCarousel className="owl-theme feat-nav" margin={10}>
          <div className="item">
            <a href="#0" className="feat-prev"><i className="flaticon-left"></i></a>
          </div>
          <div className="item">
            <a href="#0" className="feat-next active"><i className="flaticon-right"></i></a>
          </div>
        </OwlCarousel>
      </div> */}
    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--============= Feature Section Ends Here =============--> */}


    {/* <!--============= Testimonial Section Starts Here =============--> */}
    {/* <section className="testimonial-section up--down--overflow section--bg padding-bottom padding-top">
        <div className="container">
            <div className="row flex-wrap-reverse align-items-center mb-30-none">
                <div className="col-lg-6">
                    <div className="row align-items-center mb-30-none">
                        <div className="col-md-6">
                            <div className="review-item-20">
                                <div className="review-item-header">
                                    <div className="thumb">
                                        <img src={Client03} alt="extra-2/client"/>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Rudy Riley</h6>
                                        <span className="info">CEO & Founder</span>
                                    </div>
                                </div>
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <blockquote>
                                    “Always think outside the box and embrace opportunities that appear, wherever they might be.”
                                </blockquote>
                                <strong>Awesome App!</strong>
                            </div>
                            <div className="review-item-20 active">
                                <div className="review-item-header">
                                    <div className="thumb">
                                        <img src={Client02} alt="extra-2/client"/>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Rudy Riley</h6>
                                        <span className="info">CEO & Founder</span>
                                    </div>
                                </div>
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <blockquote>
                                    “Always think outside the box and embrace opportunities that appear, wherever they might be.”
                                </blockquote>
                                <strong>Awesome App!</strong>
                            </div>
                            <div className="review-item-20">
                                <div className="review-item-header">
                                    <div className="thumb">
                                        <img src={Client01} alt="extra-2/client"/>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Rudy Riley</h6>
                                        <span className="info">CEO & Founder</span>
                                    </div>
                                </div>
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <blockquote>
                                    “Always think outside the box and embrace opportunities that appear, wherever they might be.”
                                </blockquote>
                                <strong>Awesome App!</strong>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="review-item-20">
                                <div className="review-item-header">
                                    <div className="thumb">
                                        <img src={Client04} alt="extra-2/client"/>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Rudy Riley</h6>
                                        <span className="info">CEO & Founder</span>
                                    </div>
                                </div>
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <blockquote>
                                    “Always think outside the box and embrace opportunities that appear, wherever they might be.”
                                </blockquote>
                                <strong>Awesome App!</strong>
                            </div>
                            <div className="review-item-20">
                                <div className="review-item-header">
                                    <div className="thumb">
                                        <img src={Client05} alt="extra-2/client"/>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Rudy Riley</h6>
                                        <span className="info">CEO & Founder</span>
                                    </div>
                                </div>
                                <div className="ratings">
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                    <span><i className="fas fa-star"></i></span>
                                </div>
                                <blockquote>
                                    “Always think outside the box and embrace opportunities that appear, wherever they might be.”
                                </blockquote>
                                <strong>Awesome App!</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-5 mb-lg-0">
                        <div className="section-header left-style mb-olpo">
                            <h5 className="cate">Testimonials</h5>
                            <h2 className="title">Don’t just take our word for it!</h2>
                            <p>Thousands of professionals and business are already enjoying Apps. Hear what some of they are saying!</p>
                        </div>
                        <a href="#0" className="button-4">get started</a>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Testimonial Section Ends Here =============--> */}


    {/* <!--============= Apps Download Section Starts Here =============--> */}
    {/* <section className="apps-download-section overflow-hidden padding-top padding-bottom body--bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-9">
                    <div className="section-header mw-100">
                        <h5 className="cate">An Exhaustive list of Amazing Features</h5>
                        <h2 className="title">Ready to take your productivity to the next level?</h2>
                        <p className="mx-auto mw-540px">
                            Are you ready to make the leap? Download Apps for iOS or Android today — it's free.
                        </p>
                    </div>

                </div>
            </div>
            <div className="apps-download-buttons-20">
                <h6 className="title cl-white text-center mb-4">Ready to get started?</h6>
                <ul className="download-options">
                    <li>
                        <a href="#0" className="active"><i className="fab fa-apple"></i></a>
                        <span className="cl-white">Android</span>
                    </li>
                    <li>
                        <a href="#0"><i className="fab fa-android"></i></a>
                        <span className="cl-white">ios</span>
                    </li>
                </ul>
            </div>
            <div className="apps-download-screen-20">
                <div className="apps-download-bg">
                    <img src={Mocup} alt="extra-2/screenshot"/>
                </div>
                <div className="apps-download-thumb">
                    <img src={Mocupscreen} alt="extra-2/screenshot"/>
                </div>
            </div>
        </div>
    </section> */}
    {/* <!--============= Apps Download Section Ends Here =============--> */}

    </div>
  )
}

export default DigitalCampus
