import React from "react";
import '../assets/css/LightBox.css'
import communicate from '../assets/images/icon/Communicate.png'
import identity from '../assets/images/icon/Identity.png'
import access from '../assets/images/icon/Access.png'
import connect from '../assets/images/icon/Connect.png'
import dashboard from '../assets/images/icon/Dashboard.png'
import data from '../assets/images/icon/Data.png'
import engage from '../assets/images/icon/Engage.png'
import groupMgmt from "../assets/images/assn/assn-features/Group Management.png"
import eventMgmt from "../assets/images/assn/assn-features/Event Management.png"
import digiMgmt from "../assets/images/assn/assn-features/Digital Management.png"
import mentor from "../assets/images/assn/assn-features/Mentorship.png"
import fundraise from "../assets/images/assn/assn-features/Fundraising.png"
import campaign from "../assets/images/assn/assn-features/Campaigning.png"
import scholarship from "../assets/images/assn/assn-features/Scholorship.png"
import enrich from "../assets/images/assn/assn-features/Enrich Alumni.png"

function Lightbox({ isOpen, setIsOpen, lightboxTitle, setlightboxTitle}) {

  const imageMap = {
    'Dashboard': dashboard,
    'Communicate': communicate,
    'Identity': identity,
    'Access': access,
    'Engage': engage,
    'Connect':connect,
    'Data':data,
    'Neoin iD':identity,
    'Connected':connect,
    'Accessible':access,
    'Affordable':identity,
    'Group Management':groupMgmt,
    'Event Management':eventMgmt,
    'Digital Engagement':digiMgmt,
    'Alumni Campaigning': campaign,
    'Mentorship':mentor,
    'Fundraising':fundraise,
    'Scholarship':scholarship,
    'Enrich Alumni':enrich
  };
  const contentMap = {
    'Dashboard': "AI dashboard empowers users with actionable insights, data-driven decision-making capabilities, and a holistic view of business performance.",
    'Communicate': "NEOIN Communication ensures the secure transmission and exchange of identity data, facilitating authentication, authorization, and access control in digital interactions.",
    'Identity': "Verified digital identities play a crucial role in enabling secure and trustworthy interactions in the digital world, providing individuals with a reliable means of proving their identity online while safeguarding their privacy and security.",
    'Access': "Digital data access plays a critical role in enabling individuals and organizations to harness the power of information for decision-making, innovation, collaboration, and productivity in today's digital age",
    'Engage': "NEOIN Engage empowers active involvement, participation, or interaction of individuals, organizations, or systems with digital identity solutions or platforms.",
    'Connect': "NEOIN Connect serves as a foundational element for enabling trusted and secure digital interactions, transactions, and collaborations in today's interconnected world.",
    'Data': "Data drives advanced analytics, seamless integration, robust security, and real-time updates, empowering users with insights, informed decisions, and data integrity.",
    'Neoin iD':"Ensuring the safety and security of our vibrant Gen Z community is our top priority. As part of our unwavering commitment to fostering a trusted platform, we've implemented the Neoin Verified iD process.",
    'Connected':"Get ready to unlock the power of connection – tailored especially for Gen Z! Our Connected feature is your gateway to building real connections, sparking meaningful conversations, and shaping vibrant communities.",
    'Accessible':"After completing the Neoin.iD process and establishing connections, our focus shifts to enhancing 'Accessibility'. This ensures that all users, including our Gen Z community, can seamlessly navigate and utilise Neoin's features and offerings.",
    'Affordable':"Experience the thrill of savings with Neoin's 'Affordable' feature designed exclusively for Gen Z. Dive into a world of unbeatable deals and discounts across all your favorite categories. From fashion to food, tech to travel, discover jaw-dropping savings that'll leave you wanting more.",
    'Group Management':'Streamline group management across alumni, clubs, societies, and professionals for seamless coordination and collective advancement.',
    'Event Management':'Efficiently coordinate events for diverse groups like alumni, clubs, societies, and professionals, fostering meaningful connections and community engagement.',
    'Digital Engagement': "Manage various groups digitally, from alumni to professionals. Create connections, foster growth, and build communities, all through skilled digital management.",
    'Alumni Campaigning': 'Uniting graduates from schools, colleges, and universities to support shared causes and initiatives. Harness the collective strength of alumni networks to drive positive change and advancement.',
    'Digital Business Card':(
      <div>
        <p>1. Learn how to create professional digital business cards.</p>
        <p>2. Discover how to customize your cards.</p>
        <p>3. Find out how to update your digital business card.</p>
        <p>4. Explore various sharing options, including email, text message, QR code, and social media.</p>
        <p>5. Discover how to use the cards to access exciting discounts and deals on famous brands.</p>
      </div>
    ),
    'Email Signature':(
      <div>
        <p>1.Learn how to create professional and effective email signatures.</p>
        <p>2.Step-by-step guide on creating and customizing your email signature across various email platforms.</p>
        <p>3.Step-by-step instructions for adding email signatures to popular platforms like Gmail, Outlook, Apple Mail, etc.</p>
        <p>4. Troubleshooting common issues and errors related to email signatures, with detailed steps to resolve them.</p>
      </div>
    ),
    'Virtual':(
      <div>
        <p>1.Learn how to enable virtual backgrounds for your video calls.</p>
        <p>2. Explore a variety of pre-designed virtual backgrounds.</p>
        <p>3. Discover how to upload your own images or videos as virtual backgrounds.</p>
        <p>4.Troubleshoot common issues with virtual backgrounds, such as poor image quality or compatibility problems.</p>
      </div>
    ),
    'Feature':(
      <div>
        <p>1.**NFC**: "Tap to connect effortlessly with NFC technology."</p>
        <p>2.**Geo-tagging**: "Add location information to your content with geo-tagging."</p>
        <p>3. **AI-enabled Scan**: "Experience lightning-fast scanning powered by AI technology."</p>
        <p>4.**Biometrics**: "Enhanced security with biometric authentication."</p>
        <p>5. **Virtual Backgrounds**: "Transform your video calls with customizable virtual backgrounds."</p>
        <p>6. **MFA**: "Strengthen security with Multi-Factor Authentication."</p>
        <p>7. **Call, SMS, Email on QR**: "Easily initiate communication through QR codes with calls, SMS, and email."</p>
        <p>8.**PPF Score**: "Unlock personalized insights with the PPF Score feature."</p>
      </div>
    ),
    'Mentorship':'Neoin believes in the power of mentorship to guide and inspire the next generation of alumni leaders. Our Mentorship Program connects experienced alumni with aspiring individuals to foster professional growth, personal development, and lifelong connections.',
    'Fundraising':'Discover the power of collective giving at Neoin. Seamlessly run campaigns within our community, track progress in real-time, and engage donors with ease. Whether its for large-scale initiatives or smaller projects, Neoin empowers you to make a meaningful impact where its needed most.',
    'Scholarship':'Support aspiring students by funding scholarships directly within our platform. Engage donors, track progress, and unite for impact as you empower individuals to pursue their academic dreams. Together, we can break barriers, inspire success, and shape a better tomorrow through the gift of education.',
    'Enrich Alumni':'Refine alumni data with our advanced Enhancement services. Utilize tailored strategies for accurate, relevant management. From contacts to profiles, empower network utilization. Gain insights, drive engagement with our services.'
    
    
  };

  const closeLightbox = () => {
    setIsOpen(false);
    setlightboxTitle("");
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "lightbox") {
      closeLightbox();
    }
  };

  // const openLightbox = (content) => {
  //   setContent(content);
  //   setIsOpen(true);
  // };

  return (
    <>
      {isOpen && (
        <div
          id="lightbox"
          className="lightbox"
          style={{ display: "block" }}
          onMouseDown={handleOutsideClick}
        >
          <div className="lightbox__inner">
            <button type="button" className="lightbox__close" onClick={closeLightbox}>
              &times;
            </button>
            <div className="lightbox__content">
              <img src={imageMap[lightboxTitle]} alt={lightboxTitle} className="lightbox__image" />
              <div className="lightbox__title">{lightboxTitle}</div>
              <p className="ml-4 mr-4">{contentMap[lightboxTitle]}</p>
            </div>
          </div>

        </div>
      )}
    </>
  );
}

export default Lightbox;

