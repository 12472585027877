import React, { useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

import t1 from "../assets/images/banner/t1.png"
import t5 from "../assets/images/banner/t5.png"
import t4 from "../assets/images/banner/t4.png"
import t6 from "../assets/images/banner/t6.png"
import tera from "../assets/images/counter/tera.png"
import t7 from "../assets/images/banner/t7.png"
// import banner_2 from "../assets/images/banner/banner-2.png"

import topleft from "../assets/images/banner/top-left.png"
// import banner1 from "../assets/images/banner/banner1-1.png"
// import banner2 from "../assets/images/banner/banner1-2.png"
// import banner3 from "../assets/images/banner/banner1-3.png"
// import arrow from "../assets/images/banner/arrow.png"
// import arrow2 from "../assets/images/banner/arrow2.png"
// import globe2 from "../assets/images/feature/globe2.png"
import circle1 from "../assets/images/counter/circle1.png"
import round from "../assets/images/counter/round.png"
import square from "../assets/images/counter/square.png"
import square2 from "../assets/images/counter/square2.png"

import genzBanner from "../assets/images/genz/Genz_header_banner.png"

import buildingCommunity from "../assets/images/genz/building_community.png"
import feature1 from "../assets/images/genz/icons/Neoin ID.svg"
import feature2 from "../assets/images/genz/icons/Connect.svg"
import feature3 from "../assets/images/genz/icons/Accessible.svg"
import feature4 from "../assets/images/genz/icons/Affordable.svg"

import tri1 from "../assets/images/counter/tri1.png"
import tri2 from "../assets/images/counter/tri2.png"

import counter3 from "../assets/images/icon/counter3.png"
import counter4 from "../assets/images/icon/counter4.png"
import ExclusiveOffers from "../assets/images/genz/Exclusive Offers.png"
import howtop2 from "../assets/css/img/how-top2.png"
import howbottom2 from "../assets/css/img/how-bottom2.png"
import featureVideo2 from "../assets/images/feature/fature-video2.png"
import downarrow2 from "../assets/images/feature/downarrow2.png"
import c10 from "../assets/images/how/c10.png"
// import i1 from "../assets/images/how/i1.png"
// import i2 from "../assets/images/how/i2.png"
// import i3 from "../assets/images/how/i3.png"
import { ReactComponent as I1 } from "../assets/images/genz/icons/Create your account.svg"
import { ReactComponent as I2 } from "../assets/images/genz/icons/Customize your digital business cards.svg"
import { ReactComponent as I3 } from "../assets/images/genz/icons/discounts & cashbacks.svg"
// import howh1 from "../assets/images/how/h1.png"
import howc8 from "../assets/images/how/c8.png"

// import howh2 from "../assets/images/how/h2.png"
import c9 from "../assets/images/how/c9.png"

// import howh3 from "../assets/images/how/h3.png"
import rocket from "../assets/images/how/rocket.png"
import c6 from "../assets/images/how/c6.png"
import c7 from "../assets/images/how/c7.png"

import createAccount from "../assets/images/genz/Create your account.png"
import customizeCard from "../assets/images/genz/Customize your digital.png"
import utilizeCard from "../assets/images/genz/Utilize these cards.png"

// import howmuchpg from "../assets/images/how/how-much-bg.png"
// import howbg from  "../assets/images/how/how-bg.jpg"
import featureShape from "../assets/images/feature/feature-shape-with-orange-balls.png"
// import promain2 from "../assets/images/feature/pro-main2.png"
// import promain from "../assets/images/feature/pro-main.png"
// import promain3 from "../assets/images/feature/pro-main3.png"
// import promain4 from "../assets/images/feature/pro-main4.png"
import promain from "../assets/images/genz/Signup to Neoin.png"
import promain2 from "../assets/images/genz/Get Connected Ecosystem.png"
import promain3 from "../assets/images/genz/Share Your Referral.png"
import promain4 from "../assets/images/student/Budget-Friendly Student Deals.png"
import pro1 from "../assets/images/genz/icons/Signup to Neoin.svg"
import pro2 from "../assets/images/genz/icons/Get Connected Ecosystem.svg"
import pro3 from "../assets/images/genz/icons/Share Referral.svg"
import pro4 from "../assets/images/genz/icons/Earn Rewards.svg"
// import pro1 from "../assets/images/feature/pro1.png"
// import pro2 from "../assets/images/feature/pro2.png"
// import pro3 from "../assets/images/feature/pro3.png"
// import pro4 from "../assets/images/feature/pro4.png"
// import calculatebg from "../assets/images/bg/calculate-bg.png"

// import counter_bg from "../assets/images/bg/counter-bg.png"
// import world_map from "../assets/images/bg/world-map.png"

//pricing
// import top_shape from "../assets/css/img/top-shape-dark.png"
// import bottom_shape from"../assets/css/img/bottom-shape.png"

// import balls1 from "../assets/images/balls/1.png"
// import balls2 from "../assets/images/balls/2.png"
// import balls3 from "../assets/images/balls/3.png"
// import balls4 from "../assets/images/balls/4.png"
// import balls5 from "../assets/images/balls/5.png"
// import balls6 from "../assets/images/balls/6.png"
// import pricing5 from "../assets/images/pricing/pricing5.png"
// import pricing6 from "../assets/images/pricing/pricing6.png"
// import pricing7 from "../assets/images/pricing/pricing7.png"
//import bannershape9 from "../assets/css/img/banner-9-shape-without-shadow.png"
// import pricingbg from "../assets/images/bg/pricing-bg.jpg"
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Counter from './Counter'
import Download from './Download'
import Lightbox from './LightBox'
import OwlCarousel from 'react-owl-carousel';

function GenzPricing() {

    const [isOpen, setIsOpen] = useState(false);
    const [lightboxTitle, setlightboxTitle] = useState("");
    const [activeComponent, setActiveComponent] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef(null);

    const openLightbox = (htmlOrElement) => {
        setIsOpen(true);
        // setContent(htmlOrElement);
        setlightboxTitle(htmlOrElement);
        setActiveComponent(true);
    };

    const isTablet = useMediaQuery({ maxWidth: 768 });

    const featureItems = [
        { id: 1, thumb: pro1, title: "Signup into Our Neoin", content: "The first step for all users is to Signup & Create your iD link to your own Personal, Professional and Financial data." },
        { id: 2, thumb: pro2, title: "Get Connected to our Ecosystem", content: "After signing in, users can Connect, Communicate and Engage through their unique referral link to share with others." },
        { id: 3, thumb: pro3, title: "Share Your Referral Link", content: "Share your referral link across social media, email, and various channels to showcase exclusive benefits and earn rewards." },
        { id: 4, thumb: pro4, title: "Earn Rewards", content: "Both referrer and new user get rewards for sign-ups through the referral link, like discounts, or cashback." },
    ];

    const carouselImages = [promain, promain2, promain3, promain4];

    const changeSlide = (index) => {
        setActiveIndex(index); // Update the active index state
        if (carouselRef.current) {
            console.log('CarouselRef :'+index);
            carouselRef.current.to(index, 300); // Navigate to the slide at the specified index
        }
    };

    return (
        <>
            { /* --============= ScrollToTop Section Starts Here =============--> */}
            {/* <div className="preloader">
        <div className="preloader-inner">
            <div className="preloader-icon">
                <span></span>
                <span></span>
            </div>
        </div>
    </div> */}
            <a href="#0" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
            { /* --============= ScrollToTop Section Ends Here =============--> */}

            { /* --============= Banner Section Starts Here =============--> */}
            <section className="banner-2 genzBanner oh">
                <div className="banner-bg-2 bg_img_bnr_2"></div>
                <div className="elem-3">
                    <img src={t1} alt="counter" />
                </div>
                <div className="elem-1">
                    <img src={t5} alt="counter" />
                </div>
                <div className="elem-4">
                    <img src={t4} alt="counter" />
                </div>
                <div className="elem-2">
                    <img src={t6} alt="counter" />
                </div>
                <div className="elem-5">
                    <img src={tera} alt="counter" />
                </div>
                <div className="elem-6">
                    <img src={t7} alt="counter" />
                </div>
                <div className="elem-7">
                    <img src={tri2} alt="counter" />
                </div>
                <div className="top-left d-none d-lg-block">
                    <img src={topleft} alt="counter" />
                </div>
                <div className="container">
                    <div className="row align-items-top justify-content-between">
                        <div className="col-lg-6">
                            <div className="banner-content-2">
                                <h2 className="Newtitle title">Where Vision Meets Action, Creating Tomorrow Today for GENZ</h2>
                                <p>
                                    <span className="first-word">NEOIN</span> Affordable, Connected, Engaging Ecosystem Empowering Gen Z's Personal, Professional, Financial Growth.</p>
                                <div className="banner-button-group justify-content-start">
                                    {/* <a href="#0" className="button-4">Start Using for Free</a> */}
                                    <a href="#0" className="button-4">Create ID</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {/* <div className="banner-1-slider-wrapper"> */}
                            {/* <div className="banner-1-slider owl-carousel owl-theme">
                            <div className="banner-thumb">
                                <img  src={banner1} alt="banner" />
                            </div>
                            <div className="banner-thumb">
                                <img src={banner2} alt="banner" />
                            </div>
                            <div className="banner-thumb">
                                <img src={banner3} alt="banner" />
                            </div>
                            <div className="banner-thumb">
                                <img src={banner1} alt="banner" />
                            </div>
                            <div className="banner-thumb">
                                <img src={banner2} alt="banner" />
                            </div>
                            <div className="banner-thumb">
                            </div>
                        </div> */}
                            {/* <OwlCarousel className="banner-1-slider"
                                    loop
                                    margin={0}
                                    padding={0}
                                    nav={false}
                                    dots={false} // Show dots navigation
                                    autoplay
                                    autoplayTimeout={3000}
                                    autoplayHoverPause
                                    responsive={{
                                        0: { items: 1 }, // Show 1 item on small screens
                                        768: { items: 1 }, // Show 3 items on medium screens
                                        1200: { items: 1 } // Show 3 items on large screens
                                    }}
                                    >
                                    <div className="banner-thumb">
                                        <img src={banner1} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner2} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner3} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner1} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner2} alt="banner" />
                                    </div>
                                    <div className="banner-thumb">
                                        <img src={banner3} alt="banner" />
                                    </div>
                                </OwlCarousel>

                        <div className="ban-click">
                                    <div className="Newban-nav ">
                                        <a href="#0" className="ban-prev active .Newban-nav">
                                        Click here <i className="flaticon-right lefticon"></i>
                                        </a> */}


                            {/* <img className="d-none d-lg-block" src={arrow} alt="banner" /> 
                                    <img className="d-lg-none" src={arrow2} alt="banner" /> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            <div className={`${isTablet ? "text-center" : ""}`}>
                                <img src={genzBanner} alt='GenZ Banner' className='d-none d-md-block GenzImg' />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            { /* --============= Banner Section Ends Here =============--> */}


            { /* --============= Amazing Feature Section Starts Here =============--> */}
            <section className="amazing-feature-section  padding-bottom pos-rel" id="feature">
                <div className="ft-shape" style={{"top":"-100px"}}>
                    <img src={buildingCommunity} className='img-up-down-animation' alt="feature" style={{ "opacity": "0.6"}} />
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7">
                            <div className="section-header mw-100">
                                <h5 className="cate">Next-Gen Solutions for Gen Z</h5>
                                <h2 className="title line-height-heading">Building Community, Empowering Budgets, and Inspiring Confidence</h2>
                                <p>Join us in building a vibrant community, empowering you financially, and fostering confidence in the Gen Z realm. Let's shape a future where unity, prosperity, and self-assurance thrive.</p>
                            </div>
                        </div>

                        {activeComponent && (
                            <Lightbox
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                lightboxTitle={lightboxTitle}
                                setlightboxTitle={setlightboxTitle}
                            />
                        )}

                        <div className="col-12">
                            <div className="row justify-content-center mb-30-none">

                                <div className="col-sm-6 col-lg-3" onClick={() => openLightbox("Neoin iD")}>
                                    <div className="am-item cursor-pointer">
                                        <div className="am-thumb">
                                            <img src={feature1} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Neoin.iD</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-3" onClick={() => openLightbox("Connected")}>
                                    <div className="am-item cursor-pointer">
                                        <div className="am-thumb">
                                            <img src={feature2} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Connected</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-3" onClick={() => openLightbox("Accessible")}>
                                    <div className="am-item cursor-pointer">
                                        <div className="am-thumb">
                                            <img src={feature3} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Accessible</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-lg-3" onClick={() => openLightbox("Affordable")}>
                                    <div className="am-item cursor-pointer">
                                        <div className="am-thumb">
                                            <img src={feature4} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">Affordable</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* --============= Amazing Feature Section Ends Here =============--> */}


            { /* --============= Counter Section Starts Here =============--> */}
            <section className="counter-section bg_img_counter oh pos-rel">
                <div className="elem-1">
                    <img src={circle1} alt="counter" />
                </div>
                <div className="elem-2">
                    <img src={round} alt="counter" />
                </div>
                <div className="elem-3">
                    <img src={square} alt="counter" />
                </div>
                <div className="elem-4">
                    <img src={square2} alt="counter" />
                </div>
                <div className="elem-5">
                    <img src={tera} alt="counter" />
                </div>
                <div className="elem-6">
                    <img src={tri1} alt="counter" />
                </div>
                <div className="elem-7">
                    <img src={tri2} alt="counter" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="counter-wrapper padding-bottom-2 padding-top">
                                <div className="section-header left-style cl-white">
                                    <h5 className="cate">Gen Z, Reap the Rewards</h5>
                                    <h2 className="title">Exclusive Offers Tailored for Gen Z</h2>
                                    <p>Dive into exclusive deals and discounts tailored for you. Unlock savings and enjoy perks curated for your shopping experience. Join our community and reap unbeatable deals!</p>
                                </div>
                                <div className="counter-area">
                                    <div className="counter--item">
                                        <div className="counter-thumb">
                                            <img src={counter3} alt="icon" />
                                        </div>
                                        <div className="counter-content">
                                            <h2 className="title"><Counter end={17501}></Counter></h2>
                                            <span>Premium User</span>
                                        </div>
                                    </div>
                                    <div className="counter--item">
                                        <div className="counter-thumb">
                                            <img src={counter4} alt="icon" />
                                        </div>
                                        <div className="counter-content">
                                            <h2 className="title"><Counter end={1987}></Counter></h2>
                                            <span>Daily Visitors</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-none d-lg-block">
                            <div className="counter-thumb-1">
                                <img src={ExclusiveOffers} alt="counter" className='genz-img-padding'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* --============= Counter Section Ends Here =============--> */}


            { /* --============= How Section Starts Here =============--> */}
            <section className="how-section-two padding-top-2 oh pos-rel mt-max-lg-0" id="how">
                <div className="how-main-bg bg_img_how"></div>
                <div className="how-top"><img src={howtop2} alt="css" /></div>
                <div className="how-bottom"><img src={howbottom2} alt="css" /></div>
                <div className="container">
                    <div className="row flex-wrap-reverse">
                        <div className="col-lg-5 rtl">
                            <a href="https://www.youtube.com/watch?v=ObZwFExwzOo" className="feature-video-area">
                                <div className="thumb">
                                    <img src={featureVideo2} alt="feature" />
                                </div>
                                <div className="button-area">
                                    <h4 className="title">
                                        Watch Videos
                                    </h4>
                                    <div className="video-button">
                                        <i className="flaticon-play"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-7 padding-top">
                            <div className="section-header left-style">
                                <h5 className="cate">Smooth Sailing from Sign-Up to Set-Up</h5>
                                <h2 className="title">Let’s See How It Work</h2>
                                <p>Onboarding Steps for Gen Z</p>
                            </div>
                            <div className="downarrow2">
                                <img src={downarrow2} alt="feature" />
                            </div>
                        </div>
                    </div>
                    <div className="how-wrapper">
                        <div className="how--item">
                            <div className="anime-item"></div>
                            <div className="how-content">
                                <div className="serial">
                                    <img src={c10} alt="how" />
                                </div>
                                <div className="thumb">
                                    <I1 className='genz-launch-icon' />
                                </div>
                                <a href="#0" className="button-4">Create ID</a>
                                <p>
                                    Create your account and get verified on our platform.
                                </p>
                            </div>
                            <div className="how-thumb d-none d-lg-block">
                                <img src={createAccount} alt="how" />
                            </div>
                        </div>
                        <div className="how--item">
                            <div className="anime-item"></div>
                            <div className="how-content">
                                <div className="serial">
                                    <img src={howc8} alt="how" />
                                </div>
                                <div className="thumb">
                                <I2 className='genz-launch-icon' />
                                </div>
                                {/* <a href="#0" className="button-4">create an account</a> */}
                                <p>
                                    Customize your digital business cards and share them with anyone to connect, communicate, and engage.
                                </p>
                            </div>
                            <div className="how-thumb d-none d-lg-block">
                                <img src={customizeCard} alt="how" />
                            </div>
                        </div>
                        <div className="how--item">
                            <div className="anime-item"></div>
                            <div className="how-content">
                                <div className="serial">
                                    <img src={c9} alt="how" />
                                </div>
                                <div className="thumb d-block">
                                <I3 className='genz-launch-icon' />
                                </div>
                                {/* <a href="#0" className="button-4">enjoy the app</a> */}
                                <p>
                                    Utilize these cards for various features such as discounts, cashbacks on shopping, and referrals.

                                </p>
                            </div>
                            <div className="how-thumb d-none d-lg-block">
                                <img src={utilizeCard} alt="how" />
                            </div>
                        </div>
                        <div className="plane wow slideInUp d-none d-lg-block" data-wow-duration="2s">
                            <img src={rocket} alt="how" />
                        </div>
                    </div>
                    <div className="member-counter-area padding-bottom-2 padding-top ">
                        <div className="up-bal two wow img-up-down-animation">
                            <img src={c6} alt="how" />
                        </div>
                        <div className="up-bal wow slideInUp d-none d-lg-block" data-wow-duration="1s">
                            <img src={c7} alt="how" />
                        </div>
                        {/*
                <div className="member-bg">
                    <img src={howmuchpg} alt="how" />
                </div>
                <div className="member-counter cl-white">
                    <h2 className="title"><span className="counter">455,326,234</span></h2>
                    <p>People Joined Already</p>
                    <a href="#0" className="custom-button">Download App</a>
                    <ul className="download-options">
                        <li>
                            <a href="#0"><i className="fab fa-windows"></i></a>
                        </li>
                        <li>
                            <a href="#0" className="active"><i className="fab fa-apple"></i></a>
                        </li>
                        <li>
                            <a href="#0"><i className="fab fa-android"></i></a>
                        </li>
                    </ul>
                </div>*/}
                    </div>
                </div>
            </section>
            { /* --============= How Section Ends Here =============--> */}


            { /* --============= Coverage Section Starts Here =============--> */}
            {/* <section className="coverage-section padding-top padding-bottom pb-max-lg-0" id="coverage">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="section-header left-style coverage-header">
                        <h5 className="cate">Our stats say more than any words</h5>
                        <h2 className="title">Apps Without Borders</h2>
                        <p>
                            Neoin app are growing by 300% every year with a steady love from users around the world. We are also close to achieving 10 million cumulative downloads.
                        </p>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="coverage-right-area text-lg-right">
                        <div className="rating-area">
                            <div className="ratings">
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                                <span><i className="fas fa-star"></i></span>
                            </div>
                            <span className="average">5.0 / 5.0</span>
                        </div>
                        <h2 className="amount">312,921+</h2>
                        <a href="#0">Total User Reviews <i className="fas fa-paper-plane"></i></a>
                    </div>
                </div>
            </div>
            <div className="coverage-wrapper bg_img_worldmap">
                <div className="border-item-1">
                    <span className="name">North America</span>
                    <h2 className="title">70.7%</h2>
                </div>
                <div className="border-item-2">
                    <span className="name">Asia</span>
                    <h2 className="title">14.4%</h2>
                </div>
                <div className="border-item-3">
                    <span className="name">North Europe</span>
                    <h2 className="title">8.4%</h2>
                </div>
                <div className="border-item-4">
                    <span className="name">South America</span>
                    <h2 className="title">1.8%</h2>
                </div>
                <div className="border-item-5">
                    <span className="name">Africa</span>
                    <h2 className="title">1.8%</h2>
                </div>
                <div className="border-item-6">
                    <span className="name">Australia</span>
                    <h2 className="title">3%</h2>
                </div>
            </div>
        </div>
    </section> */}
            { /* --============= Coverage Section Ends Here =============--> */}


            { /* --============= Feature Section Starts Here =============--> */}
            <section className="feature-section padding-top-2 padding-bottom-2 oh pos-rel">
                <div className="feature-shapes d-none d-lg-block">
                    <img src={featureShape} alt="feature" />
                </div>
                <div className="container">
                    <div className="section-header mw-725">
                        <h5 className="cate">Unlock Benefits Through Referrals</h5>
                        <h2 className="title">Gen Z's Path to Earning and Referring </h2>
                        <p>
                            Explore Gen Z's unique earning and referring system. Join now to unlock exclusive benefits and share the love with friends.
                        </p>
                    </div>
                    <div className="row genz-carousel-container">
                        <div className="col-lg-5 rtl">
                            <div className="feature--thumb pr-xl-4 ltr">
                                {/* <div className="feat-slider owl-carousel owl-theme" data-slider-id="1"> */}
                                <OwlCarousel className="feat-slider genz-carousel"
                                    loop
                                    margin={10}
                                    nav={false}
                                    dots={false}
                                    autoplay={false}
                                    autoplayTimeout={1500}
                                    autoplayHoverPause
                                    startPosition={activeIndex}
                                    responsive={{
                                        0: { items: 1 }, // Show 0 item on small screens
                                        768: { items: 1 }, // Show 1 item on medium screens
                                        1200: { items: 1 } // Show 1 item on large screens
                                    }}
                                    ref={carouselRef}>
                                    {carouselImages.map((imageSrc, index) => (
                                        <div key={index} className="main-thumb">
                                            <img src={imageSrc} alt={`feature ${index + 1}`} className="StyleImg" style={{"height":"auto", "width":"auto"}}/>
                                        </div>
                                    ))}
                                    {/* <div className="main-thumb">
                                <img className='StyleImg' src={promain2} alt="feature"  />
                            </div>
                            <div className="main-thumb">
                                <img src={promain} alt="feature" />
                            </div>
                            <div className="main-thumb">
                                <img src={promain3} alt="feature" />
                            </div>
                            <div className="main-thumb">
                                <img src={promain4} alt="feature" />
                            </div> */}
                                    {/* </div> */}
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            {/* <div className="feature-wrapper mb-30-none owl-thumbs" data-slider-id="1">
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src={pro1} alt="feature" />
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Signup into Our Neoin</h4>
                                        <p>The first step for all users is to Signup & Create your ID link to your own Personal, Professional and Financial data.</p>
                                    </div>
                                </div>
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src={pro2} alt="feature" />
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Get Connected to our Ecosystem</h4>
                                        <p>After signing in, users can Connect, Communicate and Engage through their unique referral link to share with others.</p>
                                    </div>
                                </div>
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src={pro3} alt="feature" />
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Share Your Referral Link</h4>
                                        <p>Share your referral link across social media, email, and various channels to showcase exclusive benefits and earn rewards.</p>
                                    </div>
                                </div>
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src={pro4} alt="feature" />
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Earn Rewards</h4>
                                        <p>Both referrer and new user get rewards for sign-ups through the referral link, like discounts, or cashback.</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="feature-wrapper mb-30-none">
                                {featureItems.map((item, index) => (
                                    <div key={item.id} className={`feature-item ${index === activeIndex ? 'active' : ''}`} onClick={() => changeSlide(index)}>
                                        <div className="feature-thumb">
                                            <div className="thumb d-flex justify-content-center align-items-center">
                                                <img src={item.thumb} alt={`feature ${item.id}`} style={{"height": "64%", "width": "64%"}}/>
                                            </div>
                                        </div>
                                        <div className="feature-content">
                                            <h4 className="title">{item.title}</h4>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { /* --============= Feature Section Ends Here =============--> */}

            { /* <!--============= Pricing Section Ends Here =============--> */}
            {/* <section className="pricing-section padding-top oh padding-bottom pb-md-0 bg_img_pricing pos-rel" id="pricing">
        <div className="top-shape d-none d-md-block">
            <img src={top_shape} alt="css" />
        </div>
        <div className="bottom-shape d-none d-md-block">
            <img src={bottom_shape} alt="css" />
        </div>
        <div className="ball-2" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls1} alt="balls" />
        </div>
        <div className="ball-3" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls2} alt="balls" />
        </div>
        <div className="ball-4" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls3} alt="balls" />
        </div>
        <div className="ball-5" data-paroller-factor="0.30" data-paroller-factor-lg="-0.30"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src={balls4} alt="balls" />
        </div>
        <div className="ball-6" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="horizontal">
            <img src={balls5} alt="balls" />
        </div>
        <div className="ball-7" data-paroller-factor="-0.30" data-paroller-factor-lg="0.60"
        data-paroller-type="foreground" data-paroller-direction="vertical">
            <img src={balls6} alt="balls" />
        </div>
        <div className="container">
            <div className="section-header pb-30 cl-white">
                <h5 className="cate">Choose a plan that's right for you</h5>
                <h2 className="title">Simple Pricing Plans</h2>
                <p>
                    Mosto has plans, from free to paid, that scale with your needs. Subscribe to a plan that fits the size of your business.
                </p>
            </div>
            <div className="row justify-content-center mb-30-none">
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">basic</span>
                        <div className="pricing-thumb">
                            <img src={pricing5} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>30.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">standard</span>
                        <div className="pricing-thumb">
                            <img src={pricing6} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>50.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block active">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-4">
                    <div className="pricing-item">
                        <span className="tag">premium</span>
                        <div className="pricing-thumb">
                            <img src={pricing7} alt="pricing" />
                        </div>
                        <div className="pricing-content">
                            <div className="pricing-header">
                                <h2 className="title"><sup>$</sup>90.0</h2>
                                <span className="cate">Per Month</span>
                            </div>
                            <ul>
                                <li>Days of Free Trial</li>
                                <li>Cloud Storage</li>
                                <li>Regular Updates</li>
                                <li>eCommerce Integration</li>
                                <li>Interface Customization</li>
                                <li>24/7 Support</li>
                            </ul>
                            <a href="#0" className="button-3 d-block">seat plan <i className="flaticon-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
            {/* <!--============= Pricing Section Ends Here =============--> */}

            {/* <div className="text-center mt-70">
                        <p>Want a customized solution to meet your specific needs?</p>
                        <a href="#0" className="show-feature" style={{ color: '#00E9E7' }}>Get in touch with us.</a>


    </div> */}


            <Download />
        </>
    );
}
export default GenzPricing;