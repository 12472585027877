import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import '../assets/css/Download.css'

import QRFrame from '../assets/images/mobile_apps_qr.png';
import GPlay from '../assets/images/android.svg';
import AppStore from '../assets/images/ios.svg';


function Download() {

    const isResponsive = useMediaQuery({ maxWidth: 992 });

  return (
    <section className='download-links'>
                <div className="container row links">
                    {isResponsive ? ( // Smaller Screens: Tabs and Mobile phones (<= 992px)
                        <div className="flex-container">
                            <div className="get-started">
                                <h1>Get Started Instantly</h1>
                                {/* <br /> */}
                                <p>Download now <br /> and embark on a journey of convenience!</p>
                            </div>
                            <div className="col-12 qr-frame mt-3">
                            <a href='https://enterprise.neocardz.com/dashboard' target='_blank' rel="noreferrer">
                                <img src={QRFrame} alt='QR Frame' />
                            </a>
                            </div>
                            <div className="row store-links mb-3 mt-3">
                                <div className='col-md-4 col-sm-6 res-gplay pr-3'>
                                    <a href='https://play.google.com/store/games?hl=en_IN&gl=US&pli=1'>
                                        <img src={GPlay} alt='GOOGLE PLAY' />
                                    </a>
                                </div>
                                <div className='col-md-4 col-sm-6 res-astore pl-3'>
                                    <a href='https://www.apple.com/in/app-store/'>
                                        <img src={AppStore} alt='APP STORE' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : ( // Larger Screens: Laptops and Desktops (> 992px)
                        <>
                            <div className="col-md-6 qr-frame">
                                <a href='https://enterprise.neocardz.com/dashboard' target='_blank' rel="noreferrer" className='qr-image-link-wrapper'>
                                <img src={QRFrame} alt='QR Frame' />
                                </a>
                            </div>
                            <div className="col-md-6 get-started-wrappers pt-4">
                                <div className="get-started mb-2">
                                    <h1 className='pb-3'>Get Started Instantly</h1>
                                    {/* <br /> */}
                                    <p>Download now and embark on a journey of convenience!</p>
                                </div>
                                <div className="row store-links mt-4">
                                    <div className='col-4 mr-2'>
                                        <a href='https://play.google.com/store/games?hl=en_IN&gl=US&pli=1'>
                                            <img src={GPlay} alt='GOOGLE PLAY' />
                                        </a>
                                    </div>
                                    <div className='col-4 ml-2'>
                                        <a href='https://www.apple.com/in/app-store/'>
                                            <img src={AppStore} alt='APP STORE' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
  )
}

export default Download